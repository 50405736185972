const ArrowLeft = () => (
  <svg width="10px" height="18px" viewBox="0 0 10 18">
    <g
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g transform="translate(-8.000000, -4.000000)">
        <g transform="translate(16.000000, 14.000000) rotate(44.000000) translate(-16.000000, -14.000000) translate(9.000000, 6.000000)">
          <line
            x1="7.27804963"
            y1="6.92629381"
            x2="-4.03311786"
            y2="6.68652301"
            transform="translate(1.332179, 6.849959) rotate(-91.214357) translate(-1.332179, -6.849959) "
          ></line>
          <line
            x1="4.99928598"
            y1="6.75848475"
            x2="8.55045338"
            y2="17.2286036"
            transform="translate(6.967524, 12.093381) rotate(-71.000000) translate(-6.967524, -12.093381) "
          ></line>
        </g>
      </g>
    </g>
  </svg>
);
export default ArrowLeft;
