import { authorised } from "../request";
import { WishlistItem } from "../types/items/WishlistItem";
import { BookOption, ProductSize } from "../types/Product";
import { ProductOption } from "../types/ProductOption";
import { mixpanelEvent } from "../helpers/analytics";

export async function addToWishlist(data: {
  product: number;
  size?: ProductSize | null;
  options: ProductOption[];
  book_option: BookOption | null;
}) {
  const API = authorised();

  mixpanelEvent("Added to wishlist");

  return await API.request<WishlistItem>({
    method: "POST",
    url: "/api/frontend/wishlist/",
    data,
  });
}
