import React, { useMemo } from "react";
import { Spacing, Column } from "../../helpers/layout";
import { InHomeProduct } from "../../components/InHomeProduct";
import { Colors } from "../../branding";
import CloseIcon from "../../icons/CloseIcon";
import { PlainButton } from "../../components/PlainButton";
import { InHome } from "../../types/InHome";
import { useWrapper } from "../../helpers/useWrapper";
import InHomeSlideshow from "./InHomeSlideshow";
import InHomeMobileItemImage from "./InHomeMobileItemImage";
import { css } from "styled-components";
import InHomeBottomActions from "./InHomeBottomActions";

export default function InHomeMobileView(props: {
  inHome: InHome;
  onClose?: () => void;
  allInHomes?: InHome[];
}) {
  const spacing = useWrapper();
  const { products } = props.inHome;

  // the layout must be slightly different on the standalone in-home page (/in-homes/228)
  const bottomActionsFixed = !!props.onClose;

  const closeButton = useMemo(
    () =>
      props.onClose ? (
        <PlainButton
          icon={<CloseIcon />}
          onClick={props.onClose}
          css={css`
            position: fixed;
            top: ${spacing};
            right: ${spacing};
            background: rgba(255, 255, 255, 0.5);
            padding: ${Spacing.s};
            z-index: 2;
            span {
              display: flex;
            }
          `}
        />
      ) : null,
    [props.onClose, spacing]
  );

  const initialSlide = useMemo(() => {
    if (props.allInHomes && props.allInHomes.length > 1) {
      return props.allInHomes.findIndex((home) => home.id === props.inHome.id);
    }
    return 0;
  }, [props.allInHomes, props.inHome.id]);

  if (props.allInHomes && props.allInHomes.length > 1) {
    return (
      <div>
        {closeButton}
        <InHomeSlideshow
          slides={props.allInHomes}
          initialSlide={initialSlide}
        />
      </div>
    );
  }

  return (
    <div>
      {closeButton}
      <Column
        css={`
          ${bottomActionsFixed ? `padding-bottom: 70px;` : ""}
          padding-top: ${Spacing.l};
        `}
      >
        <div
          css={`
            padding-inline: ${Spacing.l};
            width: 100%;
            box-sizing: border-box;
          `}
        >
          <InHomeMobileItemImage image={props.inHome?.image} />
        </div>
        <div
          css={css`
            padding: ${spacing};
            box-sizing: border-box;
          `}
        >
          <Column gutter={Spacing.xxl}>
            <div>
              <Column>
                {products.map((connection) => (
                  <div
                    key={`in-home-product--${connection.id}`}
                    css={css`
                      max-width: 700px;
                      margin: 0 auto;
                      width: 100%;
                      padding: ${Spacing.m} 0;
                      box-sizing: border-box;
                      border-bottom: 1px solid ${Colors.light_grey};

                      &:first-child {
                        padding-top: 0;
                      }

                      &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                      }
                    `}
                  >
                    <InHomeProduct
                      product={connection.product}
                      onClose={props.onClose}
                    />
                  </div>
                ))}
              </Column>
            </div>
          </Column>
        </div>
      </Column>
      <div
        css={`
          ${bottomActionsFixed
            ? `
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.05);
            padding-inline: ${spacing};
            padding-block: ${Spacing.m};
            `
            : `
            position: relative;
            padding: ${spacing};
            `}
          box-sizing: border-box;
          background: ${Colors.white};
          z-index: 2;
        `}
      >
        <InHomeBottomActions products={products} onClose={props.onClose} />
      </div>
    </div>
  );
}
