import * as layout from "../helpers/layout";
import { Spacing } from "../types/Block";

const sizeMapping: {
  [index: string]: string;
} = {
  none: layout.Spacing.none,
  xs: layout.Spacing.s,
  s: layout.Spacing.m,
  m: layout.Spacing.l,
  l: layout.Spacing.xl,
  xl: layout.Spacing.xxl,
  xxl: layout.Spacing.xxxl,
  xxxl: layout.Spacing.xxxxl
};

export function getSpacing(spacing: Spacing, sizeCoefficient: number) {
  const remValue = sizeMapping[spacing];

  if (!remValue) return layout.Spacing.none;

  const value = Number(remValue.replace("rem", ""));

  if (!value) return layout.Spacing.none;

  const newValue = value * sizeCoefficient;

  return `${newValue.toFixed(2)}rem`;
}
