import React, { useMemo } from "react";
import { Colors } from "../branding";
import { Spacing, Row } from "../helpers/layout";
import { css } from "styled-components";
import { Dictionary } from "lodash";
import { Scrollbar } from "./Scrollbar";
import CheckIcon from "../icons/CheckIcon";
import { useResponsive } from "../context/ResponsiveContext";

type GroupCounts = {
  [key: string]: number;
};

function getFilterButtonWidth<T>(groups: Dictionary<T[]>) {
  const maxFilterLength = Object.keys(groups).reduce(
    (maxLength, currentFilter) => {
      return Math.max(currentFilter.length, maxLength);
    },
    0,
  );
  return maxFilterLength * 14;
}

export function FilterButtons<T>(props: {
  currentFilter: string | null;
  groups: Dictionary<T[]>;
  groupCounts: GroupCounts;
  onOpen: (filter: string) => void;
  onClose: () => void;
  onCancel: (filter: string) => void;
  arrowsInside?: boolean;
}) {
  const { isTablet } = useResponsive();
  const buttonWidth = getFilterButtonWidth(props.groups);

  const buttons = useMemo(() => {
    const order = [
      "series",
      "subject",
      "animal",
      "orientation",
      "honors",
      "energy",
      "tone",
      "palette",
      "country",
    ];
    return Object.keys(props.groups).sort((itemA, itemB) => {
      const a = itemA.toLocaleLowerCase();
      const b = itemB.toLocaleLowerCase();
      const indexA = order.indexOf(a);
      const indexB = order.indexOf(b);

      if (indexA === -1 && indexB === -1) {
        return a.localeCompare(b);
      }
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
  }, [props.groups]);

  return (
    <Scrollbar autoHeight onlyArrows arrowsInside={props.arrowsInside}>
      <Row
        css={css`
          padding: 8px 0;
        `}
      >
        {buttons.map((filter) => {
          const isActive = props.currentFilter === filter;
          const hasSelections = props.groupCounts[filter] > 0;

          return (
            <button
              css={css`
                border: 1px solid ${Colors.black};
                padding: ${Spacing.s} 0;
                width: ${buttonWidth}px;
                background: ${isActive ? Colors.black : "transparent"};
                text-decoration: ${hasSelections ? "underline" : "none"};
                color: ${isActive ? Colors.white : Colors.black};
                position: relative;
                cursor: pointer;
                font-size: 12px;
                letter-spacing: 0.15em;
                font-weight: 600;
                text-transform: uppercase;
              `}
              key={`filters--${filter}`}
              onMouseEnter={
                !isTablet
                  ? () => {
                      props.onOpen(filter);
                    }
                  : undefined
              }
              onClick={
                isTablet
                  ? () => {
                      if (!isActive) {
                        props.onOpen(filter);
                      } else {
                        props.onClose();
                      }
                    }
                  : undefined
              }
            >
              {filter}
              {hasSelections && (
                <span
                  css={css`
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    display: inline-block;
                  `}
                  onClick={() => {
                    props.onCancel(filter);
                  }}
                >
                  <CheckIcon deleteOnHover />
                </span>
              )}
            </button>
          );
        })}
      </Row>
    </Scrollbar>
  );
}
