const HeartIcon = () => (
  <svg viewBox="0 0 100 100" width="21" height="21">
    <path
      fill="fill"
      strokeWidth="3"
      stroke="currentColor"
      d="M70.099,7.412c-7.931,0-15.388,3.816-20.099,10.231C45.289,11.228,37.831,7.412,29.9,7.412C16.17,7.412,5,18.582,5,32.313    c0,4.13,1.667,9.198,4.953,15.062c11.471,20.464,39.442,44.848,39.723,45.093c0.025,0.022,0.055,0.033,0.084,0.049    c0.017,0.01,0.033,0.022,0.051,0.03c0.061,0.025,0.125,0.041,0.19,0.041c0,0,0,0,0,0s0,0,0,0c0.063,0,0.125-0.015,0.185-0.04    c0.019-0.008,0.034-0.02,0.052-0.03c0.027-0.015,0.055-0.025,0.08-0.046C52.141,90.949,95,54.897,95,32.313    C95,18.582,83.83,7.412,70.099,7.412z M50.006,91.441c-3.086-2.72-28.45-25.39-39.19-44.549    c-3.203-5.715-4.827-10.62-4.827-14.579c0-13.186,10.727-23.912,23.911-23.912c7.834,0,15.196,3.871,19.694,10.355    c0.016,0.023,0.039,0.036,0.057,0.055c0.022,0.023,0.039,0.051,0.067,0.07c0.003,0.002,0.007,0.002,0.01,0.004    c0.03,0.02,0.065,0.029,0.098,0.042c0.027,0.01,0.052,0.025,0.079,0.031c0.031,0.006,0.062,0.001,0.094,0.001    c0.032,0,0.063,0.005,0.094-0.001c0.028-0.005,0.053-0.021,0.079-0.031c0.034-0.013,0.068-0.022,0.098-0.042    c0.003-0.002,0.007-0.002,0.01-0.004c0.028-0.019,0.045-0.046,0.067-0.07c0.019-0.019,0.041-0.032,0.057-0.055    c4.497-6.484,11.859-10.355,19.693-10.355c13.185,0,23.912,10.727,23.912,23.912C94.011,53.432,54.02,88.024,50.006,91.441z"
    />
  </svg>
);
export default HeartIcon;
