import React from "react";
import { css } from "styled-components";
import { Colors } from "../branding";

export function Hr({
  size,
  color,
  align
}: {
  size: "small" | "medium" | "large" | "extra-large" | "full";
  color?: Colors;
  align?: "center" | "left";
}) {
  return (
    <hr
      css={css`
        border: none;
        margin-left: ${align === "center" ? "auto" : 0};
        border-top: 1px solid ${color};
        width: 100%;
        max-width: ${size === "small"
          ? "30px"
          : size === "medium"
          ? "50px"
          : size === "large"
          ? "100px"
          : size === "extra-large"
          ? "200px"
          : size === "full"
          ? "100%"
          : "0px"};
      `}
    />
  );
}
