import { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import isEqual from "lodash/isEqual";

function getInitialFilters(
  namespace: string,
  queryFilters: string,
  urlFilters?: boolean
) {
  const sessionFilters =
    typeof sessionStorage !== "undefined"
      ? sessionStorage.getItem(`DD_FILTERS_${namespace}`)
      : "";

  if (queryFilters && urlFilters) {
    const decodedFilters = decodeURIComponent(queryFilters);
    return decodedFilters.split(",").map((s) => Number(s));
  }

  if (sessionFilters) {
    return sessionFilters.split(",").map((s) => Number(s));
  }

  return [];
}

export function useFilters(namespace: string, urlFilters?: boolean) {
  const router = useRouter();
  const path = router.asPath;
  const queryFilters = path.split("?filters=")[1];
  const cleanPath = path.split("?")[0];
  const [filters, setFilters] = useState<number[]>(
    getInitialFilters(namespace, queryFilters, urlFilters)
  );

  // Update filters when location is changed
  useEffect(() => {
    const newFilters = getInitialFilters(namespace, queryFilters, urlFilters);

    if (urlFilters && !isEqual(newFilters, filters)) {
      setFilters(newFilters);
    }
  }, [path]);

  // Change current url query params
  useEffect(() => {
    if (urlFilters) {
      const params = new URLSearchParams(window.location.search);
      if (filters.length > 0) {
        params.set("filters", filters.join(","));
        const newPath = `${cleanPath}?${params.toString()}`;
        Router.replace(router.pathname, newPath, { shallow: true });
      } else if (params.has("filters")) {
        params.delete("filters");
        const newPath = `${cleanPath}?${params.toString()}`;
        Router.replace(router.pathname, newPath, { shallow: true });
      }
    }
  }, [urlFilters, filters]);

  // Preserve session filters
  useEffect(() => {
    const filterString = filters.join(",");

    sessionStorage.setItem(`DD_FILTERS_${namespace}`, filterString);
  }, [filters]);

  return { filters, setFilters };
}
