import { css } from "styled-components";
import { Colors } from "../branding";
import { fontFamilies } from "../typography";

const CheckIcon = (props: { deleteOnHover?: boolean }) => (
  <div
    css={css`
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #000000;

      svg path {
        fill: #ffffff !important;
      }

      ${props.deleteOnHover &&
      css`
        &:before {
          position: absolute;
          width: 100%;
          height: 14px;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          content: "+";
          transform: rotate(45deg);
          color: ${Colors.white};
          text-align: center;
          font: 17px ${fontFamilies.gotham};
          letter-spacing: 0;
          line-height: 15px;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }

          svg {
            opacity: 0;
          }
        }
      `}
    `}
  >
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <g transform="translate(4.266667 4.266667)">
        <path
          d="M7.12157 0.117507C6.96645 0.0128671 6.7774 -0.0238905 6.59602 0.0153216C6.41464 0.0545337 6.25579 0.166503 6.15442 0.326592L2.78486 5.64023L1.17394 4.10145C0.890479 3.8316 0.448324 3.84911 0.185826 4.14162C-0.0766718 4.43413 -0.0587062 4.88938 0.224752 5.16027L2.45149 7.28614C2.45948 7.29335 2.46946 7.29541 2.47745 7.30262C2.49741 7.3191 2.50938 7.34175 2.53034 7.3572C2.56029 7.3778 2.59422 7.38192 2.62616 7.39634C2.66409 7.41488 2.70102 7.43239 2.74094 7.44372C2.77987 7.45402 2.81779 7.45917 2.85772 7.46226C2.91461 7.46844 2.9695 7.46844 3.0254 7.4602C3.04835 7.45608 3.07031 7.4499 3.09327 7.44372C3.16214 7.42621 3.22601 7.3984 3.28889 7.35926C3.29987 7.35205 3.30886 7.34381 3.31983 7.3366C3.36076 7.30777 3.40467 7.28614 3.43961 7.247C3.46456 7.21919 3.47554 7.18314 3.4955 7.15224C3.4965 7.15121 3.49849 7.15018 3.49849 7.14915L7.32318 1.11658C7.53462 0.783198 7.4444 0.336077 7.12157 0.117507L7.12157 0.117507Z"
          fill="#FFFFFF"
          stroke="none"
        />
      </g>
    </svg>
  </div>
);
export default CheckIcon;
