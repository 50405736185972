import React from "react";
import { Row, Spacing } from "../../helpers/layout";
import styled, { css } from "styled-components";

const CheckboxButtonLabel = styled.span`
  position: absolute;
  top: 0;
  left: 0px;
  width: 18px;
  height: 18px;
  background: white;
  border: 1px solid #000;
`;
const CheckboxButton = styled.input`
  opacity: 0;
  width: 18px;
  height: 18px;
  background: none;

  &:checked + ${CheckboxButtonLabel} {
    background: white;
    border: 1px solid #000;
    &::after {
      content: "✔";
      display: block;
      font-size: 15px;
      margin-left: 3px;
    }
  }
`;

export function PlainCheckbox(props: {
  checked: boolean;
  onChange: (value: boolean) => void;
  children: React.ReactNode;
}) {
  return (
    <label
      css={css`
        position: relative;
        cursor: pointer;
      `}
    >
      <Row align="center" gutter={Spacing.s}>
        <CheckboxButton
          type="checkbox"
          checked={props.checked}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
        />
        <CheckboxButtonLabel />
        <div
          css={css`
            margin-top: 4px;
          `}
        >
          {props.children}
        </div>
      </Row>
    </label>
  );
}
