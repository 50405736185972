const InMuseumIcon = () => (
  <svg width="17px" viewBox="0 0 35 41">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M33.627451,8.280345 L25.6762745,8.280345 L17.6413725,0.201825 C17.3737255,-0.067275 16.9386275,-0.067275 16.6709804,0.201825 L8.63745098,8.280345 L0.68627451,8.280345 C0.30745098,8.280345 0,8.589465 0,8.970345 L0,39.330345 C0,39.711225 0.30745098,40.020345 0.68627451,40.020345 L33.627451,40.020345 C34.0062745,40.020345 34.3137255,39.711225 34.3137255,39.330345 L34.3137255,8.970345 C34.3137255,8.589465 34.0062745,8.280345 33.627451,8.280345 Z M17.1568627,1.666005 L23.7354902,8.280345 L10.5782353,8.280345 L17.1568627,1.666005 Z M9.63666667,38.640345 C9.93039216,34.025625 13.1078431,28.980345 17.1568627,28.980345 C21.2058824,28.980345 24.3833333,34.025625 24.6770588,38.640345 L9.63666667,38.640345 Z M32.9411765,38.640345 L26.0496078,38.640345 C25.8121569,34.380285 23.4198039,29.909085 19.977451,28.262745 C21.9607843,27.227745 23.3333333,25.167405 23.3333333,22.770345 C23.3333333,19.346565 20.5621569,16.560345 17.1568627,16.560345 C13.7515686,16.560345 10.9803922,19.346565 10.9803922,22.770345 C10.9803922,25.167405 12.3529412,27.227745 14.3362745,28.262745 C10.8952941,29.909085 8.50294118,34.380285 8.2654902,38.640345 L1.37254902,38.640345 L1.37254902,9.660345 L32.9411765,9.660345 L32.9411765,38.640345 Z M17.1568627,27.600345 C14.5078431,27.600345 12.3529412,25.433745 12.3529412,22.770345 C12.3529412,20.106945 14.5078431,17.940345 17.1568627,17.940345 C19.8058824,17.940345 21.9607843,20.106945 21.9607843,22.770345 C21.9607843,25.433745 19.8058824,27.600345 17.1568627,27.600345 Z"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);
export default InMuseumIcon;
