import React, { useEffect, useRef, useState } from "react";
import { css } from "styled-components";
import { Spacing } from "../helpers/layout";
import { hoverStyle, underlineStyle, activeStyle } from "./Link";

export function PlainButton(props: {
  icon?: JSX.Element;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: () => void;
  type?: "button" | "submit" | "reset";
  title?: string;
  successText?: string;
  successIcon?: JSX.Element;
  disabled?: boolean;
  className?: string;
  underlined?: boolean;
  active?: boolean;
  hoverEffect?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  iconPosition?: "left" | "right";
  height?: number;
}) {
  const [success, setSuccess] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success) {
        setSuccess(false);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [success]);

  const icon = props.icon && (
    <span
      css={css`
        display: inline-block;
        vertical-align: middle;
      `}
    >
      {success && props.successIcon ? props.successIcon : props.icon}
    </span>
  );

  const content = props.children && (
    <span
      css={css`
        display: inline-block;
        vertical-align: middle;
        margin-left: ${props.iconPosition === "left" ? Spacing.s : 0};
        margin-right: ${props.iconPosition === "right" ? Spacing.s : 0};
        margin: ${!props.icon && 0};
        text-decoration: inherit;
        position: relative;
        ${props.hoverEffect && hoverStyle};
        ${props.underlined && underlineStyle};
        ${props.active && activeStyle};
        ${props.height &&
        css`
          line-height: ${props.height}px;
        `}
      `}
    >
      {success && props.successText ? props.successText : props.children}
    </span>
  );

  return (
    <button
      className={props.className}
      css={css`
        background: none;
        outline: none;
        border: none;
        padding: 0;
        opacity: ${props.disabled ? 0.3 : 1};
        cursor: pointer;
        pointer-events: ${props.disabled && "none"};
        box-sizing: border-box;
        color: inherit;
        font: inherit;
        text-transform: inherit;
        letter-spacing: 0.1em;
      `}
      type={props.type || "button"}
      disabled={props.disabled}
      title={props.title}
      onMouseEnter={props.onMouseEnter}
      onClick={async (event) => {
        event.stopPropagation();

        if (props.onClick) {
          await props.onClick(event);
          if (isMounted.current) {
            setSuccess(true);
          }
        }
      }}
    >
      {props.iconPosition === "right" ? (
        <>
          {content}
          {icon}
        </>
      ) : (
        <>
          {icon}
          {content}
        </>
      )}
    </button>
  );
}
