import React, { useState } from "react";
import { ProductTag } from "../types/ProductTag";
import { css } from "styled-components";
import { Colors } from "../branding";
import { BlockTitle, fontFamilies } from "../typography";
import { Column, Row, Spacing } from "../helpers/layout";
import { Layout, layouts, getOrderedFilters } from "../helpers/filters";
import xor from "lodash/xor";
import groupBy from "lodash/groupBy";
import { Image } from "./Image";
import { ClearFiltersButton } from "./ClearFiltersButton";
import CheckIcon from "../icons/CheckIcon";
import { Scrollbar } from "./Scrollbar";
import { Wrapper } from "./Wrapper";
import { useKeyPress } from "../helpers/useKeyPress";
import { useResponsive } from "../context/ResponsiveContext";
import { PlainSelect } from "../forms/SelectField";
import { SEARCH_FILTERS_LEVEL } from "../helpers/zIndex";
import { FilterButtons } from "./FilterButtons";
import { MobileFilters } from "./MobileFilters";

type GroupCounts = {
  [key: string]: number;
};

export function SearchDesktopFilters(props: {
  background: "white" | "gray";
  tags: ProductTag[];
  showLayout?: boolean;
  showTitle?: boolean;
  isSticky?: boolean;
  inWrapper?: boolean;
  currentFilters: number[];
  currentLayout?: Layout;
  onLayoutChange?: (layout: Layout) => void;
  onFiltersChange: (options: number[]) => void;
  arrowsInside?: boolean;
  stickyOffset?: number;
}) {
  const { showLayout, showTitle, background, currentLayout, currentFilters } =
    props;
  const groups = groupBy(props.tags, (tag) => tag.category);
  const [currentFilter, setCurrentFilter] = useState<string | null>(null);
  const { isMobile, isTablet } = useResponsive();
  const currentGroup = currentFilter
    ? getOrderedFilters(groups[currentFilter])
    : null;
  const stickyOffset = props.stickyOffset || 0;
  const groupCounts = props.tags.reduce((groupCounts: GroupCounts, tag) => {
    const currentAmount = groupCounts[tag.category] || 0;

    return {
      ...groupCounts,
      [tag.category]: currentFilters.includes(tag.id)
        ? currentAmount + 1
        : currentAmount,
    };
  }, {});

  useKeyPress("Escape", () => {
    setCurrentFilter(null);
  });

  return (
    <div
      css={
        props.isSticky
          ? css`
              position: sticky;
              top: ${stickyOffset}px;
              z-index: ${SEARCH_FILTERS_LEVEL};
              background: ${Colors.white};
              transition: top 0.2s ease-in-out 0s;
              transform: translate3d(0px, 0px, 0px);

              body.header-pinned & {
                top: ${stickyOffset + 70}px;
              }
            `
          : undefined
      }
      onMouseLeave={() => setCurrentFilter(null)}
    >
      <div
        css={css`
          padding: ${isMobile ? Spacing.s : Spacing.m} 0;
        `}
      >
        <Wrapper type="header" disable={!props.inWrapper}>
          {showTitle && isMobile && (
            <div
              css={css`
                margin-bottom: ${Spacing.m};
              `}
            >
              <BlockTitle>Filter by:</BlockTitle>
            </div>
          )}
          <Row justify="space-between" align="center" gutter={Spacing.xxl}>
            {showLayout && !isTablet && (
              <div>
                <Row align="center">
                  <BlockTitle>Layout:</BlockTitle>
                  <Row>
                    {layouts.map((layout) => {
                      const isActive =
                        currentLayout && currentLayout.name === layout.name;

                      return (
                        <div
                          css={css`
                            border: 1px solid
                              ${isActive ? Colors.black : "transparent"};
                          `}
                          onClick={() => {
                            if (props.onLayoutChange) {
                              props.onLayoutChange(layout);
                            }
                          }}
                          key={`layouts--${layout.name}`}
                        >
                          <img
                            style={{
                              height: 25,
                              display: "block",
                            }}
                            src={layout.icon}
                            alt={layout.label}
                          />
                        </div>
                      );
                    })}
                  </Row>
                </Row>
              </div>
            )}
            {showTitle && !isMobile && (
              <div>
                <BlockTitle>Filter by:</BlockTitle>
              </div>
            )}
            <div
              css={css`
                flex: 1 0 20%;
              `}
            >
              <div
                css={css`
                  width: ${isMobile ? "190px" : "100%"};
                `}
              >
                {isMobile ? (
                  <PlainSelect
                    placeholder={"Not applied"}
                    options={Object.keys(groups).map((filter) => {
                      return {
                        value: filter,
                        label: `${filter} (${groupCounts[filter]})`,
                      };
                    })}
                    onChange={(value) => {
                      setCurrentFilter(value);
                    }}
                    value={currentFilter || ""}
                  />
                ) : (
                  <FilterButtons<ProductTag>
                    groups={groups}
                    groupCounts={groupCounts}
                    currentFilter={currentFilter}
                    onOpen={(filter) => setCurrentFilter(filter)}
                    onClose={() => setCurrentFilter(null)}
                    onCancel={(filter) => {
                      const newFilters = currentFilters.filter((filterId) => {
                        const currentFilter = props.tags.find(
                          (tag) => tag.id === filterId
                        );

                        if (!currentFilter) return false;

                        if (currentFilter.category === filter) return false;

                        return true;
                      });

                      props.onFiltersChange(newFilters);
                    }}
                    arrowsInside={props.arrowsInside}
                  />
                )}
              </div>
            </div>
            <div>
              <ClearFiltersButton
                onClick={() => {
                  props.onFiltersChange([]);
                  setCurrentFilter(null);
                }}
                disabled={currentFilters.length < 1}
              />
            </div>
          </Row>
        </Wrapper>
      </div>

      {currentGroup && (
        <div
          css={css`
            padding: 0 ${isMobile ? Spacing.l : Spacing.none};
            background: ${background === "gray"
              ? Colors.very_light_grey
              : Colors.white};
          `}
        >
          <Wrapper type="header" disable={!props.inWrapper}>
            <Scrollbar autoHeight onlyArrows arrowsInside>
              <div
                css={css`
                  padding: ${Spacing.l} 0;
                `}
              >
                <Row>
                  {currentGroup.map((option) => {
                    const isActive = currentFilters.includes(option.id);

                    const bgColor =
                      background === "white"
                        ? Colors.very_light_grey
                        : Colors.white;

                    return (
                      <div
                        css={css`
                          background: ${isActive ? bgColor : "transparent"};
                          padding: ${Spacing.m};
                          flex: 0 0 180px;
                          text-align: center;
                          cursor: pointer;
                          position: relative;
                          &:hover {
                            background: ${bgColor};
                          }
                        `}
                        onClick={() => {
                          const currentGroupOptions = currentGroup.map(
                            (o) => o.id
                          );
                          let options = currentFilters.filter(
                            (optionId) =>
                              optionId === option.id ||
                              !currentGroupOptions.includes(optionId)
                          );

                          options = xor(options, [option.id]);

                          props.onFiltersChange(options);

                          setCurrentFilter(null);
                        }}
                        key={`filter-options--${option.id}`}
                      >
                        <Column>
                          {option.image && (
                            <div
                              css={css`
                                width: 100%;
                                height: 110px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img {
                                  max-width: 100%;
                                  max-height: 100%;
                                }
                              `}
                            >
                              <Image
                                file={option.image}
                                width={180}
                                notResponsive
                              />
                            </div>
                          )}
                          <h4
                            css={css`
                              font-family: ${fontFamilies.freight};
                              font-weight: ${isActive ? 800 : 400};
                              font-size: 16px;
                              letter-spacing: 0.02em;
                              text-transform: capitalize;
                            `}
                          >
                            {option.name}
                          </h4>
                        </Column>
                        {isActive && (
                          <span
                            css={css`
                              position: absolute;
                              right: -6px;
                              top: -7px;
                              svg path {
                                fill: ${Colors.white};
                              }
                            `}
                          >
                            <CheckIcon deleteOnHover />
                          </span>
                        )}
                      </div>
                    );
                  })}
                </Row>
              </div>
            </Scrollbar>
          </Wrapper>
        </div>
      )}
    </div>
  );
}

export function SearchFilters(props: {
  background: "white" | "gray";
  tags: ProductTag[];
  showLayout?: boolean;
  showTitle?: boolean;
  isSticky?: boolean;
  inWrapper?: boolean;
  currentFilters: number[];
  currentLayout?: Layout;
  onLayoutChange?: (layout: Layout) => void;
  onFiltersChange: (options: number[]) => void;
  arrowsInside?: boolean;
  stickyOffset?: number;
}) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return <MobileFilters {...props} />;
  }

  return <SearchDesktopFilters {...props} />;
}
