import React, { useEffect } from "react";
import { countInHomeView } from "../../actions/countInHomeView";
import { InHome } from "../../types/InHome";
import { useResponsive } from "../../context/ResponsiveContext";
import InHomeMobileView from "./InHomeMobileView";
import InHomeDesktopView from "./InHomeDesktopView";

export default function InHomeView(props: {
  inHome: InHome;
  onClose?: () => void;
  allInHomes?: InHome[];
}) {
  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    const countInHome = async () => {
      await countInHomeView(props.inHome.id);
    };

    countInHome();
  }, [props.inHome.id]);

  if (isMobile || isTablet) {
    return (
      <InHomeMobileView
        inHome={props.inHome}
        onClose={props.onClose}
        allInHomes={props.allInHomes}
      />
    );
  }

  return <InHomeDesktopView inHome={props.inHome} onClose={props.onClose} />;
}
