export function sanitizePrice(number: number) {
  if (number) {
    return number.toFixed(2);
  }

  return "0.00";
}

export function sanitizeInput(input: string | number, precision?: number) {
  if (!input) return "";

  const string = input.toString();

  if (string.match(/\.$/)) {
    return string;
  }

  const number = Number(input) || 0;

  if (precision) {
    const newNumber = roundToPrecision(number, precision);

    return newNumber.toString();
  } else {
    return number.toFixed(2);
  }
}

export function roundToPrecision(x: number, precision: number) {
  const y = x + precision / 2;

  return y - (y % precision);
}

export function getFrontendUrl(path: string) {
  return `${process.env.REACT_APP_FRONTEND_URL}${path}`;
}

export function hexToRgb(hex: string) {
  const defaultColor = {
    r: 0,
    g: 0,
    b: 0,
  };

  if (!hex) return defaultColor;

  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, function (_m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : defaultColor;
}

export function normalizeUrl(url: string) {
  const modified = url.replace(/\/$/, "");
  return modified;
}

export function isTheSameUrl(url1: string, url2: string) {
  const normalizedUrl1 = normalizeUrl(url1);
  const normalizedUrl2 = normalizeUrl(url2);

  return normalizedUrl1 === normalizedUrl2;
}
