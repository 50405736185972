import React, { useEffect, useState } from "react";
import { TABLET, MOBILE, INCH_13, SMALL_MOBILE } from "../branding";
import debounce from "lodash/debounce";
import { getSpacing } from "../helpers/block";
import { Padding } from "../types/Block";
import { css } from "styled-components";
import { useCommonData } from "./CommonDataContext";

export type Responsive = {
  innerWidth: number | null;
  isSmallMobile: boolean;
  isMobile: boolean;
  isTablet: boolean;
  is13inch: boolean;
  isIPad: boolean;
  isIPhone: boolean;
  isDesktop: boolean;
  sizeCoefficient: number;
  getFontSize: (size: number, disableMinimalFont?: boolean) => number;
  renderPadding: (padding: Padding) => any;
};

const ResponsiveContext = React.createContext<Responsive>({
  innerWidth: null,
  isSmallMobile: false,
  isMobile: false,
  isTablet: false,
  is13inch: false,
  isDesktop: false,
  isIPad: false,
  isIPhone: false,
  sizeCoefficient: 1,
  getFontSize: () => 16,
  renderPadding: () => null,
});

function getSizeCoefficient(innerWidth: number | null) {
  if (!innerWidth) return 1;

  return innerWidth / 1790;
}

function getDefaultInnerWidth(userAgent: string) {
  const isMobile = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );

  return isMobile ? 375 : INCH_13;
}

function isApple(userAgent: string) {
  return Boolean(userAgent.match(/iPhone|iPad|iPod/i));
}

export function ResponsiveProvider(props: { children: React.ReactNode }) {
  const { userAgent } = useCommonData();

  const defaultInnerWidth = getDefaultInnerWidth(userAgent);
  const isIos = isApple(userAgent);
  const [innerWidth, setInnerWidth] = useState<number | null>(
    defaultInnerWidth
  );
  const isSmallMobile = innerWidth ? innerWidth <= SMALL_MOBILE : false;
  const isMobile = innerWidth ? innerWidth <= MOBILE : false;
  const isIPad = innerWidth
    ? innerWidth <= TABLET && innerWidth >= MOBILE && isIos
    : false;
  const isIPhone = innerWidth ? innerWidth <= MOBILE && isIos : false;
  const isTablet = innerWidth
    ? innerWidth > MOBILE && innerWidth <= TABLET
    : false;
  const is13inch = innerWidth
    ? innerWidth > TABLET && innerWidth <= INCH_13
    : false;
  const isBigScreen = innerWidth ? innerWidth > INCH_13 : false;
  const isDesktop = is13inch || isBigScreen;
  const sizeCoefficient = getSizeCoefficient(innerWidth);

  useEffect(() => {
    const resize = debounce(() => {
      setInnerWidth(window.innerWidth);
    }, 200);

    window.addEventListener("resize", resize);
    window.addEventListener("load", resize);
    document.addEventListener("DOMContentLoaded", resize);

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("load", resize);
      document.removeEventListener("DOMContentLoaded", resize);
    };
  }, []);

  function getFontSize(size: number, disableMinimalFont?: boolean) {
    const newSize = size * sizeCoefficient * 0.95;

    if (disableMinimalFont) {
      return Math.floor(newSize);
    }

    if (size > 50 && newSize < 50) {
      return 50;
    }

    if (size > 30 && newSize < 30) {
      return 30;
    }

    if (size >= 24 && newSize < 24) {
      return 24;
    }

    if (size >= 16 && newSize < 16) {
      return 16;
    }

    if (size < 16) {
      return size;
    }

    return Math.floor(newSize);
  }

  function renderPadding(padding: Padding) {
    return css`
      padding-top: ${getSpacing(padding.top, sizeCoefficient)};
      padding-bottom: ${getSpacing(padding.bottom, sizeCoefficient)};
      padding-left: ${getSpacing(padding.left, sizeCoefficient)};
      padding-right: ${getSpacing(padding.right, sizeCoefficient)};
    `;
  }

  return (
    <ResponsiveContext.Provider
      value={{
        innerWidth,
        isSmallMobile,
        isMobile,
        isIPad,
        isIPhone,
        isTablet,
        is13inch,
        isDesktop,
        sizeCoefficient,
        getFontSize,
        renderPadding,
      }}
    >
      {props.children}
    </ResponsiveContext.Provider>
  );
}

export function useResponsive() {
  const context = React.useContext(ResponsiveContext);

  if (context === undefined) {
    throw new Error("useResponsive must be used within a ResponsiveProvider");
  }

  return context;
}
