import { maybeAuthorised } from "../request";
import { Comparison } from "../types/Comparison";
import { BookOption, ProductSize } from "../types/Product";
import { ProductOption } from "../types/ProductOption";

export async function addToComparison(
  token: string,
  data: {
    product: number;
    size?: ProductSize | null;
    options: ProductOption[];
    order?: number;
    book_option: BookOption | null;
  }
) {
  const API = maybeAuthorised();

  const comparison = await API.request<Comparison>({
    method: "POST",
    url: `/api/frontend/comparison/${token}/`,
    data,
  });

  return comparison;
}
