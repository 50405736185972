import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import { Column, Spacing, Row } from "../helpers/layout";
import styled, { css } from "styled-components";
import { sanitizeInput } from "../helpers/utils";
import { Button } from "../components/Button";
import { Note } from "../components/Note";
import { ProductSize } from "../types/Product";
import { PlainButton } from "../components/PlainButton";
import { Price } from "../components/Price";
import { Link } from "../components/Link";
import { getInterval, calculatePrice, sizeIsValid } from "../helpers/product";
import { useCommonData } from "../context/CommonDataContext";

const FieldWrapper = styled.div`
  flex: 1 0 20%;
`;

type FormValues = {
  width: string;
  height: string;
  price: string;
};

function Recalculate({
  sizes,
  width,
  setFieldValue,
}: {
  sizes: ProductSize[];
  width: number;
  setFieldValue: (name: string, value: any) => void;
}) {
  useEffect(() => {
    const interval = getInterval(width, sizes);

    if (interval) {
      const price = calculatePrice(width, interval);

      setFieldValue("price", sanitizeInput(price, 5));
    }
  }, [setFieldValue, sizes, width]);

  return null;
}

export function PrintSizeForm(props: {
  initialValue: FormValues;
  sizes: ProductSize[];
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
}) {
  const { settings } = useCommonData();

  return (
    <Formik<FormValues>
      initialValues={props.initialValue}
      validationSchema={Yup.object().shape({
        width: Yup.number().required(),
        height: Yup.number().required(),
        price: Yup.number(),
      })}
      onSubmit={async (values: any) => {
        props.onSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => {
        const isValid = sizeIsValid(parseFloat(values.width), props.sizes);

        return (
          <Form>
            <Column gutter={Spacing.xxl}>
              <Row gutter={Spacing.l}>
                <FieldWrapper>
                  <TextField
                    name="width"
                    label="Width (in inches)"
                    type="text"
                    onChange={(event) => {
                      if (!props.initialValue) return;

                      const val = event.target.value;
                      const width = parseFloat(props.initialValue.width);
                      const height = parseFloat(props.initialValue.height);
                      const newWidth = parseFloat(val);
                      const newHeight = (newWidth * height) / width;

                      setFieldValue("width", sanitizeInput(val, 0.5));
                      setFieldValue("height", sanitizeInput(newHeight, 0.5));
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    name="height"
                    label="Height (in inches)"
                    type="text"
                    onChange={(event) => {
                      if (!props.initialValue) return;

                      const val = event.target.value;
                      const width = parseFloat(props.initialValue.width);
                      const height = parseFloat(props.initialValue.height);
                      const newHeight = parseFloat(val);
                      const newWidth = (width * newHeight) / height;

                      setFieldValue("width", sanitizeInput(newWidth, 0.5));
                      setFieldValue("height", sanitizeInput(val, 0.5));
                    }}
                  />
                </FieldWrapper>
                <div
                  css={css`
                    flex: 0 0 auto;
                    width: auto;
                  `}
                >
                  <div
                    css={css`
                      margin-bottom: 20px;
                    `}
                  >
                    <label>Price: </label>
                  </div>
                  <div>
                    <strong>
                      <Price price={values.price} />
                    </strong>
                  </div>
                </div>
              </Row>
              <Recalculate
                sizes={props.sizes}
                width={parseFloat(values.width)}
                setFieldValue={setFieldValue}
              />
              {!isValid && (
                <Note>
                  Chosen dimension exceeds our largest standard size. Please{" "}
                  <Link href="mailto:gallery@drewdoggett.com" pure>
                    email
                  </Link>{" "}
                  or call the studio{" "}
                  <Link href={`tel:${settings.studio_director_phone_number}`}>
                    {settings.studio_director_phone_number}
                  </Link>{" "}
                  to speak to our in-house art advisor for options.
                </Note>
              )}
              <Row justify="space-between" align="center">
                <div>
                  <PlainButton onClick={props.onClose}>Cancel</PlainButton>
                </div>
                <div>
                  <Button type="submit" label="Select" disabled={!isValid} />
                </div>
              </Row>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
}
