import noImage from "../static/images/noimage.jpg";
import { getFrontendUrl } from "./utils";

export type Width = number | "large";
export type Height = number;

export function getImageUrl(
  file: {
    file_path: string;
  } | null,
  size?: {
    width?: Width;
    height?: Height;
    devicePixelRatio?: number;
  }
) {
  if (!file) return getFrontendUrl(noImage);

  const url = `${process.env.REACT_APP_MEDIA_URL}/${file.file_path}`;
  const max = 9999;
  const devicePixelRatio =
    size && size.devicePixelRatio ? size.devicePixelRatio : 1.5;

  if (process.env.REACT_IMAGE_RESIZING === "false") return url;

  if (size && size.width) {
    const width = size.width === "large" ? 1920 : size.width * devicePixelRatio;

    return `${process.env.REACT_APP_RESIZE_URL}/fit/${Math.floor(
      width
    )}/${max}/sm/0/plain/local:///${file.file_path}`;
  }

  if (size && size.height) {
    const height = size.height * devicePixelRatio;

    return `${process.env.REACT_APP_RESIZE_URL}/fit/${max}/${Math.floor(
      height
    )}/sm/0/plain/local:///${file.file_path}`;
  }

  return url;
}
