import React, { useState, useEffect, useCallback } from "react";
import { authorised } from "../request";
import { Wishlist } from "../types/Wishlist";
import { useUserState } from "./UserContext";
import { WishlistItem } from "../types/items/WishlistItem";

const emptyWishlist = {
  uuid: "",
  items: [],
  count: 0
};

const WishlistContext = React.createContext<{
  opened: boolean;
  wishlist: Wishlist;
  fetchWishlist: () => void;
  openWishlist: () => void;
  closeWishlist: () => void;
  isInWishlist: (productId: number) => WishlistItem | undefined;
}>({
  opened: false,
  wishlist: emptyWishlist,
  fetchWishlist: () => {},
  openWishlist: () => {},
  closeWishlist: () => {},
  isInWishlist: () => {
    return undefined;
  }
});

export function WishlistProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated } = useUserState();
  const [opened, setOpened] = useState(false);
  const [wishlist, setWishlist] = useState<Wishlist>(emptyWishlist);

  const fetchWishlist = useCallback(async () => {
    try {
      const API = authorised();
      const response = await API.request<Wishlist>({
        method: "GET",
        url: `/api/frontend/wishlist/`
      });

      setWishlist(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const openWishlist = () => {
    setOpened(true);
  };
  const closeWishlist = () => {
    setOpened(false);
  };

  const isInWishlist = (productId: number) => {
    return wishlist.items.find(i => i.product.id === productId);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchWishlist();
    } else {
      setWishlist(emptyWishlist);
    }
  }, [isAuthenticated]);

  return (
    <WishlistContext.Provider
      value={{
        opened,
        openWishlist,
        closeWishlist,
        wishlist,
        fetchWishlist,
        isInWishlist
      }}
    >
      {props.children}
    </WishlistContext.Provider>
  );
}

export function useWishlist() {
  const context = React.useContext(WishlistContext);

  if (context === undefined) {
    throw new Error("useWishlist must be used within a WishlistProvider");
  }

  return context;
}
