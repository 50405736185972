import React from "react";
import { css } from "styled-components";
import NoteIcon from "../icons/NoteIcon";
import { Row, Column } from "../helpers/layout";

export function Note(props: { children: React.ReactNode }) {
  return (
    <Row>
      <NoteIcon />
      <Column>
        <p
          css={css`
            margin: 0;
          `}
        >
          {props.children}
        </p>
      </Column>
    </Row>
  );
}
