import React, { useRef, useEffect } from "react";
import convert from "htmr";
import draftToHtml from "draftjs-to-html";
import { css } from "styled-components";
import { useResponsive, Responsive } from "../context/ResponsiveContext";
import {
  buttonStyles,
  defaultStyles,
  whiteStyles,
  whiteBorderStyles
} from "./Button";
import { gtag } from "../helpers/gtag";
import EventsTracker from "../helpers/fb";

export function responsiveText(
  text: any,
  responsive: Responsive,
  disableMinimalFont?: boolean
) {
  if (!text) return text;

  const jsonString = JSON.stringify(text);
  const fontSizes = jsonString.replace(/fontsize-([0-9]*)/g, (_value, size) => {
    const newSize = responsive.getFontSize(Number(size), disableMinimalFont);

    return `fontsize-${newSize}`;
  });

  return JSON.parse(fontSizes);
}

export function getCurrentColor(text: any) {
  if (!text) return text;

  const jsonString = JSON.stringify(text);
  const match = jsonString.match(/color-rgb\(([0-9]*,[0-9]*,[0-9]*)\)/);

  if (match) {
    return `rgba(${match[1]})`;
  }

  return "rgba(0,0,0)";
}

const customEntityTransform = (
  options: {
    currentColor: string;
    blackButtons: boolean;
  },
  entity: any,
  text: any
) => {
  const { currentColor, blackButtons } = options;

  if (entity.type === "HR") {
    return `<hr style="display:block;width:100%;max-width:100px;height:1px;border:none;background:${currentColor};" />`;
  }

  if (entity.type === "SPACING") {
    return "<br />";
  }

  if (entity.type === "LINK") {
    const targetOption = entity.data.targetOption || "_self";
    const buttonLook = entity.data.buttonLook;
    const buttonStyle = entity.data.buttonStyle;

    const classes = [];

    if (buttonLook && blackButtons) {
      classes.push("button", "button--default");
    } else if (buttonLook) {
      classes.push("button");

      if (!buttonStyle) {
        classes.push("button--default");
      }

      if (buttonStyle === "white") {
        classes.push("button--white");
      }

      if (buttonStyle === "white-border") {
        classes.push("button--white-border");
      }
    } else {
      classes.push("link");
    }

    let gaProps = "";
    if (entity.data.logClickEvent) {
      gaProps += "data-ga-log='true'";

      if (entity.data.gaIdentifier) {
        gaProps += `data-ga-id="${entity.data.gaIdentifier}"`;
      }
    }

    return `<a ${gaProps} href="${
      entity.data.url
    }" target="${targetOption}" class="${classes.join(
      " "
    )}"><span>${text}</span></a>`;
  }
};

export const EditorContent = React.memo(function (props: {
  text: any;
  responsive?: boolean;
  disableMinimalFont?: boolean;
  blackButtons?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const responsive = useResponsive();
  const disableMinimalFont = props.disableMinimalFont || false;
  const text = props.responsive
    ? responsiveText(props.text, responsive, disableMinimalFont)
    : props.text;
  const currentColor = getCurrentColor(props.text);
  const blackButtons = props.blackButtons || false;

  useEffect(() => {
    if (!ref.current) return;

    const links = Array.from(ref.current.getElementsByTagName("a"));

    const handleClick = (event: MouseEvent) => {
      const target = event.currentTarget as HTMLAnchorElement;

      if (!target) return;

      const shouldLogEvent = target.dataset.gaLog === "true";

      if (shouldLogEvent) {
        const button_id = target.dataset.gaId || "";
        gtag("button_click", { button_id });
        EventsTracker.trackCustom("ButtonClick", {
          button_id
        });
      }

      const href = target.getAttribute("href");
      if (href && href.startsWith("#")) {
        if (href === "#affirm-qualify") {
          event.preventDefault();

          window.affirm.ui.ready(function () {
            window.affirm.ui.openModal();
          });
        } else {
          const section = document.querySelector(href);
          if (section) {
            event.preventDefault();
            section.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    };

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", handleClick);
    }

    return () => {
      for (let i = 0; i < links.length; i++) {
        links[i].removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      css={css`
        img + p {
          clear: both;
          max-width: 100%;
        }
        .button {
          ${buttonStyles}
          display: inline-block;
          text-decoration: none;
        }

        .button--default {
          ${defaultStyles}
        }

        .button--white {
          ${whiteStyles}
        }

        .button--white-border {
          ${whiteBorderStyles}
        }

        a:not(.button) {
          color: inherit;
        }

        p[style*="text-align:right"] + hr,
        p[style*="text-align: right"] + hr {
          margin: 0 0 0 auto;
        }

        p[style*="text-align:center"] + hr,
        p[style*="text-align: center"] + hr {
          margin: 0 auto 0 auto;
        }

        p[style*="text-align:left"] + hr,
        p[style*="text-align: left"] + hr {
          margin: 0 auto 0 0;
        }
      `}
    >
      {convert(
        draftToHtml(
          text,
          null as any,
          false,
          customEntityTransform.bind(null, {
            currentColor,
            blackButtons
          })
        )
      )}
    </div>
  );
});
