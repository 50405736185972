import { useEffect } from "react";

export function useKeyPress(targetKey: string, callback: () => void) {
  function downHandler(event: KeyboardEvent) {
    if (event.key === targetKey) {
      callback();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);
}
