const NoteIcon = () => (
  <svg width="21px" height="21px" viewBox="0 0 21 21">
    <path
      d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z"
      transform="translate(9.965846 5.921176)"
      fill="#000000"
      stroke="none"
    />
    <path
      d="M2.25 6.58824L2.25 0L0 0L0 0.411765L0.75 0.411765L0.75 6.58824L0 6.58824L0 7L3 7L3 6.58824L2.25 6.58824Z"
      transform="translate(9.965846 8.921176)"
      fill="#000000"
      stroke="none"
    />
    <path
      d="M0 10C0 4.47596 4.47596 0 10 0C15.524 0 20 4.47596 20 10C20 15.524 15.524 20 10 20C4.47596 20 0 15.524 0 10ZM0.831731 10C0.831731 15.0577 4.94712 19.1683 10 19.1683C15.0529 19.1683 19.1683 15.0529 19.1683 10C19.1683 4.94231 15.0529 0.831731 10 0.831731C4.94231 0.831731 0.831731 4.94712 0.831731 10Z"
      transform="translate(0.9658459 0.9211762)"
      fill="#000000"
      fillRule="evenodd"
      stroke="none"
    />
  </svg>
);
export default NoteIcon;
