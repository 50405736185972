import React from "react";
import { useState } from "react";
import { css } from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useUserState } from "../context/UserContext";
import { Button } from "../components/Button";
import { Link } from "../components/Link";
import { TextField } from "./TextField";
import { Column, Row, Spacing } from "../helpers/layout";
import { Message } from "../components/Message";
import { useResponsive } from "../context/ResponsiveContext";

interface FormValues {
  email: string;
  password: string;
}

export default function SignInForm(props: { onSignIn?: () => void }) {
  const { login } = useUserState();
  const [error, setError] = useState<string | null>(null);
  const { isMobile } = useResponsive();

  return (
    <Formik<FormValues>
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("Enter a valid email"),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={async (values: FormValues, { setSubmitting }) => {
        setError(null);
        try {
          setSubmitting(true);

          if (login) {
            await login(values);
          }

          if (props.onSignIn) {
            props.onSignIn();
          }
        } catch (error) {
          setError(error.toString());
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Column gutter={isMobile ? Spacing.m : Spacing.l}>
            <TextField name="email" type="email" placeholder="Email address" />
            <TextField name="password" type="password" placeholder="Password" />
            {error && <Message text={error} />}

            <Row
              justify="flex-end"
              css={css`
                margin-top: 20px;
              `}
            >
              <Column
                css={css`
                  flex: 0 0 140px;
                  text-align: center;
                `}
              >
                <div>
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    label={isSubmitting ? "Signing in..." : "Sign in"}
                  />
                </div>

                <Link
                  href="/reset-password"
                  css={css`
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.05em;
                  `}
                >
                  Forgot password?
                </Link>
              </Column>
            </Row>
          </Column>
        </Form>
      )}
    </Formik>
  );
}
