import HeartIcon from "../icons/HeartIcon";
import { PlainButton } from "./PlainButton";
import { useWishlist } from "../context/WishlistContext";
import { addToWishlist } from "../actions/addToWishlist";
import { deleteWishlistItem } from "../actions/deleteWishlistItem";
import { useState } from "react";
import Spinner from "react-spinners/ClipLoader";
import { css } from "styled-components";
import { useUserState } from "../context/UserContext";
import { useModal } from "../context/ModalContext";
import HeartFilledIcon from "../icons/HeartFilledIcon";
import { addToRecentlyViewed } from "../actions/addToRecentlyViewed";
import { useRecentlyViewed } from "../context/RecentlyViewedContext";
import { BookOption } from "../types/Product";

export function AddToWishlistButton(props: {
  productId: number;
  disabled?: boolean;
  bookOptions?: BookOption[];
}) {
  const [loading, setLoading] = useState(false);
  const { showModal } = useModal();
  const { isAuthenticated } = useUserState();
  const { fetchWishlist, isInWishlist, wishlist } = useWishlist();
  const { recentlyViewed, fetchRecentlyViewed } = useRecentlyViewed();
  const inWishlish = isInWishlist(props.productId);

  return (
    <PlainButton
      hoverEffect
      onClick={async () => {
        if (!isAuthenticated) {
          showModal("login", {
            type: "wishlist",
          });
        } else {
          setLoading(true);

          if (inWishlish) {
            await deleteWishlistItem(wishlist.uuid, inWishlish.id);
          } else {
            await addToWishlist({
              product: props.productId,
              options: [],
              book_option: props.bookOptions ? props.bookOptions[0] : null,
            });

            // Add item to recently viewed
            await addToRecentlyViewed(recentlyViewed.uuid, {
              product: props.productId,
            });

            await fetchRecentlyViewed();
          }

          await fetchWishlist();

          setLoading(false);
        }
      }}
      css={css`
        svg {
          width: 16px;

          path {
            stroke-width: 9;
          }
        }
      `}
      icon={
        loading ? (
          <Spinner size={16} color="currentColor" />
        ) : inWishlish ? (
          <HeartFilledIcon />
        ) : (
          <HeartIcon />
        )
      }
      disabled={props.disabled}
      iconPosition="left"
    >
      {inWishlish ? "Remove from wishlist" : "Add to Wishlist"}
    </PlainButton>
  );
}
