import React from "react";
import { ProductOption } from "../types/ProductOption";
import { css } from "styled-components";
import { Column, Spacing } from "../helpers/layout";
import { Image } from "./Image";
import CheckIcon from "../icons/CheckIcon";
import { Price } from "./Price";

export function PrintOptionPreview(props: {
  option: ProductOption;
  price: number;
  discount: number;
  isSelected: boolean;
  onClick: () => void;
  disablePrices?: boolean;
}) {
  return (
    <div
      onClick={() => props.onClick()}
      css={css`
        opacity: ${props.isSelected ? "1" : "0.5"};
        text-align: center;
        transition: opacity 200ms;
        cursor: pointer;
      `}
    >
      <Column gutter={Spacing.m}>
        {props.option.icon && (
          <div
            css={css`
              position: relative;
            `}
          >
            <Image
              style={{
                height: 45,
                objectFit: "contain",
              }}
              file={props.option.icon}
              height={45}
            />
            {props.isSelected && (
              <span
                css={css`
                  position: absolute;
                  right: -7px;
                  top: -7px;
                `}
              >
                <CheckIcon />
              </span>
            )}
          </div>
        )}
        <Column gutter={Spacing.xs}>
          <h4
            css={css`
              letter-spacing: 0.05em;
            `}
          >
            {props.option.name}
          </h4>
          {!props.disablePrices && (
            <h4>
              + <Price price={props.price} discount={props.discount} />
            </h4>
          )}
        </Column>
      </Column>
    </div>
  );
  ``;
}
