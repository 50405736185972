import React from "react";
import { BaseModal } from "./BaseModal";
import { ProductSize } from "../types/Product";
import { PrintSizeForm } from "../forms/PrintSizeForm";
import { css } from "styled-components";
import { Column, Spacing } from "../helpers/layout";
import { ModalTitle } from "../typography";
import { useResponsive } from "../context/ResponsiveContext";

export function SizeConfigurator(props: {
  visible: boolean;
  initialValue: ProductSize;
  sizes: ProductSize[];
  onClose: () => void;
  onSubmit: (size: ProductSize) => void;
}) {
  const { sizes, initialValue } = props;
  const { isMobile } = useResponsive();

  if (!sizes.length) return null;

  return (
    <BaseModal visible={props.visible} onClose={props.onClose}>
      <div
        css={css`
          padding: ${isMobile ? Spacing.xl : Spacing.xxl};
          max-width: 660px;
          width: 100vw;
          box-sizing: border-box;
          background: #fff;
          color: #000;
        `}
      >
        <Column gutter={Spacing.xxl}>
          <ModalTitle>Enter dimensions below</ModalTitle>
          <PrintSizeForm
            initialValue={{
              width: initialValue.width.toString(),
              height: initialValue.height.toString(),
              price: initialValue.price.toString()
            }}
            sizes={sizes}
            onSubmit={size => {
              props.onSubmit({
                width: parseFloat(size.width),
                height: parseFloat(size.height),
                price: size.price
              });
            }}
            onClose={props.onClose}
          />
        </Column>
      </div>
    </BaseModal>
  );
}
