const InfoIcon = () => (
  <svg width="15" height="15" viewBox="0 0 450 450">
    <path
      width="100%"
      height="100%"
      d="M225,0C100.74,0,0,100.74,0,225S100.74,450,225,450,450,349.26,450,225,349.26,0,225,0ZM199,322.12v-103a26,26,0,0,1,52,0v103a26,26,0,0,1-52,0Zm26-157.37a31.25,31.25,0,1,1,31.25-31.25A31.25,31.25,0,0,1,225,164.75Z"
    />
  </svg>
);
export default InfoIcon;
