import React, { useEffect } from "react";
import { useState } from "react";
import { css } from "styled-components";
import styled from "styled-components";
import { Formik, Field, Form } from "formik";
import { SelectField } from "./SelectField";
import * as Yup from "yup";
import { Button } from "../components/Button";
import { FieldError, TextField } from "./TextField";
import { Colors } from "../branding";
import { Column, Row, Spacing } from "../helpers/layout";
import { useUserState } from "../context/UserContext";
import { RegistrationValues, notAuthorised } from "../request";
import { Message } from "../components/Message";
import { useResponsive } from "../context/ResponsiveContext";
import { mixpanelEvent } from "../helpers/analytics";
import debounce from "lodash/debounce";
import { PlainCheckbox } from "./fields/PlainCheckbox";
import { Link } from "../components/Link";
import { useCommonData } from "../context/CommonDataContext";

export const StyledField = styled(Field)`
  border: none;
  background: none;
  border-bottom: 1px solid ${Colors.light_grey};
  font-size: 16px;
  outline: none;
  margin-top: 40px;
  :focus {
    border-bottom: 1px solid ${Colors.black};
  }
  padding: 10px 0;
  ::placeholder {
    color: ${Colors.dark_gray};
  }
`;

const validateEmail = debounce(async (email: string) => {
  try {
    await notAuthorised.request({
      method: "POST",
      url: "/api/frontend/registration/check-email/",
      data: {
        email,
      },
    });
  } catch {
    return "The email address you have entered is already registered";
  }

  return "";
}, 300);

export default function SignUpForm(props: { onSignUp?: () => void }) {
  const [error, setError] = useState<string | null>(null);
  const [isChecked, setChecked] = useState<boolean>(false);
  const { register } = useUserState();
  const { isMobile } = useResponsive();
  const { howYouFoundDrew } = useCommonData();
  const howYouFoundDrewOptions = howYouFoundDrew?.items || [];

  useEffect(() => {
    mixpanelEvent("Registration Form Seen");
  }, []);

  return (
    <Formik<RegistrationValues>
      initialValues={{
        email: "",
        password: "",
        password2: "",
        first_name: "",
        last_name: "",
        how_did_you_find: "",
        how_did_you_find_value: "",
        tos: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("Enter a valid email"),
        password: Yup.string()
          .required("No password provided")
          .min(8, "Password is too short - should be 8 characters min.")
          .matches(/^.*\D+.*$/, "Password contains only numbers"),
        password2: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Password confirm is required"),
        first_name: Yup.string().required("First Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        how_did_you_find: Yup.string().required("This field is required"),
        how_did_you_find_value: Yup.string().when(
          "how_did_you_find",
          (
            how_did_you_find: string,
            schema: { required: (arg0: string) => void },
          ) => {
            const howDidYouFind = howYouFoundDrewOptions.find(
              (option) => option.name === how_did_you_find,
            );
            if (howDidYouFind && howDidYouFind.show_custom_field) {
              return schema.required("This field is required");
            }
            return schema;
          },
        ),
        tos: Yup.boolean().oneOf(
          [true],
          "You have to accept our Terms and Conditions",
        ),
      })}
      onSubmit={async (values: RegistrationValues, { setSubmitting }) => {
        setError(null);

        try {
          setSubmitting(true);

          if (register) {
            await register(values);

            if (props.onSignUp) {
              props.onSignUp();
            }
          }
        } catch (error) {
          setError(
            error.response.data
              ? Object.values(error.response.data).join(", ")
              : "Something went wrong",
          );
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => {
        const howDidYouFind = howYouFoundDrewOptions.find(
          (option) => option.name === values.how_did_you_find,
        );

        return (
          <Form noValidate>
            <Column gutter={isMobile ? Spacing.m : Spacing.l}>
              <TextField name="first_name" placeholder="First name" />
              <TextField name="last_name" placeholder="Last name" />
              <TextField
                name="email"
                type="email"
                placeholder="Email address"
                validate={validateEmail}
              />
              <TextField
                name="password"
                type="password"
                placeholder="Password"
              />
              <TextField
                name="password2"
                type="password"
                placeholder="Confirm Password"
              />
              <SelectField
                name="how_did_you_find"
                placeholder="How did you find Drew?"
                options={howYouFoundDrewOptions.map((option) => ({
                  value: option.name,
                  label: option.name,
                }))}
              />
              {howDidYouFind && howDidYouFind.show_custom_field && (
                <TextField
                  name="how_did_you_find_value"
                  placeholder="Please specify"
                />
              )}

              <PlainCheckbox
                checked={isChecked}
                onChange={(val) => {
                  setFieldValue("tos", val);
                  setChecked(!isChecked);
                }}
              >
                I agree to the{" "}
                <Link href="/terms-of-use" underlined target="_blank">
                  terms and conditions
                </Link>{" "}
                of this website
              </PlainCheckbox>
              <FieldError name="tos" />

              {error && <Message text={error} />}

              <Row
                justify="flex-end"
                css={css`
                  margin-top: 20px;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  label={isSubmitting ? "Signing up..." : "Sign up"}
                ></Button>
              </Row>
            </Column>
          </Form>
        );
      }}
    </Formik>
  );
}
