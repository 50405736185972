import React, { useState } from "react";
import { MobileModal } from "../modals/MobileModal";
import styled, { css } from "styled-components";
import { Spacing } from "../helpers/layout";
import { PlainButton } from "./PlainButton";

const Label = styled.div`
  line-height: 1;
  cursor: pointer;
`;

export function MobileDropdown(props: {
  label?: string;
  icon?: JSX.Element;
  title: string;
  active?: boolean;
  bottom?: (options: { close: () => void }) => React.ReactNode;
  children: (options: { close: () => void }) => React.ReactNode;
  additionalAction?: React.ReactNode | null;
}) {
  const [opened, setOpened] = useState(false);

  const close = () => {
    setOpened(false);
  };

  return (
    <React.Fragment>
      <Label
        css={`
          position: relative;
        `}
      >
        <PlainButton
          onClick={() => setOpened((prevOpened) => !prevOpened)}
          icon={props.icon}
          iconPosition="right"
        >
          {props.active ? <strong>{props.label}</strong> : props.label}
        </PlainButton>
        {props.additionalAction ? (
          <div
            css={`
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              z-index: 3;
            `}
          >
            {props.additionalAction}
          </div>
        ) : null}
      </Label>
      <MobileModal
        title={props.title}
        visible={opened}
        onClose={() => {
          setOpened(false);
        }}
        bottom={props.bottom ? props.bottom({ close }) : null}
      >
        <div
          css={css`
            padding-top: ${Spacing.xl};
          `}
        >
          {props.children({ close })}
        </div>
      </MobileModal>
    </React.Fragment>
  );
}
