import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Row, Spacing } from "../helpers/layout";
import { Colors } from "../branding";
import CheckIcon from "../icons/CheckIcon";
import xor from "lodash/xor";
import ArrowRight from "../icons/ArrowRight";
import { fontFamilies } from "../typography";
import { getImageUrl } from "../helpers/getImageUrl";
import { Media } from "../types/Media";

type Filter = {
  id: number;
  name: string;
  image: Media | null;
};

const H4 = styled.h4<{
  bold?: boolean;
}>`
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: ${fontFamilies.freight};

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `};
`;

const Option = styled.h4<{
  bold?: boolean;
}>`
  font-weight: 400;

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`;

export function FilterSection(props: {
  title: string;
  options: Array<Filter>;
  currentFilters: number[];
  onSelect: (filters: number[]) => void;
}) {
  const [opened, setOpened] = useState(false);

  const count = props.options.reduce((count: number, tag) => {
    return props.currentFilters.includes(tag.id) ? count + 1 : count;
  }, 0);

  const hasImages = props.options.reduce((hasImages: boolean, tag) => {
    if (hasImages) return hasImages;

    return !!tag.image;
  }, false);

  return (
    <div>
      <div
        css={css`
          cursor: pointer;
          padding-bottom: ${Spacing.m};
          border-bottom: 1px solid ${Colors.light_grey};
        `}
        onClick={() => {
          setOpened((opened) => !opened);
        }}
      >
        <Row>
          <div
            css={css`
              flex: 1 0 20%;
            `}
          >
            <H4 bold={count > 0}>{props.title}</H4>
          </div>
          <div
            css={css`
              position: relative;
              flex: 0 0 30px;
            `}
          >
            <div
              css={css`
                position: absolute;
                transform: rotate(90deg);
                transition: transform 300ms;

                ${opened &&
                css`
                  transform: rotate(270deg);
                `}

                svg {
                  width: 7px;
                }
              `}
            >
              <ArrowRight />
            </div>
          </div>
        </Row>
      </div>
      {opened && (
        <div>
          {props.options.map((option) => {
            const isSelected = props.currentFilters.includes(option.id);

            return (
              <div
                key={`option--${option.id}`}
                css={css`
                  padding: ${Spacing.m} 0;
                  min-height: 20px;
                  cursor: pointer;
                `}
                onClick={() => {
                  const currentGroupOptions = props.options.map((o) => o.id);
                  let options = props.currentFilters.filter(
                    (optionId) =>
                      optionId === option.id ||
                      !currentGroupOptions.includes(optionId)
                  );
                  options = xor(options, [option.id]);

                  props.onSelect(options);
                }}
              >
                <Row>
                  {hasImages && (
                    <div
                      css={css`
                        flex: 0 0 80px;

                        img {
                          width: 100%;
                        }
                      `}
                    >
                      <img src={getImageUrl(option.image)} alt={option.name} />
                    </div>
                  )}
                  <div
                    css={css`
                      flex: 1 0 10%;
                    `}
                  >
                    <Option bold={isSelected}>{option.name}</Option>
                  </div>
                  <div
                    css={css`
                      flex: 0 0 36px;
                    `}
                  >
                    {isSelected && <CheckIcon />}
                  </div>
                </Row>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
