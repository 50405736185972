const BagIcon = () => (
  <svg width="16px" viewBox="0 0 20 27">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#FFFFFF" fillRule="nonzero">
        <path d="M18.8037,5.9256 L15.3003,5.9256 L15.3003,5.4321 C15.3003,2.4375 12.8628,0 9.8682,0 C6.8706,0 4.4331,2.4375 4.4331,5.4321 L4.4331,5.9256 L0.9963,5.9256 C0.4968,5.9256 0.0879,6.3312 0.0879,6.834 L0.0879,26.0916 C0.0879,26.5941 0.4968,27 0.9963,27 L18.8034,27 C19.3029,27 19.7118002,26.5941 19.7118002,26.0916 L19.7118002,6.834 C19.7121,6.3315 19.3032,5.9256 18.8037,5.9256 Z M5.6442,5.4321 C5.6442,3.1035 7.5396,1.2111 9.8682,1.2111 C12.1968,1.2111 14.0892,3.1035 14.0892,5.4321 L14.0892,5.9256 L5.6442,5.9256 L5.6442,5.4321 Z M17.8953,25.1832 L1.9047,25.1832 L1.9047,7.7424 L4.4331,7.7424 L4.4331,9.6408 C4.4331,9.9768 4.7055,10.2465 5.0388,10.2465 C5.3748,10.2465 5.6445,9.9771 5.6445,9.6408 L5.6445,7.7424 L14.0895,7.7424 L14.0895,9.6408 C14.0895,9.9768 14.3619,10.2465 14.6952,10.2465 C15.0282,10.2465 15.3009,9.9771 15.3009,9.6408 L15.3009,7.7424 L17.8959,7.7424 L17.8959,25.1832 L17.8953,25.1832 Z"></path>
      </g>
    </g>
  </svg>
);
export default BagIcon;
