export const CANVAS_LEVEL = 1;

export const FILTERS_LEVEL = CANVAS_LEVEL + 1;

export const IN_HOMES_FILTERS_LEVEL = FILTERS_LEVEL;
export const IN_PAGE_MENU_LEVEL = FILTERS_LEVEL;
export const SEARCH_FILTERS_LEVEL = FILTERS_LEVEL;
export const IN_PAGE_MENU_MOBILE_LEVEL = SEARCH_FILTERS_LEVEL + 1;
export const BIGGEST_FILTERS_LEVEL = Math.max(
  IN_HOMES_FILTERS_LEVEL,
  IN_PAGE_MENU_LEVEL,
  SEARCH_FILTERS_LEVEL,
  IN_PAGE_MENU_MOBILE_LEVEL
);

export const OLARK_WIDGET = 9000000;

export const HEADER_DESKTOP_LEVEL = BIGGEST_FILTERS_LEVEL + OLARK_WIDGET + 1;
export const HEADER_MOBILE_LEVEL = BIGGEST_FILTERS_LEVEL + OLARK_WIDGET + 1;
export const HEADER_DESKTOP_DROPDOWN_LEVEL = HEADER_DESKTOP_LEVEL + 1;
export const MODAL_LEVEL = HEADER_DESKTOP_LEVEL + 1;
export const COOKIE_LEVEL = HEADER_DESKTOP_LEVEL;
export const HIGHEST_LEVEL = 2147483646;
