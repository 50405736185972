import { maybeAuthorised } from "../request";
import { RecentlyViewed } from "../types/RecentlyViewed";

export async function addToRecentlyViewed(
  token: string,
  data: {
    product: number;
  }
) {
  const API = maybeAuthorised();

  const recentlyViewed = await API.request<RecentlyViewed>({
    method: "POST",
    url: `/api/frontend/recently-viewed/${token}/`,
    data
  });

  return recentlyViewed;
}
