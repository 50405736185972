import { useState } from "react";
import React from "react";
import { InHome } from "types/InHome";
import { Scrollbar } from "./Scrollbar";
import { Row } from "helpers/layout";
import { PlainButton } from "./PlainButton";
import { Image } from "./Image";
import { Link } from "./Link";
import { InHomeModal } from "modals/InHomeModal";
import { useResponsive } from "context/ResponsiveContext";

export default function ProductAdditionalImages({
  inHomes,
}: {
  inHomes: InHome[];
}) {
  const { isMobile, isTablet } = useResponsive();
  const isSmall = isTablet || isMobile;
  const [openedInHome, setInHomeOpened] = useState<InHome | null>(null);

  return (
    <div>
      {inHomes.length > 0 && (
        <>
          <Scrollbar autoHeight>
            <Row>
              {inHomes.map((inHome) => (
                <PlainButton
                  key={`in-homes--${inHome.id}`}
                  onClick={() => setInHomeOpened(inHome)}
                >
                  <Image
                    key={`in-home--${inHome.id}`}
                    style={{
                      maxWidth: "none",
                      width: "auto",
                      height: "80px",
                    }}
                    file={inHome.image}
                    height={80}
                  />
                </PlainButton>
              ))}
            </Row>
          </Scrollbar>
          {openedInHome && (
            <InHomeModal
              inHome={openedInHome}
              allInHomes={inHomes}
              visible={true}
              onClose={() => {
                setInHomeOpened(null);
              }}
            />
          )}
        </>
      )}
      {!isSmall && (
        <p
          css={`
            margin: 0;
          `}
        >
          See additional{" "}
          <Link href={"/in-homes"} underlined>
            Installation Images
          </Link>
        </p>
      )}
    </div>
  );
}
