import { useResponsive } from "../../context/ResponsiveContext";
import { Image } from "../Image";
import { Media } from "../../types/Media";

const InHomeMobileItemImage = (props: { image: Media | null }) => {
  const { isTablet } = useResponsive();

  return (
    <div>
      <Image
        file={props.image}
        style={{
          maxHeight: isTablet ? "60vh" : "100vh",
          maxWidth: "100%",
          width: "auto",
          height: "auto",
          display: "block",
          margin: "0 auto",
        }}
        width="large"
      />
    </div>
  );
};

export default InHomeMobileItemImage;
