import React from 'react';
import { css } from 'styled-components';
import { Row, Spacing } from '../helpers/layout';
import { ProductAward as ProductAwardType } from '../types/Product';
import { ProductAward } from './ProductAwards';


const ShopProductAwards = (props: { awards: ProductAwardType[] }) => {
  return (
    <>
      {props.awards && props.awards.length > 0 && (
        <div
          css={css`
            margin-top: ${Spacing.xxl};
          `}
        >
          <Row align="center" justify="center" gutter={Spacing.xl}>
            {props.awards.map((award) => {
              return (
                <div
                  key={`product-awards--${award.id}`}
                  css={css`
                    text-align: left;
                  `}
                >
                  <ProductAward
                    award={award}
                    key={`product-award--${award.id}`}
                    showTitle={props.awards.length === 1}
                  />
                </div>
              );
            })}
          </Row>
        </div>
      )}
    </>
  )
}

export default ShopProductAwards
