import { HEADER_MOBILE_LEVEL } from "../helpers/zIndex";
import { Colors } from "../branding";
import { Spacing, Row } from "../helpers/layout";
import { css } from "styled-components";
import { useState, useEffect, useMemo } from "react";
import ArrowUp from "../icons/ArrowUp";
import CloseIcon from "../icons/CloseIcon";
import { ProductPreview } from "./ProductPreview";
import { ProductSize, Product } from "../types/Product";
import { ProductOption } from "../types/ProductOption";
import InfoIcon from "../icons/InfoIcon";

const HEIGHT_TRANSITION_DURATION = 350;

const Underlay = ({
  open,
  onClose,
  zIndex,
}: {
  open: boolean;
  onClose: () => void;
  zIndex: number;
}) => (
  <div
    css={`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: ${zIndex};
      background: rgba(0, 0, 0, 0.5);
      opacity: ${open ? 1 : 0};
      transition: opacity ${HEIGHT_TRANSITION_DURATION}ms;
      pointer-events: ${open ? "auto" : "none"};
    `}
    onClick={onClose}
  />
);

const ModalHeader = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Row
    align="center"
    justify="space-between"
    onClick={() => setOpen((o) => !o)}
    css={`
      background: ${Colors.light_grey};
      padding: ${Spacing.m};
    `}
  >
    <a
      href="mailto:gallery@drewdoggett.com"
      title="Need Help?"
      css={`
        svg {
          width: 20px;
          height: auto;
        }
      `}
    >
      <InfoIcon />
    </a>
    <span
      css={`
        height: 40px;
        letter-spacing: 0.04em;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
      `}
    >
      Preview Changes
    </span>
    <div
      css={`
        svg {
          width: 18px;
          height: auto;
        }
      `}
    >
      {open ? <CloseIcon /> : <ArrowUp />}
    </div>
  </Row>
);

const MobilePrintPreviewModal = ({
  product,
  size,
  options,
}: {
  product: Product;
  size: ProductSize;
  options: ProductOption[];
}) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);

  const isFramed = useMemo(
    () => options.length && options.every((o) => o.name !== "Unframed"),
    [options]
  );

  useEffect(() => {
    if (!show && isFramed) {
      setShow(true);
    }
  }, [isFramed, show]);

  const zIndexBase = useMemo(() => {
    if (open) {
      return HEADER_MOBILE_LEVEL;
    }
    return 10;
  }, [open]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setContentVisible(false);
      }, HEIGHT_TRANSITION_DURATION);
    } else {
      setContentVisible(true);
    }
  }, [open]);

  return (
    <>
      <Underlay
        open={open}
        onClose={() => setOpen(false)}
        zIndex={zIndexBase + 1}
      />

      <div
        css={css`
          position: fixed;
          width: 100%;
          box-sizing: border-box;
          left: 0;
          bottom: ${show || open ? "0px" : "-72px"};
          transition: bottom 300ms;
          z-index: ${zIndexBase + 2};
          text-align: center;
        `}
      >
        <ModalHeader open={open} setOpen={setOpen} />
        <div
          css={`
            height: ${open ? "80svh" : "0"};
            transition: height ${HEIGHT_TRANSITION_DURATION}ms ease-in-out;
          `}
        >
          {contentVisible ? (
            <div
              css={`
                display: flex;
                flex-direction: column;
                background-color: #fff;
                width: 100%;
                height: 100%;
              `}
            >
              <div
                css={`
                  margin-block: auto;
                  padding-inline: ${Spacing.m};
                `}
              >
                <ProductPreview
                  product={product}
                  printSize={size}
                  options={options}
                  enableZoom={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MobilePrintPreviewModal;
