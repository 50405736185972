import PlusIcon from "../icons/PlusIcon";
import { PlainButton } from "./PlainButton";
import { addToComparison } from "../actions/addToComparison";
import { useComparison } from "../context/ComparisonContext";
import { deleteComparisonItem } from "../actions/deleteComparisonItem";
import Spinner from "react-spinners/ClipLoader";
import { useState } from "react";
import MinusIcon from "../icons/MinusIcon";
import { addToRecentlyViewed } from "../actions/addToRecentlyViewed";
import { useRecentlyViewed } from "../context/RecentlyViewedContext";
import { BookOption } from "../types/Product";

export function CompareButton(props: {
  productId: number;
  disabled?: boolean;
  bookOptions?: BookOption[];
}) {
  const [loading, setLoading] = useState(false);
  const { comparison, fetchComparison, isInComparison } = useComparison();
  const { recentlyViewed, fetchRecentlyViewed } = useRecentlyViewed();
  const inComparison = isInComparison(props.productId);

  return (
    <PlainButton
      hoverEffect
      onClick={async () => {
        setLoading(true);
        if (inComparison) {
          await deleteComparisonItem(comparison.uuid, inComparison.id);
        } else {
          await addToComparison(comparison.uuid, {
            product: props.productId,
            options: [],
            book_option: props.bookOptions ? props.bookOptions[0] : null,
          });

          // Add item to recently viewed
          await addToRecentlyViewed(recentlyViewed.uuid, {
            product: props.productId,
          });

          await fetchRecentlyViewed();
        }

        await fetchComparison();
        setLoading(false);
      }}
      icon={
        loading ? (
          <Spinner size={16} color="currentColor" />
        ) : inComparison ? (
          <MinusIcon />
        ) : (
          <PlusIcon />
        )
      }
      iconPosition="left"
      disabled={props.disabled}
    >
      Compare
    </PlainButton>
  );
}
