import React, { useRef } from "react";
import { Colors } from "../branding";
import * as RSC from "react-scrollbars-custom";
import { css } from "styled-components";
import ArrowLeft from "../icons/ArrowLeft";
import ArrowRight from "../icons/ArrowRight";
import { Row, Spacing } from "../helpers/layout";

const styleArrowsInside = css`
  width: 100%;
  padding: 0 ${Spacing.xl};
  box-sizing: border-box;
`;

export function Scrollbar(props: {
  height?: string | number;
  autoHeight?: boolean;
  onlyArrows?: boolean;
  arrowsInside?: boolean;
  children: React.ReactNode | React.ReactNode[];
}) {
  const ref = useRef<any>(null);
  const { onlyArrows } = props;

  const scrollbar = (
    <RSC.Scrollbar
      ref={ref}
      translateContentSizeYToHolder={props.autoHeight}
      style={{
        width: "100%",
        height: props.height || undefined
      }}
      renderer={props => {
        const { style, elementRef, ...restProps } = props;
        return (
          <div
            {...restProps}
            ref={elementRef}
            style={{
              ...style,
              paddingBottom: onlyArrows ? "0" : "40px"
            }}
          />
        );
      }}
      wrapperProps={{
        renderer: props => {
          const { style, elementRef, ...restProps } = props;

          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                ...style,
                bottom: onlyArrows ? 0 : 10
              }}
            />
          );
        }
      }}
      scrollerProps={{
        renderer: props => {
          const { style, elementRef, ...restProps } = props;

          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                ...style,
                paddingBottom: onlyArrows ? 0 : 20
              }}
            />
          );
        }
      }}
      trackXProps={{
        renderer: props => {
          const { style, elementRef, ...restProps } = props;
          const trackVisible =
            ref.current && ref.current.state && ref.current.state.trackXVisible;

          const arrowLeft = (
            <div
              css={css`
                position: relative;
                cursor: pointer;
              `}
              onClick={() => {
                if (!ref.current) return;

                const scrollLeft = ref.current.scrollLeft;

                ref.current.scrollTo(scrollLeft - 100);
              }}
            >
              <ArrowLeft />
            </div>
          );

          const arrowRight = (
            <div
              css={css`
                position: relative;
                cursor: pointer;
              `}
              onClick={() => {
                if (!ref.current) return;

                const scrollLeft = ref.current.scrollLeft;

                ref.current.scrollTo(scrollLeft + 100);
              }}
            >
              <ArrowRight />
            </div>
          );

          if (onlyArrows) {
            return (
              <div ref={elementRef} className="Scrollbar__track">
                <div
                  css={css`
                    position: absolute;
                    width: 10px;
                    height: 18px;
                    left: -30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  `}
                >
                  {arrowLeft}
                </div>
                <div
                  css={css`
                    position: absolute;
                    width: 10px;
                    height: 18px;
                    right: -30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                  `}
                >
                  {arrowRight}
                </div>
              </div>
            );
          }

          return (
            <div
              css={css`
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                display: ${trackVisible ? "block" : "none"};
              `}
              className="Scrollbar__track"
            >
              <Row
                align="center"
                css={css`
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    flex: 0 0 auto;
                  `}
                >
                  {arrowLeft}
                </div>
                <div
                  css={css`
                    flex: 1 0 30%;
                    min-width: 0;
                    overflow: hidden;
                  `}
                >
                  <div
                    {...restProps}
                    ref={elementRef}
                    style={{
                      ...style,
                      position: "relative",
                      height: "3px",
                      width: `100%`,
                      borderRadius: "0",
                      background: `${Colors.gainsboro}`
                    }}
                  ></div>
                </div>
                <div
                  css={css`
                    flex: 0 0 auto;
                  `}
                >
                  {arrowRight}
                </div>
              </Row>
            </div>
          );
        }
      }}
      trackYProps={{
        renderer: props => {
          const { style, elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                ...style,
                width: "3px",
                height: `100%`,
                top: "0px",
                borderRadius: "0",
                background: `${Colors.gainsboro}`
              }}
            ></div>
          );
        }
      }}
      thumbXProps={{
        renderer: props => {
          const { style, elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              style={{
                ...style,
                backgroundColor: `${Colors.mortar}`,
                width: "100%",
                left: "0px",
                bottom: "0px"
              }}
            />
          );
        }
      }}
    >
      {props.children}
    </RSC.Scrollbar>
  );

  if (props.arrowsInside) {
    return <div css={styleArrowsInside}>{scrollbar}</div>;
  }

  return scrollbar;
}
