import React, { useState } from "react";
import { useCommonData } from "../context/CommonDataContext";
import { HelpTextModal } from "../modals/HelpTextModal";
import InfoIcon from "../icons/InfoIcon";
import { PlainButton } from "./PlainButton";

export const HelpLink = React.memo(function (props: {
  identifier: string;
  hideLabel?: boolean;
}) {
  const [show, setShow] = useState(false);
  const { helpTexts } = useCommonData();
  const helpText = helpTexts.find(
    (text) => text.identifier === props.identifier
  );

  if (!helpText) return null;

  return (
    <React.Fragment>
      <PlainButton
        onClick={() => {
          setShow(true);
        }}
        icon={<InfoIcon />}
        iconPosition="left"
        hoverEffect
      >
        {!props.hideLabel && helpText.label}
      </PlainButton>

      <HelpTextModal
        key={`help-text-modal--${props.identifier}`}
        helpText={helpText}
        visible={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </React.Fragment>
  );
});
