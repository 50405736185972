import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { BaseModal } from "../modals/BaseModal";
import { PrintConfigurator } from "../components/PrintConfigurator";
import { Product } from "../types/Product";
import { css } from "styled-components";
import { ProductOption } from "../types/ProductOption";
import { ProductSize } from "../types/Product";
import { Row, Column, Spacing } from "../helpers/layout";
import { BigTitle, ProductSerie } from "../typography";
import { calculateOrderItem } from "../helpers/product";
import { useUserState } from "../context/UserContext";
import { Colors } from "../branding";
import { useCommonData } from "../context/CommonDataContext";
import { MobileModal } from "./MobileModal";
import { useResponsive } from "../context/ResponsiveContext";
import { ProductPreview } from "../components/ProductPreview";
import { TryInYouRoomButton } from "../components/TryInYouRoomButton";
import { Hr } from "../components/Hr";
import { EditorContent } from "../components/EditorContent";
import { ProductNote } from "../components/ProductNote";
import ShopProductAwards from "../components/ShopProductAwards";
import { PrintDimensions } from "../components/PrintDimensions";
import { ProductDetailFooter } from "../components/ProductDetailFooter";
import { useCart } from "../context/CartContext";
import { addToCart } from "../actions/addToCart";
import MobilePrintPreviewModal from "../components/MobilePrintPreviewModal";
import { uniqBy } from "lodash";
import { notAuthorised } from "request";
import ProductAdditionalImages from "components/ProductAdditionalImages";

export function QuickShopProductModal({
  product,
  visible,
  onClose,
}: {
  product?: Product;
  onClose: () => void;
  visible: boolean;
}) {
  const { isAuthenticated } = useUserState();
  const { settings } = useCommonData();
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const previewRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<ProductSize | null>(
    product?.sizes[0] || null,
  );
  const [options, setOptions] = useState<ProductOption[]>(
    product?.options || [],
  );
  const isSmall = isTablet || isMobile;
  const Layout = isSmall ? Column : Row;
  const [productDetail, setProductDetail] = useState<Product | null>(null);

  const getProductDetail = useCallback(async () => {
    if (!product?.slug || productDetail) {
      return;
    }
    try {
      const response = await notAuthorised.request<Product>({
        method: "GET",
        url: `/api/frontend/products/${product.slug}/`,
      });

      setProductDetail(response.data);
    } catch {
      //
    }
  }, [product?.slug, productDetail]);

  useEffect(() => {
    getProductDetail();
  }, [getProductDetail]);

  const inHomes = useMemo(() => {
    if (!productDetail) {
      return [];
    }
    const productInHomes = productDetail.in_homes.map((c) => c.in_home) || [];
    const relatedInHomes = productDetail.related_in_homes || [];
    const allInHomes = [...productInHomes, ...relatedInHomes];
    return uniqBy(allInHomes, (inHome) => inHome.id);
  }, [productDetail]);

  useEffect(() => {
    if (product && !size) {
      setSize(product.sizes[0]);
    }
  }, [product, size]);

  const handleClose = () => {
    onClose();
    setSize(null);
    setOptions([]);
    setProductDetail(null);
  };

  const { fetchCart } = useCart();

  const ModalComponent = isMobile ? MobileModal : BaseModal;

  const calculated = useMemo(() => {
    if (!product) {
      return {
        subtotal: "0",
        discount: "0",
        total: "0",
        gap: 0,
        width: 0,
        height: 0,
      };
    }

    return calculateOrderItem(
      {
        product,
        size: size || product.sizes[0],
        options,
      },
      settings,
    );
  }, [product, size, options, settings]);

  if (!product) {
    return null;
  }

  const imageAspectRatio = product.featured_image
    ? product.featured_image.width / product.featured_image.height
    : 1;

  return (
    <>
      <ModalComponent
        title="Preview Product"
        visible={visible}
        onClose={handleClose}
        styles={{
          content: {
            display: "flex",
            flexDirection: "column",
            maxWidth: "90%",
            margin: "0 auto",
          },
        }}
      >
        <div
          css={css`
            padding: ${Spacing.m};
            background: ${Colors.white};
            color: ${Colors.black};
          `}
        >
          {isMobile && size ? (
            <MobilePrintPreviewModal {...{ product, size, options }} />
          ) : null}
          <Layout gutter={imageAspectRatio > 1 ? Spacing.l : Spacing.xxl}>
            <div
              ref={previewRef}
              css={css`
                flex: 0 0 ${imageAspectRatio > 1 ? "50" : "30"}%;

                ${isDesktop &&
                css`
                  position: sticky;
                  top: ${Spacing.l};
                `}
              `}
            >
              <Column>
                <ProductPreview
                  product={product}
                  printSize={size}
                  options={options}
                  enableZoom={isDesktop}
                />
                {isSmall && !settings.gallery_focus && (
                  <div
                    css={css`
                      text-align: right;
                    `}
                  >
                    <TryInYouRoomButton
                      productId={product.id}
                      size={size}
                      options={options}
                    />
                  </div>
                )}
                <ProductAdditionalImages inHomes={inHomes} />
                {!isSmall && !settings.gallery_focus && (
                  <>
                    <Hr size="medium" color={Colors.black} />
                    <div>
                      <TryInYouRoomButton
                        productId={product.id}
                        size={size}
                        options={options}
                      />
                    </div>
                  </>
                )}
              </Column>
            </div>
            <div
              css={css`
                flex: 1 0 20%;
              `}
            >
              <Column gutter={Spacing.xxl}>
                <div
                  css={css`
                    text-align: center;
                  `}
                >
                  <BigTitle>{product.name}</BigTitle>
                  {product.serie && (
                    <ProductSerie>{product.serie.name}</ProductSerie>
                  )}
                  {product.product_caption && (
                    <div
                      css={css`
                        margin-top: ${Spacing.xl};
                      `}
                    >
                      <EditorContent text={product.product_caption} />
                    </div>
                  )}
                  {product.note && (
                    <div
                      css={css`
                        margin-top: ${Spacing.xl};
                      `}
                    >
                      <ProductNote note={product.note} />
                    </div>
                  )}
                  <ShopProductAwards awards={product.awards} />
                </div>
                <PrintConfigurator
                  product={product}
                  selected={{
                    size: size || product.sizes[0],
                    options,
                  }}
                  onSelectOptions={(options) => setOptions(options)}
                  onSelectSize={(size) => setSize(size)}
                  disablePrices={!isAuthenticated}
                  disableSizeSelection={false}
                />
                <PrintDimensions
                  calculated={calculated}
                  selectedOptions={options}
                />
                <ProductDetailFooter
                  product={product}
                  total={calculated.total}
                  onAddToCart={async () => {
                    await addToCart({
                      product: product.id,
                      productType: product.type,
                      size,
                      options,
                      book_option: null,
                    });

                    await fetchCart();
                  }}
                />
              </Column>
            </div>
          </Layout>
        </div>
      </ModalComponent>
    </>
  );
}
