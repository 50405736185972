import { useResponsive } from "../context/ResponsiveContext";
import { Spacing } from "./layout";

export function useWrapper(type?: "header") {
  const { isMobile, isTablet, is13inch } = useResponsive();

  if (type === "header") {
    if (isMobile) {
      return Spacing.l;
    } else {
      return Spacing.xl;
    }
  }

  if (isMobile) {
    return Spacing.l;
  }

  if (isTablet) {
    return Spacing.xl;
  }

  if (is13inch) {
    return Spacing.xxl;
  }

  return Spacing.xxxl;
}
