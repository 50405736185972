import React from "react";
import styled, { css } from "styled-components";
import { Row } from "./helpers/layout";
import { MOBILE } from "./branding";

export const fontFamilies = {
  gotham: "Gotham",
  freight: "freight-big-pro",
};

export const SubTitle = styled.h3`
  font-size: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
`;

export const BigTitle = styled.h1`
  font-family: ${fontFamilies.freight};
  font-weight: 500;
  font-size: 42px;
  line-height: 47px;
  text-transform: uppercase;
`;

export const Title = styled.h2`
  font-family: ${fontFamilies.freight};
  font-size: 35px;
  font-weight: 600;
  line-height: 1.34;

  @media screen and (max-width: ${MOBILE + 1}px) {
    font-size: 25px;
  }
`;

export const ModalTitle = styled.h2`
  font-family: ${fontFamilies.freight};
  font-size: 26px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-align: center;
`;

export const TotalLabel = styled.h2`
  font-family: ${fontFamilies.gotham};
  font-weight: 400;
  font-size: 20px;
`;

export const TotalPrice = styled.h2`
  font-family: ${fontFamilies.freight};
  font-size: 23px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.5px;
`;

export function Section(props: { number: number; children: React.ReactNode }) {
  return (
    <Row align="flex-start">
      <div
        css={css`
          position: relative;
          font-family: ${fontFamilies.freight};
          font-size: 23px;
          font-weight: 600;
          line-height: 20px;
          top: -4px;
          letter-spacing: 0.5px;
        `}
      >
        0{props.number}.
      </div>
      <div
        css={css`
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.06em;
        `}
      >
        {props.children}
      </div>
    </Row>
  );
}

export const ItalicTitle = styled.h2`
  font-family: ${fontFamilies.freight};
  font-size: 24px;
  font-style: italic;
  letter-spacing: 0.1em;
  font-weight: 400;
`;

export const ItalicSubTitle = styled.h2`
  font-family: ${fontFamilies.gotham};
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.1em;
  font-weight: 400;
  text-transform: none;
`;

export const BlockTitle = styled.h4`
  font-family: ${fontFamilies.freight};
  font-weight: 600;
  font-size: 16px;
`;

export const BoldSubTitle = styled.h3`
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  font-family: ${fontFamilies.gotham};
`;

export const MediumSubTitle = styled.h3`
  font-family: ${fontFamilies.gotham};
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
`;

export const ProductTitle = styled.h3`
  font-family: ${fontFamilies.freight};
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

export const ProductSerie = styled.h3`
  font-family: ${fontFamilies.gotham};
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.15em;
`;

export const ProductOptionTitle = styled(ProductSerie)`
  font-weight: 500;
`;

export const H5 = styled.h5`
  font-family: ${fontFamilies.gotham};
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.4;
`;

export const Label = styled.h4<{
  bold?: boolean;
}>`
  line-height: 20px;
  text-transform: uppercase;
  font-weight: ${(props) => (props.bold ? "600" : "400")};
`;
