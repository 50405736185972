import { useEffect } from "react";
import throttle from "lodash/throttle";

export function useReachedEnd(
  callback: () => void,
  dependencies: any[],
  scrollContainer?: HTMLDivElement | null
) {
  useEffect(() => {
    const container = scrollContainer || window;

    const handleScroll = throttle(() => {
      const height = scrollContainer
        ? scrollContainer.scrollHeight
        : document.body.scrollHeight;

      const scrollTop = scrollContainer
        ? scrollContainer.scrollTop
        : Math.max(document.body.scrollTop, document.documentElement.scrollTop);

      const clientHeight = window.innerHeight;
      const reachedEnd = scrollTop > height - clientHeight * 2;

      if (reachedEnd) {
        callback();
      }
    }, 200);

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [scrollContainer, ...dependencies]);
}
