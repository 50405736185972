const BestsellingIcon = () => (
  <svg width="18px" height="17px" viewBox="0 0 18 17">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#000000" fillRule="nonzero">
        <path d="M17.3271973,6.52529298 C17.292334,6.41660157 17.1988769,6.33706056 17.0859375,6.32021484 L11.6878418,5.51132811 L9.2879883,0.60234375 C9.23774415,0.49980468 9.13374024,0.43461915 9.01948245,0.43417968 C9.01918947,0.43417968 9.01875003,0.43417968 9.01845705,0.43417968 C8.9046387,0.43417968 8.8004883,0.49863282 8.74980471,0.60058593 L6.31918947,5.48833008 L0.91655274,6.25034181 C0.80346681,6.26630862 0.70927734,6.34511721 0.67353516,6.45351564 C0.63779298,6.56191407 0.6666504,6.68129883 0.74809572,6.76127931 L4.64414064,10.5912598 L3.70458984,15.9713379 C3.68481444,16.0836914 3.73081053,16.1976563 3.8229492,16.2650391 C3.91538085,16.3328613 4.03740234,16.3416504 4.13862303,16.288916 L8.97700194,13.7680664 L13.7992676,16.3306641 C13.8435059,16.3542481 13.8918457,16.3658203 13.9400391,16.3658203 C14.0018555,16.3658203 14.0633789,16.3467773 14.1153809,16.3092773 C14.2081055,16.2424805 14.254834,16.1288086 14.2359375,16.0161621 L13.3303711,10.6281738 L17.250293,6.83232423 C17.3321777,6.75292968 17.3620606,6.63398436 17.3271973,6.52529298 Z M12.7996582,10.3069336 C12.7287598,10.3756348 12.6962402,10.4748047 12.7125,10.5720703 L13.5433594,15.5153321 L9.11909178,13.1642578 C9.07514646,13.1408203 9.02666013,13.1291016 8.97832029,13.1291016 C8.93071287,13.1291016 8.88310545,13.1403809 8.83974606,13.1630859 L4.40009763,15.4762207 L5.26215819,10.5392578 C5.27915037,10.4421386 5.24736327,10.3428222 5.17705077,10.2738281 L1.60224609,6.75952149 L6.55942383,6.06035157 C6.6572754,6.04658205 6.74208984,5.98535157 6.78618165,5.896875 L9.0161133,1.41254883 L11.2180664,5.91650391 C11.2614258,6.00512697 11.3455079,6.06679689 11.4432129,6.08129883 L16.396875,6.82353516 L12.7996582,10.3069336 Z"></path>
      </g>
    </g>
  </svg>
);
export default BestsellingIcon;
