import React from "react";
import { useState } from "react";
import { css } from "styled-components";
import styled from "styled-components";
import { useUserState } from "../context/UserContext";
import { HeaderDesktopDropdown } from "./HeaderDesktopDropdown";
import { Colors } from "../branding";
import { Spacing } from "../helpers/layout";
import { Link } from "../components/Link";
import { LoggedInForm, NotLoggedInForm } from "./AccountForm";
import { useCart } from "../context/CartContext";
import { PlainButton } from "./PlainButton";
import { useCommonData } from "../context/CommonDataContext";
import BagIcon from "../icons/BagIcon";

const Li = styled.li`
  display: flex;
  padding: 0 ${Spacing.m};
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  line-height: 17px;
  a {
    margin: auto 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const MenuIconWithText = styled.div`
  display: inline-block;
  margin-left: ${Spacing.s};
  position: relative;
  font-size: 9px;
  height: 21px;
  text-align: center;
  vertical-align: middle;
`;

export const MenuCountCircle = styled.div`
  display: inline-block;
  margin-left: ${Spacing.s};
  position: relative;
  font-size: 9px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  color: ${Colors.white};
  background: ${Colors.black};
  vertical-align: middle;

  span {
    line-height: 19px;
  }
`;

export const StyledMenuIcon = styled.span`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  font: 8px Tahoma;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0;
`;

export function AccountNav() {
  const [visible, setVisible] = useState(false);
  const { isAuthenticated } = useUserState();
  const { cart } = useCart();
  const { settings } = useCommonData();

  if (settings.gallery_focus) {
    return (
      <>
        <ul
          css={css`
            list-style: none;
            display: flex;
            align-items: center;
            padding: 0;
          `}
        >
          {isAuthenticated && cart.count > 0 ? (
            <Li>
              <Link href="/cart" noEffect>
                <MenuIconWithText>
                  <BagIcon />
                  <StyledMenuIcon>{cart.count}</StyledMenuIcon>
                </MenuIconWithText>
              </Link>
            </Li>
          ) : (
            <Li>
              <Link href="/contact" noEffect>
                Contact
              </Link>
            </Li>
          )}
          <Li>
            <PlainButton
              onClick={() => {
                setVisible((visible) => !visible);
              }}
              css={css`
                text-transform: uppercase;
                letter-spacing: 0.15em;
                font-size: 12px;
              `}
            >
              {isAuthenticated ? "Account" : "Sign in"}
            </PlainButton>
          </Li>
        </ul>
        {visible && (
          <HeaderDesktopDropdown
            onClose={() => {
              setVisible(false);
            }}
          >
            {isAuthenticated ? <LoggedInForm /> : <NotLoggedInForm />}
          </HeaderDesktopDropdown>
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <ul
        css={css`
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0;
        `}
      >
        {isAuthenticated && (
          <Li>
            <Link href="/cart" noEffect>
              <MenuIconWithText>
                <BagIcon />
                <StyledMenuIcon style={{ lineHeight: "27px" }}>
                  {cart.count}
                </StyledMenuIcon>
              </MenuIconWithText>
            </Link>
          </Li>
        )}
        <Li>
          <PlainButton
            onClick={() => {
              setVisible((visible) => !visible);
            }}
            css={css`
              text-transform: uppercase;
              letter-spacing: 0.15em;
              font-size: 12px;
            `}
          >
            {isAuthenticated ? "Account" : "Sign in"}
          </PlainButton>
        </Li>
      </ul>
      {visible && (
        <HeaderDesktopDropdown
          onClose={() => {
            setVisible(false);
          }}
        >
          {isAuthenticated ? <LoggedInForm /> : <NotLoggedInForm />}
        </HeaderDesktopDropdown>
      )}
    </React.Fragment>
  );
}
