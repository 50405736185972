import InfoIcon from "../icons/InfoIcon";
import { Row } from "../helpers/layout";
import { css } from "styled-components";
import { Colors } from "../branding";

export const Message = (props: { text: string; success?: boolean }) => {
  return (
    <div
      css={css`
        svg {
          path {
            fill: ${props.success
              ? Colors.islamic_green
              : Colors.error} !important;
          }
        }
      `}
    >
      <Row align="center">
        <InfoIcon />
        <div
          css={css`
            color: ${props.success ? Colors.islamic_green : Colors.error};
            font-size: 10px;
            text-transform: capitalize;
            line-height: 1em;
            a {
              color: inherit;
            }
          `}
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </Row>
    </div>
  );
};
