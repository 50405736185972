export enum Colors {
  error = "red",
  white = "#fff",
  black = "#000",
  light_grey = "#c7c7c7",
  very_light_grey = "#F1F1F1",
  mortar = "#5c5c5c",
  dark_gray = "#9d9d9d",
  night_rider = "#333",
  nero = "#272727",
  suva_grey = "#959595",
  shady_lady = "#979797",
  gainsboro = "#dfdfdf",
  selective_yellow = "#F9B100",
  islamic_green = "#00A01D",
  nero_dark = "#1b1b1b",
  grey_chateau = "#a2a3a7",
}

export const SMALL_MOBILE = 375;
export const MOBILE = 670;
export const SMALL_TABLET = 768;
export const TABLET = 1030;
export const INCH_13 = 1280;
