import { authorised } from "../request";
import { WishlistItem } from "../types/items/WishlistItem";

export async function deleteWishlistItem(uuid: string, id: number) {
  const API = authorised();

  return await API.request<WishlistItem>({
    method: "DELETE",
    url: `/api/frontend/wishlist/${uuid}/${id}/`
  });
}
