import React, { useState, useEffect } from "react";
import CloseIcon from "../icons/CloseIcon";
import Modal from "react-modal";
import { css } from "styled-components";
import { Colors } from "../branding";
import { PlainButton } from "../components/PlainButton";
import { useRouter } from "next/router";
import { Spacing } from "../helpers/layout";
import { MODAL_LEVEL } from "../helpers/zIndex";
import { useResponsive } from "../context/ResponsiveContext";
import { useBlockScroll } from "../helpers/useBlockScroll";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.85)",
    zIndex: MODAL_LEVEL,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "1082px",
    maxHeight: "calc(100% - 60px)",
    transform: "translate(-50%, -50%)",
    background: "none",
    color: "white",
    display: "flex",
    flexDirection: "column",
    border: "none",
    padding: 0,
  },
};

const smallStyles = {
  ...customStyles,
  content: {
    ...customStyles.content,
    width: "100%",
    maxWidth: "700px",
  },
};

export function BaseModal(props: {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
  small?: boolean;
  styles?: Modal.Styles;
}) {
  const [mounted, setMounted] = useState(false);
  const router = useRouter();
  const { isMobile } = useResponsive();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (router.asPath && mounted) {
      props.onClose();
    }
  }, [router.asPath]);

  useBlockScroll(props.visible);
  const styles = props.small
    ? {
        ...smallStyles,
        ...props.styles,
      }
    : {
        ...customStyles,
        ...props.styles,
      };

  return (
    <Modal
      isOpen={props.visible}
      onRequestClose={props.onClose}
      style={styles}
      ariaHideApp={false}
      closeTimeoutMS={200}
    >
      <PlainButton
        onClick={props.onClose}
        css={css`
          align-self: flex-end;
          padding-bottom: ${Spacing.l};
          margin-right: ${isMobile && Spacing.m};
          svg {
            fill: ${Colors.white};
          }
        `}
        icon={<CloseIcon />}
      />

      {/* 43px is close button + padding */}
      <div
        css={css`
          max-height: calc(100% - 43px);
          overflow: scroll;
        `}
      >
        {props.children}
      </div>
    </Modal>
  );
}
