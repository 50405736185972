import React from "react";
import { PlainButton } from "./PlainButton";
import { css } from "styled-components";

export function NoSearchResults(props: { onClear?: () => void }) {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      {props.onClear ? (
        <p>
          No results for the filters selected. <br /> Please{" "}
          <PlainButton
            underlined
            hoverEffect
            onClick={() => {
              props.onClear!();
            }}
            css={css`
              letter-spacing: 0.06em;
            `}
          >
            Clear Filters
          </PlainButton>{" "}
          and begin again.
        </p>
      ) : (
        <p>No results found</p>
      )}
    </div>
  );
}
