import { InHome } from "../../types/InHome";
import { useWrapper } from "../../helpers/useWrapper";
import Slider from "react-slick";
import { useRef } from "react";
import { css } from "styled-components";
import { Spacing } from "../../helpers/layout";
import { PlainButton } from "../PlainButton";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";
import InHomeMobileItemImage from "./InHomeMobileItemImage";

const navButtonCss = css`
  display: flex;
  width: 34px;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  padding: ${Spacing.s};
  z-index: 2;
  span {
    display: flex;
  }
`;

const slideshowSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function InHomeSlideshow({
  slides,
  initialSlide,
}: {
  slides: InHome[];
  initialSlide: number;
}) {
  const spacing = useWrapper();
  const ref = useRef<Slider>(null);

  const nav = (dir: "prev" | "next") => {
    if (!ref.current) return;

    if (dir === "prev") {
      ref.current.slickPrev();
    } else {
      ref.current.slickNext();
    }
  };

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Slider {...slideshowSettings} initialSlide={initialSlide} ref={ref}>
        {slides.map((inHome) => (
          <div
            key={inHome.id}
            css={css`
              height: 100svh;
              display: flex !important;
              align-items: center;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <InHomeMobileItemImage image={inHome?.image} />
          </div>
        ))}
      </Slider>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          padding-inline: ${spacing};
          box-sizing: border-box;

          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          left: 0;
          z-index: 3;

          pointer-events: none;
          & > * {
            pointer-events: auto;
          }
        `}
      >
        <PlainButton
          css={navButtonCss}
          icon={<ArrowLeft />}
          onClick={() => nav("prev")}
        />
        <PlainButton
          css={navButtonCss}
          icon={<ArrowRight />}
          onClick={() => nav("next")}
        />
      </div>
    </div>
  );
}
