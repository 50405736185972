import { notAuthorised } from "../request";

export async function countInHomeView(id: number) {
  const API = notAuthorised;

  return await API.request({
    method: "POST",
    url: `/api/frontend/in-homes/${id}/view/`
  });
}
