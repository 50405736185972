import React, { useEffect, useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import Modal from "react-modal";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Column, Row, Spacing } from "../helpers/layout";
import { Title } from "../typography";
import { PlainButton } from "../components/PlainButton";
import { useRouter } from "next/router";
import { useWrapper } from "../helpers/useWrapper";
import { useBlockScroll } from "../helpers/useBlockScroll";
import { MODAL_LEVEL } from "../helpers/zIndex";
import { useResponsive } from "../context/ResponsiveContext";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: MODAL_LEVEL,
  },
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    background: "none",
    display: "flex",
    flexDirection: "column",
    border: "none",
  },
};

export function MobileModal(props: {
  title: string;
  visible: boolean;
  onClose: () => void;
  bottom?: React.ReactNode | React.ReactNode[];
  actions?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  bodyRef?: React.RefObject<HTMLDivElement>;
}) {
  const [mounted, setMounted] = useState(false);
  const router = useRouter();
  const spacing = useWrapper("header");
  const { isMobile } = useResponsive();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (router.asPath && mounted) {
      props.onClose();
    }
  }, [router.asPath]);

  useBlockScroll(props.visible);

  return (
    <Modal
      isOpen={props.visible}
      onRequestClose={props.onClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        css={css`
          padding: ${spacing};
          height: 100%;
          box-sizing: border-box;
        `}
      >
        <Column
          gutter={Spacing.xl}
          css={css`
            height: 100%;
          `}
        >
          <div
            css={css`
              flex: 0 0 auto;
              svg {
                fill: ${Colors.black};
              }
            `}
          >
            <Row align="center">
              <div
                css={css`
                  flex: 1 0 20%;
                `}
              >
                <Row align="center" gutter={Spacing.xxl}>
                  <div>
                    <Title>{props.title}</Title>
                  </div>
                  {!isMobile && props.actions && (
                    <div
                      css={css`
                        position: relative;
                        top: 4px;
                        flex: 0 0 auto;
                      `}
                    >
                      {props.actions}
                    </div>
                  )}
                </Row>
              </div>
              <PlainButton
                onClick={props.onClose}
                css={css`
                  flex: 0 0 auto;
                `}
                icon={<CloseIcon />}
              />
            </Row>
            {isMobile && props.actions && (
              <div
                css={css`
                  text-align: left;
                  margin-top: ${Spacing.l};
                `}
              >
                {props.actions}
              </div>
            )}
          </div>
          <div
            css={css`
              flex: 1 0 20%;
              min-height: 0;
              overflow: scroll;
            `}
            ref={props.bodyRef}
          >
            {props.children}
          </div>
          {props.bottom && (
            <div
              css={css`
                flex: 0 0 auto;
              `}
            >
              {props.bottom}
            </div>
          )}
        </Column>
      </div>
    </Modal>
  );
}
