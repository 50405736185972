import React, { useEffect } from "react";
import { css } from "styled-components";
import { getAffirmPrice } from "../helpers/product";

declare global {
  interface Window {
    affirm: any;
  }
}
export function AffirmPromo(props: { price: string }) {
  useEffect(() => {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }, []);

  useEffect(() => {
    if (typeof window.affirm !== "undefined") {
      window.affirm.ui.ready(function () {
        /* Alternative implementation
          Docs here: https://docs.affirm.com/payments/docs/create-a-promo
          !!!! IMPORTANT DOES NOT WORK WITH AMOUNTS BELOW $50 !!!!
          const opts = { pageType: 'product', amount: getAffirmPrice(props.price) }
          const affirmComponent = window.affirm.ui.components.create('promo', opts)
          affirmComponent.render('.affirm-as-low-as');
          affirmComponent.update(opts);
        */
        window.affirm.ui.refresh();
      });
    }
  }, [props.price]);

  return (
    <div
      css={css`
        p {
          margin: 0;
          font-size: 12px;
        }

        a {
          color: inherit;
        }
      `}
    >
      <p
        className="affirm-as-low-as"
        data-page-type="product"
        data-amount={getAffirmPrice(props.price)}
      />
    </div>
  );
}
