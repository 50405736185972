import React, { CSSProperties } from "react";
import { ProductTitle, ProductSerie } from "../typography";
import { Spacing, Column, Row } from "../helpers/layout";
import { BookOption, Product } from "../types/Product";
import { Link } from "./Link";
import { css } from "styled-components";
import { ProductPreview } from "./ProductPreview";
import { Width } from "../helpers/getImageUrl";
import { Button } from "./Button";
import { useResponsive } from "../context/ResponsiveContext";

export type ProductThumbProps = Pick<
  Product,
  | "id"
  | "featured_image"
  | "pricing"
  | "name"
  | "slug"
  | "pairing_type"
  | "pairing_products"
  | "type"
  | "sizes"
  | "alt"
  | "bestselling"
  | "in_museum"
  | "awards_count"
  | "subtitle"
  | "book_options"
> & {
  serie: {
    name: string;
    slug: string;
  } | null;
  book_option?: BookOption | null;
};

export const ProductThumb = React.memo(function (props: {
  product: ProductThumbProps;
  imageStyle?: CSSProperties;
  enableOverlay?: boolean;
  align?: "center";
  onClick?: () => void;
  onQuickShop?: () => void;
  previewWidth?: Width;
}) {
  const { product, enableOverlay, onQuickShop, previewWidth } = props;
  const url = `/shop/${product.slug}`;
  const { isMobile } = useResponsive();
  const isBook = product.type === "BOOK";

  return (
    <article>
      <Column gutter={Spacing.m}>
        <div
          css={css`
            opacity: 1;
            transition: opacity 300ms;

            ${!enableOverlay &&
            css`
              &:hover {
                opacity: 0.7;
              }
            `}
          `}
        >
          {enableOverlay ? (
            <ProductPreview
              onQuickShop={onQuickShop}
              product={product}
              enableOverlay={enableOverlay}
              previewWidth={previewWidth}
            />
          ) : props.onClick ? (
            <ProductPreview
              product={product}
              onClick={props.onClick}
              previewWidth={previewWidth}
            />
          ) : (
            <Link
              css={css`
                display: block;
              `}
              href={url}
            >
              <ProductPreview product={product} previewWidth={previewWidth} />
            </Link>
          )}
        </div>
        <Row>
          <Column gutter={Spacing.xs}>
            {props.onClick ? (
              <ProductTitle onClick={props.onClick}>
                {product.name}
              </ProductTitle>
            ) : (
              <ProductTitle>
                <Link href={url}>{product.name}</Link>
              </ProductTitle>
            )}
            {product.subtitle && (
              <ProductSerie
                css={
                  props.align !== "center" &&
                  css`
                    max-width: 70%;
                  `
                }
              >
                <Link href={url}>{product.subtitle}</Link>
              </ProductSerie>
            )}
            {product.serie && (
              <ProductSerie
                css={
                  props.align !== "center" &&
                  css`
                    max-width: 70%;
                  `
                }
              >
                <Link href={`/series/${product.serie.slug}`} noEffect>
                  {product.serie.name}
                </Link>
              </ProductSerie>
            )}
          </Column>
          {!!onQuickShop && isMobile && !isBook && (
            <div
              css={`
                flex-shrink: 0;
              `}
            >
              <Button
                label="Info"
                style="black-border"
                type="button"
                onClick={() => {
                  onQuickShop();
                }}
              />
            </div>
          )}
        </Row>
      </Column>
    </article>
  );
});
