const CloseIcon = (props: { width?: string }) => (
  <svg width={props.width || "15px"} viewBox="0 0 69 69" version="1.1">
    <g
      stroke="none"
      strokeWidth="4"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(0.000000, 1.000000)" stroke="currentColor">
        <g transform="translate(0.222222, 0.000000)">
          <path d="M67.9370486,0 L0,67.826087" />
          <path d="M0,0 L67.9370486,67.826087" />
        </g>
      </g>
    </g>
  </svg>
);
export default CloseIcon;
