import React, { useEffect, useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import Modal from "react-modal";
import { css } from "styled-components";
import { Colors } from "../branding";
import { Column, Row, Spacing } from "../helpers/layout";
import { Title } from "../typography";
import { PlainButton } from "../components/PlainButton";
import { useRouter } from "next/router";
import { useBlockScroll } from "../helpers/useBlockScroll";
import { MODAL_LEVEL } from "../helpers/zIndex";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: MODAL_LEVEL,
  },
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    background: "none",
    display: "flex",
    flexDirection: "column",
    border: "none",
  },
};

export function FullscreenModal(props: {
  title?: string;
  noPadding?: boolean;
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}) {
  const [mounted, setMounted] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (router.asPath && mounted) {
      props.onClose();
    }
  }, [router.asPath]);

  useBlockScroll(props.visible);

  return (
    <Modal
      isOpen={props.visible}
      onRequestClose={props.onClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        css={css`
          padding: ${props.noPadding ? Spacing.none : Spacing.xxl};
          height: 100%;
          box-sizing: border-box;
        `}
      >
        {props.title ? (
          <Column
            gutter={Spacing.xl}
            css={css`
              height: 100%;
            `}
          >
            <div
              css={css`
                flex: 0 0 auto;
                svg {
                  fill: ${Colors.black};
                }
              `}
            >
              <Row align="center">
                <div
                  css={css`
                    flex: 1 0 20%;
                  `}
                >
                  <Title>{props.title}</Title>
                </div>
                <PlainButton
                  onClick={props.onClose}
                  css={css`
                    flex: 0 0 auto;
                  `}
                  icon={<CloseIcon />}
                />
              </Row>
            </div>
            <div
              css={css`
                flex: 1 0 20%;
                min-height: 0;
              `}
            >
              {props.children}
            </div>
          </Column>
        ) : (
          <div
            css={css`
              height: 100%;
            `}
          >
            {props.children}
          </div>
        )}
      </div>
    </Modal>
  );
}
