import TryIcon from "../icons/TryIcon";
import { PlainButton } from "./PlainButton";
import { useInteriorDesigner } from "../context/InteriorDesignerContext";
import { useState } from "react";
import { addToInteriorDesigner } from "../actions/addToInteriorDesigner";
import Spinner from "react-spinners/ClipLoader";
import { ProductOption } from "../types/ProductOption";
import { ProductSize } from "../types/Product";
import { useRecentlyViewed } from "../context/RecentlyViewedContext";
import { addToRecentlyViewed } from "../actions/addToRecentlyViewed";

export function TryInYouRoomButton(props: {
  productId: number;
  options: ProductOption[];
  size?: ProductSize | null;
  disabled?: boolean;
  onClick?: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const { recentlyViewed, fetchRecentlyViewed } = useRecentlyViewed();
  const {
    fetchInteriorDesigner,
    isInInteriorDesigner,
    interiorDesigner,
    openInteriorDesigner,
  } = useInteriorDesigner();
  const inInteriorDesigner = isInInteriorDesigner(props.productId);

  return (
    <PlainButton
      hoverEffect
      icon={loading ? <Spinner size={16} /> : <TryIcon />}
      iconPosition="left"
      disabled={props.disabled}
      onClick={async () => {
        if (!inInteriorDesigner) {
          setLoading(true);

          await addToInteriorDesigner(interiorDesigner.uuid, {
            product: props.productId,
            options: [],
          });

          await fetchInteriorDesigner();

          // Add item to recently viewed
          await addToRecentlyViewed(recentlyViewed.uuid, {
            product: props.productId,
          });

          await fetchRecentlyViewed();

          setLoading(false);
        }

        openInteriorDesigner();

        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      Try in your room
    </PlainButton>
  );
}
