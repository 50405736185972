import sortBy from "lodash/sortBy";
import iconBig from "../static/images/icon-layout-big.png";
import iconMasonry from "../static/images/icon-layout-masonry.png";
import iconMasonryWide from "../static/images/icon-layout-masonry-wide.png";
import { SerieLayoutStyle } from "../types/Serie";

export type Layout = {
  label: string;
  name: SerieLayoutStyle;
  icon: string;
};

export const layouts: Layout[] = [
  {
    label: "Wide",
    name: "wide",
    icon: iconMasonryWide,
  },
  {
    label: "Masonry",
    name: "masonry",
    icon: iconMasonry,
  },
  {
    label: "Big",
    name: "big",
    icon: iconBig,
  },
];

export function getOrderedFilters<T>(
  items: Array<
    T & {
      name: string;
    }
  >
) {
  const order = [
    "Equestrian",
    "Animals",
    "Portrait",
    "Land",
    "Sea",
    "Beach",
    "Abstract",
    "Aerial",
    "Object",
  ];

  return sortBy(items, (item) => {
    const index = order.indexOf(item.name);

    if (index !== -1) {
      return index;
    }

    return items.length;
  });
}
