import EventsTracker from "../helpers/fb";
import { authorised } from "../request";
import { CartItem } from "../types/items/CartItem";
import { ProductType, ProductSize, BookOption } from "../types/Product";
import { ProductOption } from "../types/ProductOption";

export async function addToCart(data: {
  product: number;
  size?: ProductSize | null;
  options: ProductOption[];
  productType?: ProductType;
  book_option: BookOption | null;
}) {
  const API = authorised();

  EventsTracker.trackEvent("AddToCart", {
    content_type: data.productType,
    content_ids: [data.product],
  });

  const res = await API.request<CartItem>({
    method: "POST",
    url: "/api/frontend/cart/",
    data,
  });

  if (res.status === 200 || res.status === 201) {
    window.location.href = "/cart";
  }

  return res;
}
