const MinusIcon = () => (
  <svg width="13px" height="10px" viewBox="0 0 13 2">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-329.000000, -1625.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(329.000000, 1625.000000)">
          <polygon points="12.48 1.99323077 12.48 0.220923077 0.443076923 0.220923077 0.443076923 1.99323077"></polygon>
        </g>
      </g>
    </g>
  </svg>
);
export default MinusIcon;
