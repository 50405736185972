import React, { useState, useEffect, useMemo } from "react";
import { Title, fontFamilies } from "../typography";
import { Spacing, Column } from "../helpers/layout";
import { SearchForm } from "../forms/SearchForm";
import { ProductTag } from "../types/ProductTag";
import { MasonryGrid } from "../components/MasonryGrid";
import { ProductThumb } from "../components/ProductThumb";
import { css } from "styled-components";
import { getProductTags } from "../helpers/getProductTags";
import { useResponsive } from "../context/ResponsiveContext";
import { InfiniteProducts } from "./InfiniteProducts";
import { Product } from "../types/Product";
import CheckIcon from "../icons/CheckIcon";
import { QuickShopProductModal } from "../modals/QuickProductModal";

export function Search(props: {
  background?: "white" | "gray";
  minimal?: boolean;
  arrowsInside?: boolean;
  urlFilters?: boolean;
  scrollContainer?: HTMLDivElement | null;
  onSelect?: (product: Product) => void;
  selected?: Product | null;
  isSticky?: boolean;
  skipPairings?: boolean;
}) {
  const [query, setQuery] = useState<string>("");
  const [tags, setTags] = useState<ProductTag[]>([]);
  const { isMobile, isTablet, is13inch } = useResponsive();
  const [previewProduct, setPreviewProduct] = useState<Product | undefined>();

  const columns = useMemo(() => {
    if (isMobile) {
      return 1;
    }
    if (isTablet || is13inch || props.minimal) {
      return 2;
    }

    return 3;
  }, [isMobile, isTablet, is13inch, props.minimal]);

  useEffect(() => {
    async function getTags() {
      let tags = await getProductTags();

      if (props.skipPairings) {
        tags = tags.filter((tag) => tag.category !== "PAIRING");
      }

      setTags(tags);
    }

    getTags();
  }, [props.skipPairings]);

  return (
    <div>
      <Column>
        <Title>I’m looking for...</Title>
        <SearchForm
          onSubmit={(values) => {
            setQuery(values.query);
          }}
          urlFilters={props.urlFilters}
        />
        <h4
          css={css`
            max-width: 360px;
            margin: ${Spacing.l} 0;
            font: 20px ${fontFamilies.freight};
            font-weight: 600;

            ${isMobile &&
            css`
              font-size: 16px;
            `}
          `}
        >
          or use one or more of the filters below to help you find the perfect
          piece
        </h4>

        <InfiniteProducts
          tags={tags}
          hideTitle
          background={props.background || "white"}
          arrowsInside={props.arrowsInside}
          query={query}
          scrollContainer={props.scrollContainer}
          urlFilters={props.urlFilters}
          isSticky={props.isSticky}
          skipPairings={props.skipPairings}
        >
          {(results) => {
            return (
              <MasonryGrid
                columns={columns}
                margin={isMobile ? 5 : 2}
                items={results}
              >
                {(product, index) => {
                  const isActive =
                    props.selected && props.selected.id === product.id;

                  return (
                    <div
                      className="grid-item"
                      style={{
                        marginBottom: isMobile ? "5%" : "2%",
                      }}
                      key={`masonry-item--${index}`}
                    >
                      <ProductThumb
                        key={product.id}
                        product={product}
                        enableOverlay={props.onSelect ? false : true}
                        onQuickShop={() => setPreviewProduct(product)}
                        onClick={() => {
                          if (props.onSelect) {
                            props.onSelect(product);
                          }
                        }}
                      />
                      {isActive && (
                        <span
                          css={css`
                            position: absolute;
                            right: -6px;
                            top: -6px;
                          `}
                        >
                          <CheckIcon />
                        </span>
                      )}
                    </div>
                  );
                }}
              </MasonryGrid>
            );
          }}
        </InfiniteProducts>
      </Column>
      <QuickShopProductModal
        visible={!!previewProduct}
        onClose={() => setPreviewProduct(undefined)}
        product={previewProduct}
      />
    </div>
  );
}
