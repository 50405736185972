import CloseIcon from "../icons/CloseIcon";
import { PlainButton } from "./PlainButton";
import { useResponsive } from "../context/ResponsiveContext";
import { fontFamilies } from "../typography";
import { css } from "styled-components";

export function ClearFiltersButton(props: {
  onClick: () => void;
  disabled?: boolean;
}) {
  const { isTablet, isMobile } = useResponsive();

  return (
    <PlainButton
      onClick={props.onClick}
      icon={<CloseIcon width={"10px"} />}
      iconPosition="left"
      disabled={props.disabled}
      css={css`
        font-family: ${fontFamilies.freight};
        letter-spacing: 0.02em;
        font-size: 16px;
      `}
    >
      {isTablet || isMobile ? "Clear" : "Clear filters"}
    </PlainButton>
  );
}
