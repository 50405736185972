import React, { useState, useRef, useEffect } from "react";
import { Wrapper } from "./Wrapper";
import { css } from "styled-components";
import { Row, Spacing, Column } from "../helpers/layout";
import { MobileDropdown } from "./MobileDropdown";
import FilterIcon from "../icons/FilterIcon";
import { Button } from "./Button";
import { FilterSection } from "./FilterSection";
import groupBy from "lodash/groupBy";
import { Colors } from "../branding";
import { SEARCH_FILTERS_LEVEL } from "../helpers/zIndex";
import { getOrderedFilters } from "../helpers/filters";
import { Media } from "../types/Media";
import CloseIcon from "../icons/CloseIcon";

type Tag = {
  category: string;
  id: number;
  name: string;
  image: Media | null;
};

const MobileFilterClearButton = ({ onClick }: { onClick: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [rightOffset, setRightOffset] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const moveBy = Math.min(0, (window.innerWidth - rect.right - 24) * -1);
      setRightOffset(moveBy);
    }
  }, []);

  return (
    <div
      ref={ref}
      css={`
        display: flex;
        align-items: center;
        gap: 6px;
        margin-right: ${rightOffset}px;
      `}
      onClick={onClick}
    >
      <span
        css={`
          margin-top: 2px;
        `}
      >
        Clear
      </span>
      <CloseIcon width="12px" />
    </div>
  );
};

export function MobileFilters(props: {
  tags: Tag[];
  currentFilters: number[];
  currentOrder?: string;
  onOrderChange?: (order: string) => void;
  onFiltersChange: (options: number[]) => void;
  isSticky?: boolean;
}) {
  const [currentFilters, setCurrentFilters] = useState(props.currentFilters);
  const groups = groupBy(props.tags, (tag) => tag.category);

  return (
    <div
      css={
        props.isSticky &&
        css`
          position: sticky;
          top: 0;
          z-index: ${SEARCH_FILTERS_LEVEL};
          background: ${Colors.white};
          transition: top 0.2s ease-in-out 0s;
          transform: translate3d(0px, 0px, 0px);

          body.header-pinned & {
            top: 70px;
          }
        `
      }
    >
      <div
        css={css`
          padding: ${Spacing.m} 0;
          text-align: center;
        `}
      >
        <Wrapper type="header">
          <MobileDropdown
            label={
              currentFilters.length > 0
                ? `Filter results (${currentFilters.length})`
                : "Filter results"
            }
            active={currentFilters.length > 0}
            icon={<FilterIcon />}
            title="Filter by:"
            additionalAction={
              currentFilters.length > 0 ? (
                <MobileFilterClearButton
                  onClick={() => {
                    setCurrentFilters([]);
                    props.onFiltersChange([]);
                  }}
                />
              ) : null
            }
            bottom={({ close }) => (
              <Row justify="space-between">
                <div>
                  <Button
                    type="button"
                    style="black-border"
                    label="Clear filters"
                    onClick={() => setCurrentFilters([])}
                  />
                </div>
                <div>
                  <Button
                    type="button"
                    label="Save"
                    onClick={() => {
                      props.onFiltersChange(currentFilters);
                      close();
                    }}
                  />
                </div>
              </Row>
            )}
          >
            {() => (
              <Column gutter={Spacing.xl}>
                <div>
                  <Column gutter={Spacing.m}>
                    {Object.keys(groups).map((name) => {
                      const options = getOrderedFilters(groups[name]);

                      return (
                        <FilterSection
                          title={name}
                          key={`filter--${name}`}
                          options={options}
                          currentFilters={currentFilters}
                          onSelect={(filters) => {
                            setCurrentFilters(filters);
                          }}
                        />
                      );
                    })}
                  </Column>
                </div>
              </Column>
            )}
          </MobileDropdown>
        </Wrapper>
      </div>
    </div>
  );
}
