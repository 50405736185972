import React from "react";
import { Row } from "../../helpers/layout";
import { Button } from "../../components/Button";
import { addToCart } from "../../actions/addToCart";
import { useUserState } from "../../context/UserContext";
import { useCart } from "../../context/CartContext";
import { TryInYouRoomButton } from "../../components/TryInYouRoomButton";
import Router from "next/router";
import { InHomeConnection } from "../../types/InHome";
import { useCommonData } from "../../context/CommonDataContext";

export default function InHomeBottomActions({
  products,
  onClose,
}: {
  products: InHomeConnection[];
  onClose?: () => void;
}) {
  const { isAuthenticated } = useUserState();
  const { fetchCart } = useCart();
  const { settings } = useCommonData();

  if (settings.gallery_focus) {
    return null;
  }

  return (
    <Row align="center" justify="space-between">
      <div>
        {products[0] && (
          <TryInYouRoomButton
            productId={products[0].product.id}
            options={[]}
            onClick={onClose}
          />
        )}
      </div>

      <div>
        <Button
          type="button"
          disabled={!isAuthenticated}
          label="Buy the look"
          onClick={async () => {
            for (let i = 0; i < products.length; i++) {
              await addToCart({
                product: products[i].product.id,
                productType: products[i].product.type,
                options: [],
                book_option: null,
              });
            }
            await fetchCart();

            Router.push("/cart", "/cart");
            if (onClose) {
              onClose();
            }
          }}
        />
      </div>
    </Row>
  );
}
