import React, { useState, useEffect } from "react";
import { css } from "styled-components";
import { Colors, TABLET, SMALL_MOBILE } from "../branding";
import { Spacing } from "../helpers/layout";
import SyncLoader from "react-spinners/SyncLoader";
import { fontFamilies } from "../typography";
import NextLink from "next/link";

export const buttonStyles = css`
  border: none;
  outline: none;
  padding: 0 ${Spacing.m};
  font: 12px ${fontFamilies.gotham};
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  transition: all 200ms;

  @media (max-width: ${TABLET}px) {
    font-size: 10px;
    letter-spacing: 0.1em;
  }

  @media (max-width: ${SMALL_MOBILE}px) {
    font-size: 9px;
    letter-spacing: 0.05em;
  }

  span {
    position: relative;
    line-height: 3.66;
  }
`;

export const defaultStyles = css`
  background-color: ${Colors.black};
  border: 1px solid ${Colors.white};
  color: ${Colors.white};
  opacity: 1;
  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const disabledStyles = css`
  background-color: ${Colors.light_grey};
  color: ${Colors.white};
`;

export const darkGreyStyles = css`
  position: relative;
  overflow: hidden;
  background-color: ${Colors.nero_dark};
  border: none;
  color: ${Colors.dark_gray};
  opacity: 1;
  letter-spacing: 0.2em;
  font-weight: 400;
  @media (max-width: ${TABLET}px) {
    font-size: 12px;
  }
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    background: ${Colors.light_grey};
    transition: left 200ms;
    content: "";
  }
  @media (hover: hover) {
    &:hover {
      color: ${Colors.black};

      &:before {
        left: 0;
      }
    }
  }
`;

export const whiteStyles = css`
  position: relative;
  background-color: ${Colors.white};
  color: ${Colors.black};
  overflow: hidden;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    background: ${Colors.light_grey};
    transition: left 200ms;
    content: "";
  }
  @media (hover: hover) {
    &:hover {
      color: ${Colors.black};

      &:before {
        left: 0;
      }
    }
  }
`;

export const blackBorderStyles = css`
  position: relative;
  background-color: transparent;
  border: 1px solid ${Colors.black};
  color: ${Colors.black};
  overflow: hidden;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    background: ${Colors.black};
    transition: left 200ms;
    content: "";
  }
  @media (hover: hover) {
    &:hover {
      color: ${Colors.white};

      &:before {
        left: 0;
      }
    }
  }
`;

export const whiteBorderStyles = css`
  position: relative;
  background-color: transparent;
  border: 1px solid ${Colors.white};
  color: ${Colors.white};
  overflow: hidden;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    background: ${Colors.white};
    transition: left 200ms;
    content: "";
  }
  @media (hover: hover) {
    &:hover {
      color: ${Colors.black};

      &:before {
        left: 0;
      }
    }
  }
`;

export function Button(props: {
  isSubmitting?: boolean;
  type: "button" | "submit" | "reset";
  style?:
    | "default"
    | "white-border"
    | "black-border"
    | "white"
    | "dark-grey"
    | "light-grey";
  label: string;
  successText?: string;
  onClick?: () => void;
  disabled?: boolean;
  fullwidth?: boolean;
  onMouseEnter?: () => void;
}) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const style = props.style || "default";

  useEffect(() => {
    const timer = setTimeout(() => {
      if (success) {
        setSuccess(false);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [success]);

  return (
    <button
      css={css`
        ${buttonStyles}
        width: ${props.fullwidth ? "100%" : "auto"};

        ${style === "default" && defaultStyles};
        ${style === "white" && whiteStyles};
        ${style === "white-border" && whiteBorderStyles};
        ${style === "black-border" && blackBorderStyles};
        ${style === "dark-grey" && darkGreyStyles}

        ${(props.disabled || loading || style === "light-grey") &&
        disabledStyles}
      `}
      type={props.type || "button"}
      disabled={props.isSubmitting || props.disabled}
      onClick={async () => {
        if (props.onClick) {
          setLoading(true);
          setSuccess(true);
          await props.onClick();
          setLoading(false);
        }
      }}
      onMouseEnter={props.onMouseEnter}
    >
      <span
        css={css`
          position: relative;
        `}
      >
        {loading || props.isSubmitting ? (
          <SyncLoader color="#fff" size={7} />
        ) : success && props.successText ? (
          props.successText
        ) : (
          props.label
        )}
      </span>
    </button>
  );
}

const linkStyles = css`
  display: inline-block;
  text-decoration: none;
  & {
    padding-top: 1.225em;
    padding-bottom: 1.225em;
  }

  span {
    line-height: 1;
  }
`;

export const LinkButton = ({
  style,
  label,
  fullwidth,
  onMouseEnter,
  href,
  external,
  pure,
}: {
  style?:
    | "default"
    | "white-border"
    | "black-border"
    | "white"
    | "dark-grey"
    | "light-grey";
  label: string;
  fullwidth?: boolean;
  onMouseEnter?: () => void;
  href: string;
  external?: boolean;
  pure?: boolean;
}) => {
  const _style = style || "default";
  if (pure) {
    return (
      <a
        target={external ? "_blank" : "_self"}
        href={href}
        onMouseEnter={onMouseEnter}
        css={`
          ${buttonStyles}
          width: ${fullwidth ? "100%" : "auto"};

          ${_style === "default" && defaultStyles};
          ${_style === "white" && whiteStyles};
          ${_style === "white-border" && whiteBorderStyles};
          ${_style === "black-border" && blackBorderStyles};
          ${_style === "dark-grey" && darkGreyStyles}
          ${linkStyles}
        `}
      >
        <span
          css={css`
            position: relative;
            line-height: 1;
          `}
        >
          {label}
        </span>
      </a>
    );
  }

  return (
    <NextLink href={href} passHref>
      <a
        onMouseEnter={onMouseEnter}
        css={`
          ${buttonStyles}
          width: ${fullwidth ? "100%" : "auto"};

          ${_style === "default" && defaultStyles};
          ${_style === "white" && whiteStyles};
          ${_style === "white-border" && whiteBorderStyles};
          ${_style === "black-border" && blackBorderStyles};
          ${_style === "dark-grey" && darkGreyStyles}
          ${linkStyles}
        `}
      >
        <span
          css={css`
            position: relative;
            line-height: 1;
          `}
        >
          {label}
        </span>
      </a>
    </NextLink>
  );
};
