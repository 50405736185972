import React from "react";
import styled, { css } from "styled-components";
import { ProductOption } from "../types/ProductOption";

const H5 = styled.h5`
  font-size: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export function PrintDimensions(props: {
  calculated: {
    width: number;
    individualWidth?: number;
    height: number;
    gap: number;
    individualHeight?: number;
  };
  selectedOptions: ProductOption[];
}) {
  const { calculated, selectedOptions } = props;

  const frame = selectedOptions.find((option) => option.type === "FRAME");
  const isUnframed = !frame || frame.name === "Unframed";
  const label = isUnframed ? "Unframed" : "Framed";

  return (
    <div>
      {calculated.width !== calculated.individualWidth ? (
        <React.Fragment>
          <H5>
            Approximate {label} Dimensions: {calculated.width}" x{" "}
            {calculated.height}" (Individual images:{" "}
            {calculated.individualWidth}" x {calculated.individualHeight}
            ")
          </H5>
          <p
            css={css`
              font-size: 12px;
            `}
          >
            *These figures are approximate and include a {calculated.gap}” gap
            between images. Overall dimensions will vary based on how the images
            are hung.
          </p>
        </React.Fragment>
      ) : (
        <H5>
          Approximate {label} Dimensions: {calculated.width}" x{" "}
          {calculated.height}"
        </H5>
      )}
    </div>
  );
}
