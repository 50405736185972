import React from "react";
import { Row, Column, Spacing, Align } from "../helpers/layout";
import { useResponsive } from "../context/ResponsiveContext";

export function ResponsiveLayout(props: {
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Column
        align={props.align}
        justify={props.justify}
        gutter={props.gutter}
        className={props.className}
      >
        {props.children}
      </Column>
    );
  }

  return (
    <Row
      align={props.align}
      justify={props.justify}
      gutter={props.gutter}
      className={props.className}
    >
      {props.children}
    </Row>
  );
}
