import React from "react";
import { css } from "styled-components";
import { useWrapper } from "../helpers/useWrapper";

export function Wrapper(props: {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  disable?: boolean;
  type?: "header";
}) {
  const spacing = useWrapper(props.type);

  return (
    <div
      className={props.className}
      css={
        !props.disable &&
        css`
          width: 100%;
          padding: 0 ${spacing};
          box-sizing: border-box;
        `
      }
    >
      {props.children}
    </div>
  );
}
