import { useEffect } from "react";
import { useModal } from "../context/ModalContext";

export function useBlockScroll(block: boolean) {
  const { incrementModalsOpened, decrementModalsOpened } = useModal();

  useEffect(() => {
    if (block) {
      incrementModalsOpened();
    }

    return () => {
      if (block) {
        decrementModalsOpened();
      }
    };
  }, [block]);
}
