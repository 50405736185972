import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "./TextField";
import { Row } from "../helpers/layout";
import SearchIcon from "../icons/SearchIcon";
import { PlainButton } from "../components/PlainButton";

interface FormValues {
  query: string;
}

export function SearchForm(props: {
  onSubmit: (values: FormValues) => void;
  urlFilters?: boolean;
}) {
  useEffect(() => {
    const input = document.getElementsByTagName("input")[0];

    if (input) {
      input.focus();
    }
  }, []);
  return (
    <Formik<FormValues>
      initialValues={{ query: "" }}
      validationSchema={Yup.object().shape({
        query: Yup.string(),
      })}
      onSubmit={async (values: FormValues) => {
        props.onSubmit(values);

        setTimeout(() => {
          const input = document.getElementsByTagName("input")[0];

          if (input) {
            input.blur();
          }
        }, 20);
      }}
    >
      {() => (
        <Form noValidate autoComplete="off">
          <Row>
            <TextField name="query" type="text" placeholder="Type something" />
            <PlainButton icon={<SearchIcon />} type="submit" />
          </Row>
        </Form>
      )}
    </Formik>
  );
}
