import { maybeAuthorised } from "../request";
import { InteriorDesigner } from "../types/InteriorDesigner";
import { ProductSize } from "../types/Product";
import { ProductOption } from "../types/ProductOption";
import { MOBILE } from "../branding";

export async function addToInteriorDesigner(
  token: string,
  data: {
    product: number;
    position_x?: number;
    position_y?: number;
    size?: ProductSize;
    options: ProductOption[];
  }
) {
  const API = maybeAuthorised();
  const isMobile = window.innerWidth <= MOBILE;
  const wallWidthInInch = isMobile ? 16 : 120;
  const wallWidthInPx = window.innerWidth;
  const pxPerInch = wallWidthInPx / wallWidthInInch;
  const positionX = isMobile ? 0.24 : 0.4;
  const positionY = 0.4;

  return await API.request<InteriorDesigner>({
    method: "POST",
    url: `/api/frontend/interior-designer/${token}/`,
    data: {
      ...data,
      position_x: data.position_x || positionX,
      position_y: data.position_y || positionY,
      px_per_inch: Number(pxPerInch.toFixed(2)),
    },
  });
}
