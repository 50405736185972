import React from "react";
import * as Formik from "formik";
import { css } from "styled-components";
import Select, { createFilter } from "react-select";
import { Styles } from "react-select/src/styles";
import { Colors } from "../branding";
import { FieldError } from "./TextField";
import { ValueType } from "react-select/src/types";
import { useResponsive } from "../context/ResponsiveContext";
import arrowDown from "../static/images/arrow-down.png";

export type Option = {
  value: string;
  label: string;
};

const customStyles: Styles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "none",
    borderRadius: "none",
    boxShadow: "none",
    border: "none",
    borderBottom: `1px solid ${Colors.light_grey}`,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: `${Colors.dark_gray}`,
    marginLeft: "-8px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: "-8px",
  }),
};

export function SelectField(props: {
  name: string;
  options: Option[];
  placeholder: string;
}) {
  return (
    <div>
      <Formik.Field name={props.name}>
        {({ form, field }: Formik.FieldProps) => (
          <PlainSelect
            options={props.options}
            placeholder={props.placeholder}
            value={field.value}
            onChange={(value) => form.setFieldValue(props.name, value)}
          />
        )}
      </Formik.Field>
      <FieldError name={props.name} />
    </div>
  );
}

export function PlainSelect(props: {
  options: Option[];
  placeholder: string;
  value: string;
  fullBorder?: boolean;
  onChange: (state: string) => void;
}) {
  const { isMobile } = useResponsive();
  const value = props.options.find(
    (option: Option) => option.value === props.value
  );

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "start" as const,
  };

  const styles = customStyles;

  if (props.fullBorder) {
    styles.control = (styles) => ({
      ...styles,
      paddingLeft: 10,
      border: `1px solid ${Colors.light_grey}`,
    });
  }

  if (isMobile) {
    return (
      <select
        css={css`
          background: none;
          border: none;
          border-radius: 0;
          height: 40px;
          width: 100%;
          padding: 0;
          appearance: none;
          border-bottom: 1px solid ${Colors.light_grey};
          font-size: 16px;
          background: url(${arrowDown}) right center;
          background-repeat: no-repeat;
          background-size: 12px 7px;

          &::placeholder {
            font-size: 14px;
          }

          &:focus {
            border-bottom: 1px solid ${Colors.black};
          }
        `}
        onChange={(e) => {
          const value = e.target.value;

          props.onChange(value);
        }}
      >
        <option value="" hidden>
          {props.placeholder}
        </option>
        {props.options.map((option, index) => {
          return (
            <option
              selected={option.value === value?.value}
              value={option.value}
              key={`options--${index}`}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    );
  }

  return (
    <Select
      css={css`
        font-size: 14px;
      `}
      className="react-select"
      options={props.options}
      placeholder={props.placeholder}
      filterOption={createFilter(filterConfig)}
      styles={styles}
      isSearchable={!isMobile}
      value={value}
      onChange={(state: ValueType<Option>) => {
        const value = (state as Option).value;

        props.onChange(value);
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary75: Colors.very_light_grey,
          primary50: Colors.very_light_grey,
          primary25: Colors.very_light_grey,
          primary: Colors.black,
        },
        borderRadius: 0,
      })}
    />
  );
}
