import React from "react";
import { CommonData } from "../types/CommonData";

export const defaultSettings = {
  global_discount: "0",
  instagram_url: "",
  facebook_url: "",
  pinterest_url: "",
  studio_director_first_name: "",
  studio_director_full_name: "",
  studio_director_phone_number: "",
  checkout_sandbox: false,
  product_information: [],
  gallery_focus: false,
  main_featured_image: null,
};

const CommonDataContext = React.createContext<CommonData>({
  footerMenu: null,
  footerSmallMenu: null,
  headerMenu: null,
  headerMobileMenu: null,
  howYouFoundDrew: null,
  helpTexts: [],
  settings: defaultSettings,
  userAgent: "",
});

export function CommonDataProvider(props: {
  data: CommonData;
  children: React.ReactNode;
}) {
  return (
    <CommonDataContext.Provider value={props.data}>
      {props.children}
    </CommonDataContext.Provider>
  );
}

export function useCommonData() {
  const context = React.useContext(CommonDataContext);

  if (context === undefined) {
    throw new Error("useCommonData must be used within a CommonDataProvider");
  }

  return context;
}
