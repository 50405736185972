import React, { useState } from "react";
import { Column, Row, Spacing } from "../helpers/layout";
import { Product, ProductSize } from "../types/Product";
import { ProductOption } from "../types/ProductOption";
import { PrintSizes } from "./PrintSizes";
import { PrintOptions } from "./PrintOptions";
import { Section, ItalicSubTitle } from "../typography";
import { Hr } from "./Hr";
import { SizeConfigurator } from "../modals/SizeConfigurator";
import WheelIcon from "../icons/WheelIcon";
import { PlainButton } from "./PlainButton";
import { getOptionSize, isOptionAvailable } from "../helpers/product";
import { css } from "styled-components";
import { useUserState } from "../context/UserContext";
import { useModal } from "../context/ModalContext";
import { useResponsive } from "../context/ResponsiveContext";
import { HelpLink } from "./HelpLink";

export function PrintConfigurator(props: {
  product: Product;
  selected: {
    size: ProductSize;
    options: ProductOption[];
  };
  onSelectSize: (size: ProductSize) => void;
  onSelectOptions: (options: ProductOption[]) => void;
  disablePrices?: boolean;
  disableSizeSelection?: boolean;
}) {
  const { isAuthenticated } = useUserState();
  const { showModal } = useModal();
  const { product } = props;
  const { isMobile } = useResponsive();
  const [showSizeConfigurator, setShowSizeConfigurator] = useState(false);
  const { size, options } = props.selected;

  if (!product.sizes.length) return null;

  return (
    <React.Fragment>
      <Column>
        {!props.disableSizeSelection && (
          <Row wrap="wrap" justify="space-between">
            <div
              css={css`
                flex: 1 0 40%;
              `}
            >
              <Section number={1}>
                <span>Choose a print size</span>
                {product.type === "PAIRING" && (
                  <ItalicSubTitle
                    css={css`
                      margin-top: ${Spacing.xs};
                    `}
                  >
                    {product.pairing_type === "BUNDLE"
                      ? "Dimensions are for each individual image"
                      : `Dimensions include all images, assuming a 1" gap between each`}
                  </ItalicSubTitle>
                )}
              </Section>
            </div>
            {product.serie && (
              <div
                css={css`
                  flex: 0 0 auto;
                `}
              >
                <HelpLink
                  identifier={`print_sizes_${product.serie.slug.toLowerCase()}`}
                  hideLabel={isMobile}
                />
              </div>
            )}
          </Row>
        )}
        <Column gutter={Spacing.xl}>
          {!props.disableSizeSelection && (
            <>
              <PrintSizes
                product={product}
                sizes={product.sizes}
                selected={size}
                disablePrices={props.disablePrices}
                onClick={(size) => {
                  props.onSelectSize(size);

                  const availableOptions = options.filter((option) => {
                    return getOptionSize(product.pricing, size, option);
                  });

                  props.onSelectOptions(availableOptions);
                }}
              />
              {product.type === "PAIRING" && (
                <div
                  css={css`
                    text-align: center;
                  `}
                >
                  <ItalicSubTitle>Price includes all images</ItalicSubTitle>
                </div>
              )}
              {product.sizes.length > 0 && (
                <Column
                  gutter={Spacing.xs}
                  css={css`
                    text-align: center;
                  `}
                >
                  <div
                    css={css`
                      font-size: 14px;
                      text-transform: uppercase;
                      letter-spacing: 0.06em;
                      font-weight: 500;
                    `}
                  >
                    Looking for a custom size?
                  </div>
                  <div>
                    <PlainButton
                      onClick={() => {
                        if (!isAuthenticated) {
                          showModal("login", {
                            type: "product",
                          });
                        } else {
                          setShowSizeConfigurator(true);
                        }
                      }}
                      icon={<WheelIcon />}
                      iconPosition="left"
                      hoverEffect
                    >
                      Enter Dimensions Here
                    </PlainButton>
                  </div>
                </Column>
              )}
              <Hr size="full" />
            </>
          )}
          {size && (
            <PrintOptions
              product={product}
              size={size}
              selected={options}
              initialIndex={props.disableSizeSelection ? 0 : 1}
              disablePrices={props.disablePrices}
              onSelect={(option) => {
                let newSelected: ProductOption[] = [];

                // if we click on a frame and it has no borders
                // then no Frames should be avaialable
                // and the border requires the current frame.
                if (option.type === "FRAME") {
                  newSelected = options.filter(
                    (o) => o.type === "BORDER" && o.requires.includes(option.id)
                  );

                  // however if we the borders are avaialbe and has plexis then
                  // add them to the list as well.
                  if (newSelected.length > 0) {
                    const plexis = options.filter((o) => o.type === "PLEXI");
                    newSelected = [...newSelected, ...plexis];
                  }
                } else {
                  newSelected = options.filter((o) => o.type !== option.type);
                }

                newSelected = [...newSelected, option];

                newSelected = newSelected.filter((o) =>
                  isOptionAvailable(o, newSelected)
                );

                props.onSelectOptions(newSelected);
              }}
            />
          )}
        </Column>
      </Column>
      {product.sizes.length > 0 && (
        <SizeConfigurator
          visible={showSizeConfigurator}
          sizes={product.sizes}
          initialValue={size}
          onClose={() => {
            setShowSizeConfigurator(false);
          }}
          onSubmit={(size) => {
            props.onSelectSize(size);

            const availableOptions = options.filter((option) => {
              return getOptionSize(product.pricing, size, option);
            });

            props.onSelectOptions(availableOptions);

            setShowSizeConfigurator(false);
          }}
        />
      )}
    </React.Fragment>
  );
}
