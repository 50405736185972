import React from "react";
import Masonry from "react-masonry-component";
import { css } from "styled-components";

function getWidth(columns: number, margin: number) {
  const margins = (columns - 1) * margin;

  return (100 - margins) / columns;
}

export function MasonryGrid<T>(props: {
  items: T[];
  columns: number;
  margin?: number;
  children: (item: T, index: number) => React.ReactNode;
}) {
  const { columns, items, margin: propMargin } = props;
  const margin = propMargin || 2;
  const width = getWidth(columns, margin);

  return (
    <div
      css={css`
        .grid-item,
        .grid-sizer {
          width: ${width}%;
        }

        .grid-item--fullwidth {
          width: 100%;
        }

        .grid-item--double {
          width: ${width * 2 + margin}%;
        }

        .gutter-sizer {
          width: ${margin}%;
        }
      `}
    >
      <Masonry
        options={{
          columnWidth: ".grid-sizer",
          itemSelector: ".grid-item",
          gutter: ".gutter-sizer",
          percentPosition: true,
        }}
      >
        <div className="grid-sizer"></div>
        <div className="gutter-sizer"></div>
        {items.map((item, index) => {
          return props.children(item, index);
        })}
      </Masonry>
    </div>
  );
}
