import React from "react";
import { BaseModal } from "./BaseModal";
import { HelpText } from "../types/HelpText";
import { css } from "styled-components";
import { Column, Spacing } from "../helpers/layout";
import { ModalTitle } from "../typography";
import { EditorContent } from "../components/EditorContent";
import { Colors } from "../branding";
import { Scrollbar } from "../components/Scrollbar";
import { useResponsive } from "../context/ResponsiveContext";

export const HelpTextModal = React.memo(function (props: {
  visible: boolean;
  helpText: HelpText;
  onClose: () => void;
}) {
  const { title, text } = props.helpText;
  const { isMobile } = useResponsive();

  return (
    <BaseModal visible={props.visible} onClose={props.onClose}>
      <div
        css={css`
          padding: ${Spacing.xxl} ${isMobile ? Spacing.xl : Spacing.xxl};
          max-width: 660px;
          width: 100vw;
          box-sizing: border-box;
          background: ${Colors.white};
          color: ${Colors.black};
        `}
      >
        <Column gutter={Spacing.l}>
          <ModalTitle>{title}</ModalTitle>
          <div
            css={css`
              height: 70vh;
              max-height: 400px;
            `}
          >
            <Scrollbar height="100%">
              <div
                css={css`
                  padding-right: ${Spacing.l};
                `}
              >
                <EditorContent text={text} />
              </div>
            </Scrollbar>
          </div>
        </Column>
      </div>
    </BaseModal>
  );
});
