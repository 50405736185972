import { notAuthorised } from "../request";
import { ProductTag } from "../types/ProductTag";

export async function getProductTags() {
  let tags: ProductTag[] = [];

  try {
    const tagsResponse = await notAuthorised.request<ProductTag[]>({
      method: "GET",
      url: `/api/frontend/product-tags/`
    });

    tags = tagsResponse.data;
  } catch (e) {
    console.log(e);
  }

  return tags;
}
