import * as types from "../types/InHome";
import { Row, Column, Spacing } from "../helpers/layout";
import { css } from "styled-components";
import { Image } from "./Image";
import { ProductTitle, ProductSerie } from "../typography";
import { AddToWishlistButton } from "./AddToWishlistButton";
import { CompareButton } from "./CompareButton";
import { Link } from "./Link";
import { Button } from "./Button";
import Router from "next/router";
import { ResponsiveLayout } from "./ResponsiveLayout";
import { useResponsive } from "../context/ResponsiveContext";
import { useCommonData } from "../context/CommonDataContext";

export function InHomeProduct(props: {
  product: types.InHomeProduct;
  onClose?: () => void;
  isMainImageLandscape?: boolean;
}) {
  const { is13inch, isMobile } = useResponsive();
  const { settings } = useCommonData();
  const { product } = props;

  return (
    <ResponsiveLayout
      align="center"
      css={
        (props.isMainImageLandscape && is13inch) || isMobile
          ? "flex-direction: column;"
          : "flex-direction: row;"
      }
    >
      <div
        css={css`
          flex: 0 0 auto;
          width: 235px;
          text-align: center;

          img {
            max-height: 185px;
            width: auto;
          }

          ${is13inch &&
          css`
            width: 187px;

            img {
              max-height: 130px;
            }
          `}
        `}
      >
        <Link href={`/shop/${product.slug}`} noEffect onClick={props.onClose}>
          <Image file={product.featured_image} width={300} />
        </Link>
      </div>
      <div
        css={css`
          flex: 1 0 20%;
        `}
      >
        <Column>
          <div
            css={css`
              text-align: center;
            `}
          >
            <Column gutter={Spacing.s}>
              <ProductTitle>
                <Link href={`/shop/${product.slug}`} onClick={props.onClose}>
                  {product.name}
                </Link>
              </ProductTitle>
              {product.serie && (
                <ProductSerie>
                  <Link
                    href={`/series/${product.serie.slug}`}
                    onClick={props.onClose}
                  >
                    {product.serie.name}
                  </Link>
                </ProductSerie>
              )}
            </Column>
          </div>

          <div
            css={css`
              text-align: center;
              ${settings.gallery_focus ? `margin-bottom: ${Spacing.m};` : ""}
            `}
          >
            <Button
              label="View Pricing"
              style="black-border"
              type="button"
              onClick={() => {
                Router.push("/shop/[slug]", `/shop/${product.slug}`);
                if (props.onClose) {
                  props.onClose();
                }
              }}
            />
          </div>
          {settings.gallery_focus ? null : (
            <div>
              <Row align="center" justify="center">
                <div>
                  <AddToWishlistButton
                    productId={product.id}
                    bookOptions={product.book_options}
                  />
                </div>
                <div>
                  <CompareButton
                    productId={product.id}
                    bookOptions={product.book_options}
                  />
                </div>
              </Row>
            </div>
          )}
        </Column>
      </div>
    </ResponsiveLayout>
  );
}
