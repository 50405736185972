import React, { useRef } from "react";
import { css } from "styled-components";
import { Colors } from "../branding";
import useOnClickOutside from "use-onclickoutside";
import { HEADER_DESKTOP_DROPDOWN_LEVEL } from "../helpers/zIndex";

function Triangle(props: { color: string; className?: string }) {
  return (
    <div
      className={props.className}
      css={css`
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid ${props.color};
      `}
    />
  );
}

export function HeaderDesktopDropdown(props: {
  children: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  arrowRight?: number;
  top?: number;
}) {
  const ref = useRef(null);
  const top = props.top || 55;
  const arrowRight = props.arrowRight || 70;

  useOnClickOutside(ref, props.onClose);

  return (
    <div
      ref={ref}
      css={css`
        position: absolute;
        right: 0;
        top: ${top}px;
        width: 100vw;
        max-width: 480px;
        box-sizing: border-box;
        z-index: ${HEADER_DESKTOP_DROPDOWN_LEVEL};
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        color: ${Colors.black};
        max-height: 99vh;
        overflow-y: auto;
      `}
    >
      <Triangle
        color={"#ececec"}
        css={css`
          position: absolute;
          top: -14px;
          right: ${arrowRight}px;
        `}
      />
      <Triangle
        color={Colors.white}
        css={css`
          position: absolute;
          top: -13px;
          right: ${arrowRight}px;
        `}
      />
      <div
        css={css`
          height: 100%;
          background-color: ${Colors.white};
          padding: 40px 60px;
          box-sizing: border-box;
          position: relative;
          overflow: hidden;
        `}
      >
        {props.children}
      </div>
    </div>
  );
}
