import React from "react";
import { FullscreenModal } from "./FullscreenModal";
import { InHome } from "../types/InHome";
import InHomeView from "../components/InHomeView";

export function InHomeModal(props: {
  inHome: InHome;
  allInHomes?: InHome[];
  visible: boolean;
  onClose: () => void;
}) {
  return (
    <FullscreenModal noPadding visible={props.visible} onClose={props.onClose}>
      <InHomeView
        inHome={props.inHome}
        onClose={props.onClose}
        allInHomes={props.allInHomes}
      />
    </FullscreenModal>
  );
}
