import { OrderItem } from "../types/items/OrderItem";
import { ProductOption } from "../types/ProductOption";
import { ProductSize, Product, ProductPricing } from "../types/Product";
import { sanitizePrice, roundToPrecision } from "./utils";
import { Address } from "../types/Address";
import { Settings } from "../types/Settings";

type LineItem = Pick<OrderItem, "product" | "size" | "options">;

type Interval = {
  from: ProductSize;
  to: ProductSize;
};

/**
 * Returns sizes array ordered by width
 *
 * @param sizes
 */
export function getOrderedSizes(sizes: ProductSize[]) {
  return sizes.sort((a, b) => {
    if (a.width < b.width) {
      return -1;
    }

    if (a.width > b.width) {
      return 1;
    }

    return 0;
  });
}

/**
 * Returns sizes interval for specified custom width
 *
 * @param width
 * @param sizes
 */
export function getInterval(width: number, sizes: ProductSize[]) {
  const ordered = getOrderedSizes(sizes);
  let interval: Interval | null = null;

  for (let i = 0; i < ordered.length; i++) {
    if (
      ordered[i + 1] &&
      ordered[i].width <= width &&
      width <= ordered[i + 1].width
    ) {
      interval = {
        from: ordered[i],
        to: ordered[i + 1],
      };
    }
  }

  return interval;
}

/**
 *
 * @param inputWidth
 * @param sizes
 */
export function getCustomSize(
  inputWidth: number,
  sizes: ProductSize[]
): ProductSize {
  const ordered = getOrderedSizes(sizes);
  const smallest = ordered[0];
  const biggest = ordered[ordered.length - 1];

  if (inputWidth <= smallest.width) {
    return smallest;
  }

  if (inputWidth >= biggest.width) {
    return biggest;
  }

  const interval = getInterval(inputWidth, ordered)!;
  const size = interval.from;

  const width = size.width;
  const height = size.height;
  const newWidth = roundToPrecision(inputWidth, 0.5);
  const newHeight = roundToPrecision((newWidth * height) / width, 0.5);

  let newPrice = calculatePrice(newWidth, interval);
  newPrice = roundToPrecision(newPrice, 5);

  return {
    width: newWidth,
    height: newHeight,
    price: newPrice.toString(),
  };
}

/**
 * Calculates price step based on provided sizes interval
 *
 * @param interval
 */
export function getIntervalPriceStep(interval: Interval) {
  const { to, from } = interval;
  const deltaPrice = parseFloat(to.price) - parseFloat(from.price);
  const deltaWidth = to.width - from.width;

  return deltaPrice / deltaWidth;
}

/**
 * Calculates custom print size's price based on provided sizes interval
 *
 * @param width
 * @param interval
 */
export function calculatePrice(width: number, interval: Interval) {
  const priceStep = getIntervalPriceStep(interval);
  const widthDiff = width - interval.from.width;
  const newPrice = parseFloat(interval.from.price) + widthDiff * priceStep;

  return Math.round(newPrice);
}

/**
 * Checks if custom size is within allowed range
 *
 * @param width
 * @param sizes
 */
export function sizeIsValid(width: number, sizes: ProductSize[]) {
  const ordered = getOrderedSizes(sizes);
  const smallest = ordered[0];
  const biggest = ordered[ordered.length - 1];

  if (width < smallest.width) return false;
  if (width > biggest.width) return false;

  return true;
}

/**
 * Returns Option Size properties based on selected Print Size.
 *
 * @param size
 * @param option
 */
export function getOptionSize(
  pricing: ProductPricing,
  size: ProductSize,
  option: ProductOption
) {
  const biggestSide = Math.max(size.width, size.height);
  const sizes = pricing === "SQUARE" ? option.square_sizes : option.sizes;

  return sizes.find((size) => {
    return size.from_size <= biggestSide && biggestSide <= size.to_size;
  });
}

/**
 * Calculates print size based on it's type.
 *
 * @param product
 */
export function getProductSize(
  product: Pick<Product, "sizes" | "pairing_type" | "pairing_products">
) {
  const pieces = getPiecesNumber(product);
  const piecesPerRow = getPiecePerRowNumber(product);
  const piecesPerColumn = pieces / piecesPerRow;
  const size = product.sizes[0];

  if (!size) {
    return null;
  }

  return {
    width: size.width * piecesPerRow,
    height: size.height * piecesPerColumn,
  };
}

/**
 * Returns number of pieces based on product type
 *
 * @param product
 */
export function getPiecesNumber(
  product: Pick<Product, "pairing_type" | "pairing_products">
) {
  const { pairing_type, pairing_products } = product;

  if (pairing_type === "DIPTYCH") {
    return 2;
  }

  if (pairing_type === "TRIPTYCH") {
    return 3;
  }

  if (pairing_type === "QUAD") {
    return 4;
  }

  if (pairing_type === "BUNDLE") {
    return pairing_products.length;
  }

  return 1;
}

/**
 * Returns number of pieces per row. Used in product previews
 *
 * @param product
 */
export function getPiecePerRowNumber(
  product: Pick<Product, "pairing_type" | "pairing_products">
) {
  const { pairing_type, pairing_products } = product;

  if (pairing_type === "DIPTYCH") {
    return 2;
  }

  if (pairing_type === "TRIPTYCH") {
    return 3;
  }

  if (pairing_type === "QUAD") {
    return 2;
  }

  if (pairing_type === "BUNDLE") {
    return pairing_products.length;
  }

  return 1;
}

/**
 * Calculates Product Option Discount. Global discount takes over of locally specified.
 *
 * @param printSize
 * @param option
 * @param chosenOptions
 * @param settings
 */
export function calculateOptionDiscount(
  pricing: ProductPricing,
  printSize: ProductSize,
  option: ProductOption,
  chosenOptions: ProductOption[],
  settings: Settings
) {
  const price = calculateOptionPrice(pricing, printSize, option, chosenOptions);
  const localDiscount = option.discount_percentage;
  const globalDiscount = Number(settings.global_discount);
  const discountPercentage = globalDiscount ? globalDiscount : localDiscount;
  const discount = (price * discountPercentage) / 100;

  if (discount < 0) {
    return 0;
  }

  if (discount > price) {
    return price;
  }

  return discount;
}

/**
 * Returns size sum of option's price components
 *
 * @param printSize
 * @param option
 * @param chosenOptions
 */
export function calculatePriceComponentsSize(
  pricing: ProductPricing,
  printSize: ProductSize,
  option: ProductOption,
  chosenOptions: ProductOption[]
) {
  const priceComponents = option.price_components;
  const initialSize = priceComponents.includes("PRINT")
    ? {
        width: printSize.width,
        height: printSize.height,
      }
    : {
        width: 0,
        height: 0,
      };

  return chosenOptions.reduce((size, option) => {
    if (option.type !== "PLEXI" && priceComponents.includes(option.type)) {
      const optionSize = getOptionSize(pricing, printSize, option);

      if (optionSize) {
        return {
          width: size.width + optionSize.width * 2,
          height: size.height + optionSize.height * 2,
        };
      }
    }

    return size;
  }, initialSize);
}

/**
 * Calculates product option price based on print size and other selected options
 *
 * @param printSize
 * @param option
 * @param chosenOptions
 */
export function calculateOptionPrice(
  pricing: ProductPricing,
  printSize: ProductSize,
  option: ProductOption,
  chosenOptions: ProductOption[]
) {
  const optionSize = getOptionSize(pricing, printSize, option);

  if (!optionSize) return 0;

  const price = parseFloat(optionSize.price);

  if (!option.price_per_inch) return price;

  const pricingSize = calculatePriceComponentsSize(
    pricing,
    printSize,
    option,
    chosenOptions
  );

  const biggestSide = Math.max(pricingSize.width, pricingSize.height);
  const newPrice = biggestSide * price;

  return roundToPrecision(newPrice, 5);
}

/**
 * Calculates size of the print + all chosen options (border, frame, etc)
 *
 * @param printSize
 * @param options
 */
export function calculateSize(
  pricing: ProductPricing,
  printSize: ProductSize,
  options: ProductOption[]
) {
  return options.reduce(
    (calculated, option) => {
      const optionSize = getOptionSize(pricing, printSize, option);

      if (!optionSize) {
        return calculated;
      }

      return {
        width: calculated.width + 2 * optionSize.width,
        height: calculated.height + 2 * optionSize.height,
      };
    },
    {
      width: printSize.width,
      height: printSize.height,
    }
  );
}

/**
 * Calculates total
 *
 * @param subtotal
 * @param discount
 */
export function calculateTotal(subtotal: number, discount: number) {
  const total = subtotal - discount;

  if (total > 0) {
    return total;
  }

  return 0;
}

/**
 * Calculates Order Item details
 *
 * @param item
 * @param settings
 */
export function calculateOrderItem(item: LineItem, settings: Settings) {
  const { options, size, product } = item;

  if (!size) {
    const subtotal = parseFloat(item.product.price || "0.00");
    const discount = 0;
    const total = calculateTotal(subtotal, discount);

    return {
      subtotal: sanitizePrice(subtotal),
      discount: sanitizePrice(discount),
      total: sanitizePrice(total),
      gap: 0,
      width: 0,
      height: 0,
    };
  }

  const itemSize = calculateSize(item.product.pricing, size, options);
  const pieces = getPiecesNumber(item.product);
  const piecesPerRow = getPiecePerRowNumber(item.product);
  const piecesPerColumn = pieces / piecesPerRow;

  const itemPrice = options.reduce(
    (calculated, option) => {
      const optionPrice = calculateOptionPrice(
        item.product.pricing,
        size,
        option,
        options
      );
      const optionDiscount = calculateOptionDiscount(
        item.product.pricing,
        size,
        option,
        options,
        settings
      );

      const subtotal = pieces * optionPrice;
      const discount = pieces * optionDiscount;
      const total = calculateTotal(subtotal, discount);

      return {
        subtotal: calculated.subtotal + subtotal,
        discount: calculated.discount + discount,
        total: calculated.total + total,
      };
    },
    {
      subtotal: parseFloat(size.price),
      discount: 0,
      total: parseFloat(size.price),
    }
  );

  const gap = product.pairing_type === "BUNDLE" ? 3 : 1;
  const horizontalGap = (piecesPerRow * 1 - 1) * gap;
  const verticalGap = (piecesPerColumn * 1 - 1) * gap;

  return {
    subtotal: sanitizePrice(itemPrice.subtotal),
    gap: gap,
    discount: sanitizePrice(itemPrice.discount),
    total: sanitizePrice(itemPrice.total),
    width: itemSize.width * piecesPerRow + horizontalGap,
    height: itemSize.height * piecesPerColumn + verticalGap,
    individualWidth: itemSize.width,
    individualHeight: itemSize.height,
  };
}

/**
 * Checks if product option is available based on other selected options
 *
 * @param option
 * @param selected
 */
export function isOptionAvailable(
  option: ProductOption,
  selected: ProductOption[]
) {
  if (option.requires && option.requires.length) {
    const hasAllRequirements = option.requires.reduce(
      (hasAll, requirementId) => {
        const requirement = selected.find(
          (option) => option.id === requirementId
        );

        if (requirement) {
          return true;
        }

        return hasAll;
      },
      false
    );

    return hasAllRequirements;
  }

  return true;
}

/**
 * Order of Product Option Categories (Types)
 */
export const OptionTypeOrder: {
  [key: string]: number;
} = {
  FRAME: -3,
  BORDER: -2,
  PLEXI: -1,
  DEFAULT: 0,
};

/**
 * Checks if selected order item is shippable worldwide
 */
export function itemHasWorldwideShipping(item: { options: ProductOption[] }) {
  return item.options.reduce((worldwide, option) => {
    if (!option.worldwide_shipping) {
      return false;
    }

    return worldwide;
  }, true);
}

export const isItemFramed = (item: OrderItem) => {
  const itemType = item.product.type;
  if (itemType !== "PHOTO" && itemType !== "PAIRING") return false;

  return !!item.options.find(
    (o) => o.name !== "Unframed" && o.type === "FRAME"
  );
};

// list of states requiring fedex shipping instead of domestic shipping (relevant for US addresses)
// we need to check this list because fedex shipping does not support FRAMED prints shipping.
const PRINTS_states_fedex = [
  "AK",
  "AS",
  "FM",
  "GU",
  "HI",
  "MH",
  "MP",
  "PW",
  "PR",
  "VI",
  "AA",
  "AE",
  "AP",
];
/**
 * Checks if order item is shippable to specified address.
 *
 * @param item
 * @param address
 */
export function isItemShippable(item: OrderItem, address: Address | null) {
  if (!address) return false;

  // framed photos or pairings cannot be shipped worldwide
  if (isItemFramed(item)) {
    if (
      (address.country === "US" &&
        PRINTS_states_fedex.includes(address.state)) ||
      address.country !== "US"
    ) {
      return false;
    }
  }

  // domestic shipping for books and UNFRAMED prints
  if (address.country === "US") {
    return true;
  }

  const hasWorldwideShipping = itemHasWorldwideShipping(item);
  return hasWorldwideShipping;
}

/**
 * Convers price to Affirm format.
 *
 * @param price
 */
export function getAffirmPrice(price: string) {
  return Number(price) * 100;
}

/**
 * Checks if the shipping is within United States
 *
 * @param address
 */

export function isWithinUS(address: Address | null): boolean {
  if (!address) return false;

  if (address.country === "US") {
    return true;
  }

  return false;
}

export const getInquiryLink = (name: string, currentUserName?: string) => {
  const userName = currentUserName ? `- ${currentUserName}.` : "Regards,";
  return `mailto:gallery@drewdoggett.com?subject=${encodeURIComponent(
    `${name} Print Inquiry`
  )}&body=${encodeURIComponent(
    `Dear Elle,

I am interested in learning more about the available sizes and prices of ${name}.

${userName}`
  )}`;
};
