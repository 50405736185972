import React, { useState, useEffect, useCallback } from "react";

type ModalTypes = "login";
type ModalOptions = {
  [key: string]: any;
};

type Modal = {
  type: ModalTypes;
  options: ModalOptions;
};

type ModalData = {
  modal: Modal | null;
  modalCallback: null | Callback;
  modalsOpened: number;
  showModal: (modal: ModalTypes, options?: ModalOptions) => void;
  hideModal: () => void;
  incrementModalsOpened: () => void;
  decrementModalsOpened: () => void;
  flushModalsOpened: () => void;
  setModalCallback: (callback: Callback) => void;
};

type Callback = () => void;

const ModalContext = React.createContext<ModalData>({
  modal: null,
  modalCallback: null,
  modalsOpened: 0,
  showModal: () => {},
  hideModal: () => {},
  incrementModalsOpened: () => {},
  decrementModalsOpened: () => {},
  flushModalsOpened: () => {},
  setModalCallback: () => {},
});

export function ModalProvider(props: { children: React.ReactNode }) {
  const [modal, showModal] = useState<Modal | null>(null);
  const [modalsOpened, setModalsOpened] = useState(0);
  const [modalCallback, setModalCallback] = useState<null | Callback>(null);

  const hideModal = () => {
    showModal(null);
  };

  useEffect(() => {
    if (modalsOpened > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalsOpened]);

  const handleShowModal = useCallback(
    (type: ModalTypes, options?: ModalOptions) => {
      showModal({
        type,
        options: options || {},
      });
    },
    []
  );

  return (
    <ModalContext.Provider
      value={{
        modal,
        modalsOpened,
        showModal: handleShowModal,
        hideModal,
        modalCallback: () => {
          if (modalCallback) {
            modalCallback();
          }

          hideModal();
        },
        setModalCallback: (callback: Callback) => {
          setModalCallback(() => {
            return callback;
          });
        },
        incrementModalsOpened: () => {
          setModalsOpened((v) => v + 1);
        },
        decrementModalsOpened: () => {
          setModalsOpened((v) => (v > 0 ? v - 1 : 0));
        },
        flushModalsOpened: () => {
          setModalsOpened(0);
        },
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = React.useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
}
