import { notAuthorised } from "../request";
import capitalize from "lodash/capitalize";

export async function signupNewsletter({
  email,
  name,
  listId,
  disableConfirmationEmail,
}: {
  email: string;
  name?: string;
  listId?: string | undefined;
  disableConfirmationEmail?: boolean;
}) {
  const API = notAuthorised;

  return await API.request({
    method: "POST",
    url: `/api/frontend/newsletter/`,
    data: {
      email,
      first_name: capitalize(name),
      listId,
      skip_confirmation: disableConfirmationEmail,
    },
  });
}
