import React from "react";
import NumberFormat from "react-number-format";
import { css } from "styled-components";

export function Price(props: {
  price: string | number;
  discount?: string | number;
  notBold?: boolean;
}) {
  const discount = props.discount ? Number(props.discount) : null;
  const price = Number(props.price);
  const hasCents = price - Math.floor(price) > 0;

  return (
    <div
      css={css`
        display: inline-block;
      `}
    >
      <div
        css={css`
          font-weight: ${props.notBold ? 400 : 500};
        `}
      >
        <NumberFormat
          value={discount ? price - discount : price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={hasCents}
        />
      </div>
      {!!discount && (
        <div
          css={css`
            text-decoration: line-through;
            font-weight: normal;
            font-size: 10px;
            line-height: 1.5;
          `}
        >
          <NumberFormat
            value={price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
            fixedDecimalScale={hasCents}
          />
        </div>
      )}
    </div>
  );
}
