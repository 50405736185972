import React, { useState, useEffect, useCallback } from "react";
import { authorised } from "../request";
import { Cart } from "../types/Cart";
import { useUserState } from "./UserContext";

const emptyCart = {
  items: [],
  count: 0,
  subtotal: "0.00",
  discount: "0.00",
  total: "0.00",
  // HARDCODED DISCOUNT CODES
  can_be_discounted: false,
};

const CartContext = React.createContext<{
  opened: boolean;
  cart: Cart;
  fetchCart: () => void;
  openCart: () => void;
  closeCart: () => void;
}>({
  opened: false,
  cart: emptyCart,
  fetchCart: () => {},
  openCart: () => {},
  closeCart: () => {},
});

export function CartProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated } = useUserState();
  const [opened, setOpened] = useState(false);
  const [cart, setCart] = useState<Cart>(emptyCart);

  const fetchCart = useCallback(async () => {
    try {
      const API = authorised();
      const response = await API.request<Cart>({
        method: "GET",
        url: "/api/frontend/cart/",
      });

      setCart(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const openCart = () => {
    setOpened(true);
  };
  const closeCart = () => {
    setOpened(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchCart();
    } else {
      setCart(emptyCart);
    }
  }, [fetchCart, isAuthenticated]);

  return (
    <CartContext.Provider
      value={{
        opened,
        openCart,
        closeCart,
        cart,
        fetchCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = React.useContext(CartContext);

  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }

  return context;
}
