const HeartFilledIcon = () => (
  <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(1.000000, 1.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path d="M13.67079,0.08652 C12.00528,0.08652 10.43931,0.88788 9.45,2.23503 C8.46069,0.88788 6.89451,0.08652 5.229,0.08652 C2.3457,0.08652 0,2.43222 0,5.31573 C0,6.18303 0.35007,7.24731 1.04013,8.47875 C3.44904,12.77619 9.32295,17.89683 9.38196,17.94828 C9.38721,17.9529 9.39351,17.95521 9.3996,17.95857 C9.40317,17.96067 9.40653,17.96319 9.41031,17.96487 C9.42312,17.97012 9.43656,17.97348 9.45021,17.97348 C9.45021,17.97348 9.45021,17.97348 9.45021,17.97348 C9.45021,17.97348 9.45021,17.97348 9.45021,17.97348 C9.46344,17.97348 9.47646,17.97033 9.48906,17.96508 C9.49305,17.9634 9.4962,17.96088 9.49998,17.95878 C9.50565,17.95563 9.51153,17.95353 9.51678,17.94912 C9.89961,17.62929 18.9,10.05837 18.9,5.31573 C18.9,2.43222 16.5543,0.08652 13.67079,0.08652 Z"></path>
      </g>
    </g>
  </svg>
);
export default HeartFilledIcon;
