const SearchIcon = () => (
  <svg viewBox="0 0 100 90" version="1.1" width="25" height="30">
    <path
      fill="fill"
      strokeWidth="2"
      stroke="currentColor"
      width="100%"
      height="100%"
      d="M65.32,21.93A30.67,30.67,0,1,0,64.59,66L85.34,86.76a1,1,0,0,0,1.41-1.41L66,64.59a30.66,30.66,0,0,0-.69-42.66Zm-42,42a28.68,28.68,0,1,1,40.56,0A28.49,28.49,0,0,1,23.35,63.9Z"
    />
  </svg>
);
export default SearchIcon;
