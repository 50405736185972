import { maybeAuthorised } from "../request";
import { Comparison } from "../types/Comparison";

export async function deleteComparisonItem(token: string, id: number) {
  const API = maybeAuthorised();

  return await API.request<Comparison>({
    method: "DELETE",
    url: `/api/frontend/comparison/${token}/${id}/`
  });
}
