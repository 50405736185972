import styled from "styled-components";

export enum Spacing {
  none = "0",
  xs = "0.25rem",
  s = "0.5rem",
  m = "1rem",
  l = "1.5rem",
  xl = "2rem",
  xxl = "4rem",
  xxxl = "8rem",
  xxxxl = "16rem",
}

export type Align =
  | "flex-start"
  | "flex-end"
  | "space-between"
  | "center"
  | "space-around"
  | "stretch";

export const Column = styled.div<{
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};
  gap: ${(props) => props.gutter || Spacing.m};

  > * {
    width: 100%;
  }
`;

export type RowProps = {
  align?: Align;
  justify?: Align;
  gutter?: Spacing;
  width?: number | string;
  wrap?: "wrap" | "nowrap";
};

export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};
  width: ${({ width }) => {
    if (width) {
      if (typeof width === "number") {
        if (width <= 1) {
          return width * 100 + "%";
        } else {
          return width + "px";
        }
      } else {
        return width;
      }
    }
    return undefined;
  }};

  gap: ${(props) => props.gutter || Spacing.m};
`;

export const nl2br = (text: string) => {
  return text.split(/\n/).map((item, i) => {
    return <p key={i}>{item}</p>;
  });
};
