import React from "react";
import { Row, Spacing, Column } from "../../helpers/layout";
import { css } from "styled-components";
import { Image } from "../../components/Image";
import { InHomeProduct } from "../../components/InHomeProduct";
import { Colors } from "../../branding";
import { Scrollbar } from "../../components/Scrollbar";
import CloseIcon from "../../icons/CloseIcon";
import { PlainButton } from "../../components/PlainButton";
import { InHome } from "../../types/InHome";
import { useResponsive } from "../../context/ResponsiveContext";
import InHomeBottomActions from "./InHomeBottomActions";

const ProductsList = ({
  products,
  isMainImageLandscape,
}: {
  products: InHome["products"];
  isMainImageLandscape?: boolean;
}) => {
  return (
    <Column
      css={css`
        margin-block: auto;
      `}
    >
      {products.map((connection) => (
        <div
          key={`in-home-product--${connection.id}`}
          css={css`
            width: 100%;
            padding: ${Spacing.m} 0;
            box-sizing: border-box;
            border-bottom: 1px solid ${Colors.light_grey};

            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
              border-bottom: none;
            }
          `}
        >
          <InHomeProduct
            product={connection.product}
            isMainImageLandscape={isMainImageLandscape}
          />
        </div>
      ))}
    </Column>
  );
};

export default function InHomeDesktopView(props: {
  inHome: InHome;
  onClose?: () => void;
}) {
  const { image, products } = props.inHome;
  const { is13inch } = useResponsive();
  const isMainImageLandscape = (image?.width || 0) >= (image?.height || 0);

  return (
    <div
      css={css`
        height: 100%;
      `}
    >
      <Row
        css={css`
          height: 100%;
        `}
        align="center"
      >
        <div
          css={css`
            flex: 1 0 10%;
            min-width: 0;
            display: flex;
            align-items: center;
            padding: ${Spacing.xl} 0 ${Spacing.xl} ${Spacing.xl};
          `}
        >
          <Image
            file={image}
            notResponsive
            style={{
              maxHeight: `calc(100vh - ${Spacing.xl} * 2)`,
              maxWidth: "100%",
              width: "auto",
              height: "auto",
              display: "block",
            }}
            width="large"
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${Spacing.l};
            height: 100%;
            padding: ${Spacing.xl};
            box-sizing: border-box;
            ${isMainImageLandscape
              ? `
              flex: 1;
              ${is13inch && `max-width: 400px;`}
            `
              : `
              flex: 0 0 800px;
              ${is13inch && "flex-basis: 600px;"}
            `}
          `}
        >
          {props.onClose ? (
            <div
              css={`
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
              `}
            >
              <PlainButton onClick={props.onClose} icon={<CloseIcon />} />
            </div>
          ) : null}
          {props.onClose ? (
            <div
              css={`
                flex: 1;
                .ScrollbarsCustom,
                .ScrollbarsCustom-Scroller {
                  padding-bottom: 0 !important;
                }
                .ScrollbarsCustom-Content {
                  display: flex;
                  flex-direction: column;
                }
              `}
            >
              <Scrollbar height="100%">
                <ProductsList
                  products={products}
                  isMainImageLandscape={isMainImageLandscape}
                />
              </Scrollbar>
            </div>
          ) : (
            <ProductsList
              products={products}
              isMainImageLandscape={isMainImageLandscape}
            />
          )}
          <InHomeBottomActions products={products} onClose={props.onClose} />
        </div>
      </Row>
    </div>
  );
}
