import React, { Ref } from "react";
import { Media } from "../types/Media";
import { CSSProperties } from "styled-components";
import { getImageUrl, Width, Height } from "../helpers/getImageUrl";

export const Image = React.forwardRef(
  (
    props: {
      file: Media | null;
      style?: CSSProperties | undefined;
      width?: Width;
      height?: Height;
      alt?: string;
      notResponsive?: boolean;
      className?: string;
    },
    ref: Ref<HTMLImageElement>
  ) => {
    const defaultSrc = getImageUrl(props.file, {
      width: props.width,
      height: props.height,
      devicePixelRatio: 1,
    });
    const mediumSrc = getImageUrl(props.file, {
      width: props.width,
      height: props.height,
      devicePixelRatio: 1.5,
    });
    const hqSrc = getImageUrl(props.file, {
      width: props.width,
      height: props.height,
      devicePixelRatio: 2,
    });

    const srcSet = `${defaultSrc} 1x, ${mediumSrc} 1.5x, ${hqSrc} 2x`;
    const fileName = props.file ? props.file.upload_name : "";

    return (
      <img
        ref={ref}
        key={defaultSrc}
        draggable={false}
        alt={props.alt || fileName}
        style={{
          maxWidth: "100%",
          ...props.style,
        }}
        data-srcset={props.notResponsive ? undefined : srcSet}
        data-src={defaultSrc}
        className={`lazyload ${props.className || ""}`}
      />
    );
  }
);
