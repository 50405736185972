import React from "react";
import { HelpLink } from "./HelpLink";
import { ItalicTitle } from "../typography";
import { css } from "styled-components";

export function ProductNote(props: { note: string; className?: string }) {
  return (
    <ItalicTitle
      className={props.className}
      css={css`
        position: relative;
        text-align: center;
      `}
    >
      <span
        css={css`
          margin-right: 10px;
        `}
      >
        {props.note}
      </span>
      <span
        css={css`
          position: relative;
          display: inline-block;
          width: 0;
          top: -7px;
          left: -2px;
        `}
      >
        <HelpLink identifier="limited-editions" hideLabel />
      </span>
    </ItalicTitle>
  );
}
