import React from "react";
import { ProductSize, Product } from "../types/Product";
import { Spacing, Column, Row } from "../helpers/layout";
import { css } from "styled-components";
import { PlainButton } from "./PlainButton";
import CheckIcon from "../icons/CheckIcon";
import { Price } from "./Price";
import { useResponsive } from "../context/ResponsiveContext";
import {
  getPiecePerRowNumber,
  getPiecesNumber,
  calculateOrderItem,
} from "../helpers/product";
import { useCommonData } from "../context/CommonDataContext";

function sizesEqual(size1: ProductSize, size2: ProductSize) {
  return size1.width === size2.width && size1.height === size2.height;
}

function PrintSizePreview(props: {
  product: Product;
  size: ProductSize;
  isSelected: boolean;
  onClick: () => void;
  isCustom?: boolean;
  disablePrices?: boolean;
}) {
  const { size, isSelected, product } = props;
  const { isMobile } = useResponsive();
  const { pairing_type, pairing_products } = product;

  const piecesPerRow = getPiecePerRowNumber(product);
  const pieces = getPiecesNumber(product);
  const isPhoto = product.type === "PHOTO";
  const isBundle = product.type === "PAIRING" && pairing_type === "BUNDLE";
  const isTych = product.type === "PAIRING" && pairing_type !== "BUNDLE";

  const modifier = isMobile ? 1.3 : 2;
  const width = size.width * modifier * piecesPerRow;
  const height = size.height * modifier * piecesPerRow;
  const { settings } = useCommonData();
  const calculated = calculateOrderItem(
    {
      product,
      size,
      options: [],
    },
    settings
  );

  const tile = (
    <div
      css={css`
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: ${(height / width) * 100}%;
        border: 1px solid #000;
        box-sizing: border-box;
        background: ${isSelected ? "#E6E6E6" : "transparent"};
      `}
    ></div>
  );

  const widthToShow =
    product.pairing_type === "BUNDLE" ? size.width : calculated.width;
  const heightToShow =
    product.pairing_type === "BUNDLE" ? size.height : calculated.height;

  return (
    <PlainButton
      onClick={() => {
        props.onClick();
      }}
      css={css`
        position: relative;
        text-align: center;
        min-width: 60px;
        display: flex;
        justify-content: center;
        margin: ${Spacing.m};
      `}
    >
      <div
        css={css`
          width: ${width}px;
          margin: 0 auto;
        `}
      >
        {isPhoto ? (
          tile
        ) : isBundle ? (
          <Row gutter={Spacing.xs} justify="center">
            {pairing_products.map((product) => {
              return (
                <div
                  key={`product-preview-bundle--${product.id}`}
                  css={css`
                    min-width: 0;
                    flex: 1 0 20%;
                  `}
                >
                  {tile}
                </div>
              );
            })}
          </Row>
        ) : isTych ? (
          <Row width="100%" gutter={Spacing.none} justify="center" wrap="wrap">
            {new Array(pieces).fill(null).map((_v, i) => {
              const margin = 5 / piecesPerRow;

              return (
                <div
                  key={`product-preview-pieces--${i}`}
                  css={css`
                    min-width: 0;
                    flex: 1 0 ${100 / piecesPerRow - 5}%;
                    margin-right: ${margin}%;
                    margin-bottom: ${margin}%;
                  `}
                >
                  {tile}
                </div>
              );
            })}
          </Row>
        ) : null}
      </div>

      {props.isCustom && (
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 20px;
            left: 0;
            bottom: -30px;

            ${width < 80 &&
            css`
              font-size: 9px;
              letter-spacing: 0.05em;
            `}
          `}
        >
          custom
        </div>
      )}
      {isSelected && (
        <span
          css={css`
            position: absolute;
            right: -7px;
            top: -7px;
          `}
        >
          <CheckIcon />
        </span>
      )}

      <div
        css={css`
          position: relative;
          margin-top: ${Spacing.m};
        `}
      >
        <Column gutter={Spacing.xs}>
          <h4>
            {widthToShow}" x {heightToShow}"
          </h4>
          {!props.disablePrices && (
            <strong>
              <Price price={size.price} />
            </strong>
          )}
        </Column>
      </div>
    </PlainButton>
  );
}

export function PrintSizes(props: {
  product: Product;
  sizes: ProductSize[];
  selected: ProductSize | null;
  onClick: (size: ProductSize) => void;
  disablePrices?: boolean;
}) {
  if (!props.sizes.length) return <p>No available sizes</p>;

  const isCustomSize = props.sizes.reduce((isCustom, size) => {
    if (props.selected && sizesEqual(props.selected, size)) {
      return false;
    }

    return isCustom;
  }, true);

  return (
    <div
      css={css`
        position: relative;
        padding-bottom: ${Spacing.m};
      `}
    >
      <Row
        width="100%"
        wrap="wrap"
        justify={props.sizes.length > 3 ? "space-around" : "center"}
        align="flex-end"
        gutter={props.sizes.length > 3 ? Spacing.l : Spacing.xxl}
      >
        {props.sizes.map((size, i) => {
          const isSelected = props.selected
            ? sizesEqual(props.selected, size)
            : false;

          return (
            <PrintSizePreview
              product={props.product}
              isSelected={isSelected}
              size={size}
              onClick={() => {
                props.onClick(size);
              }}
              key={i}
              disablePrices={props.disablePrices}
            />
          );
        })}
        {isCustomSize && props.selected && (
          <PrintSizePreview
            product={props.product}
            isSelected={true}
            size={props.selected}
            onClick={() => {}}
            isCustom={true}
            disablePrices={props.disablePrices}
          />
        )}
      </Row>
    </div>
  );
}
