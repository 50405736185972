import React from "react";
import { useState, useEffect } from "react";
import { css } from "styled-components";
import { useUserState } from "../context/UserContext";
import { Colors } from "../branding";
import { Spacing, Column } from "../helpers/layout";
import SignInForm from "../forms/SignInForm";
import SignUpForm from "../forms/SignUpForm";
import { Link } from "../components/Link";
import { Title, fontFamilies } from "../typography";
import { PlainButton } from "./PlainButton";
import { useResponsive } from "../context/ResponsiveContext";
import { hotjarVPV } from "../helpers/analytics";
import { useCommonData } from "../context/CommonDataContext";
import { useWishlist } from "../context/WishlistContext";
import { useComparison } from "../context/ComparisonContext";
import {
  MenuCountCircle,
  MenuIconWithText,
  StyledMenuIcon,
} from "./HeaderAccountNav";
import HeartFilledIcon from "../icons/HeartFilledIcon";

export function LoggedInForm() {
  const { logout } = useUserState();
  const { wishlist } = useWishlist();
  const { comparison } = useComparison();

  const menu = [
    {
      key: "account",
      label: "Account",
      url: "/my-account",
    },
    {
      key: "wishlist",
      label: "Wishlist",
      url: "/wishlist",
      children: (
        <MenuIconWithText>
          <div
            css={css`
              svg path {
                fill: ${Colors.black};
              }
            `}
          >
            <HeartFilledIcon />
          </div>
          <StyledMenuIcon style={{ color: Colors.white }}>
            {wishlist.count}
          </StyledMenuIcon>
        </MenuIconWithText>
      ),
    },
    {
      key: "compare",
      label: "Compare",
      url: "/compare",
      count: comparison.items.length,
      children: (
        <MenuCountCircle>
          <StyledMenuIcon>{comparison.items.length}</StyledMenuIcon>
        </MenuCountCircle>
      ),
    },
    {
      key: "my_orders",
      label: "Orders",
      url: "/orders",
    },
    {
      key: "logout",
      label: "Logout",
      onClick: () => {
        if (logout) {
          logout();
        }
      },
    },
  ];

  return (
    <div
      css={css`
        color: ${Colors.black};
        text-transform: uppercase;
        letter-spacing: 0.05em;
      `}
    >
      {menu.map((item, index) => {
        return (
          <div
            key={`menu-item--${index}`}
            css={css`
              padding: ${Spacing.l} 0;
              border-bottom: 1px solid ${Colors.light_grey};

              &:last-child {
                border-bottom: none;
              }
            `}
          >
            {item.url && <Link href={item.url}>{item.label}</Link>}
            {item.onClick && (
              <PlainButton onClick={item.onClick}>{item.label}</PlainButton>
            )}
            {item.children ? item.children : null}
          </div>
        );
      })}
    </div>
  );
}

export function getSignUpTexts(type?: string, reduced?: boolean) {
  if (type === "product-book") {
    return {
      title: "To Complete Your Purchase, Please:",
      to: "",
      points_title: "Additional Features You’ll Gain Access To:",
      points: [
        "Customize your print selection & create a wishlist",
        "Use our Curate Your Space tool to see how prints look in your space",
      ],
    };
  }
  if (type === "product") {
    if (reduced) {
      return {
        title: "TO VIEW PRICING",
        to: "",
        points_title: "",
        points: [],
      };
    }
    return {
      title: "TO VIEW PRICING",
      to: "",
      points_title: "Additional Features You’ll Gain Access To:",
      points: [
        "Customize your print selection & create a wishlist",
        "Use our Curate Your Space tool to see how prints look in your space",
      ],
    };
  }
  if (type === "interior-designer-buy") {
    if (reduced) {
      return {
        title: "TO COMPLETE YOUR DESIGN",
        to: "",
        points_title: "",
        points: [],
      };
    }
    return {
      title: "TO COMPLETE YOUR PURCHASE",
      to: "",
      points_title: "Additional Features You’ll Gain Access To:",
      points: [
        "Customize your print selection & create a wishlist",
        "Use our Curate Your Space tool to see how prints look in your space",
      ],
    };
  }

  if (type === "compare" && !reduced) {
    return {
      title: "ARE YOU COMPARING TWO OR MORE PRINTS?",
      to: "to View Pricing & Framing Options",
      points_title: "",
      points: [],
    };
  }

  if (type === "wishlist" && !reduced) {
    return {
      title: "TO CREATE A WISHLIST",
      to: "",
      points_title: "Additional Features You’ll Gain Access To:",
      points: [
        "Customize your print selection & create a wishlist",
        "Use our Curate Your Space tool to see how prints look in your space",
      ],
    };
  }

  if (type === "interior-designer") {
    if (reduced) {
      return {
        title: "GET INSPIRED",
        to: "to Create To-Scale Renderings",
        points_title: "",
        points: [],
      };
    }
    return {
      title: "GET INSPIRED",
      to: "to Create To-Scale Renderings and See Prices Adjust in Real-Time as You Design",
      points_title: "Additional Features You’ll Gain Access To:",
      points: [
        "Customize your print selection & create a wishlist",
        "Use our Curate Your Space tool to see how prints look in your space",
      ],
    };
  }

  if (reduced) {
    return {
      title: "GET INSPIRED",
      to: "to:",
      points_title: "",
      points: [],
    };
  }

  return {
    title: "GET INSPIRED",
    to: "to:",
    points_title: "",
    points: [
      "Customize your print selection & create a wishlist",
      "Use our Curate Your Space tool to see how prints look in your space",
    ],
  };
}

export function NotLoggedInForm(props: {
  onSignIn?: () => void;
  type?: string;
  signIn?: boolean;
}) {
  const [signInActive, setSignInActive] = useState(props.signIn || false);
  const { settings } = useCommonData();
  const { title, to, points_title, points } = getSignUpTexts(
    props.type,
    settings.gallery_focus
  );
  const { isMobile } = useResponsive();

  useEffect(() => {
    const pageUrl = props.type ? `/register/${props.type}` : `/register`;
    hotjarVPV(pageUrl);
  }, [props.type]);

  return (
    <div
      css={css`
        a {
          color: ${Colors.dark_gray};
        }
      `}
    >
      <div
        css={css`
          margin-bottom: ${Spacing.xl};
          text-align: center;

          ${isMobile &&
          css`
            margin-bottom: ${Spacing.m};
          `}
        `}
      >
        <Title
          css={css`
            text-transform: uppercase;
            letter-spacing: 0.05em;
          `}
        >
          {title}
        </Title>
        <div
          css={css`
            font-family: ${fontFamilies.freight};
            font-style: italic;
            font-size: 26px;
            margin: ${Spacing.l} 0;
            letter-spacing: 0.1em;

            ${isMobile &&
            css`
              font-size: 20px;
            `}

            a {
              cursor: pointer;
              color: ${Colors.black};
              margin: 0 ${Spacing.xs};
              position: relative;

              &:after {
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                background: ${Colors.black};
                content: "";
              }
            }
          `}
        >
          <PlainButton
            underlined
            active={signInActive}
            hoverEffect
            onClick={() => {
              setSignInActive(true);
            }}
          >
            Login
          </PlainButton>
          <div
            css={css`
              display: inline;
              vertical-align: middle;
              margin: 0 9px;
            `}
          >
            or
          </div>
          <PlainButton
            underlined
            active={!signInActive}
            hoverEffect
            onClick={() => {
              setSignInActive(false);
            }}
          >
            Sign Up
          </PlainButton>
          <div
            css={css`
              display: inline;
              vertical-align: middle;
              margin-left: 9px;
            `}
          >
            {to}
          </div>
        </div>
        {points_title && (
          <div
            css={css`
              margin-bottom: ${Spacing.m};
              letter-spacing: 0.05em;
            `}
          >
            {points_title}
          </div>
        )}
        <Column gutter={Spacing.s}>
          {points.map((point) => {
            return (
              <div
                key={`points--${point}`}
                css={css`
                  letter-spacing: 0.05em;
                `}
              >
                &bull; {point}
              </div>
            );
          })}
        </Column>
      </div>
      {signInActive ? (
        <SignInForm onSignIn={props.onSignIn} />
      ) : (
        <SignUpForm onSignUp={props.onSignIn} />
      )}
    </div>
  );
}
