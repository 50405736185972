import React, { useRef, useEffect, useState } from "react";
import { BookOption, Product, ProductSize } from "../types/Product";
import { getOptionSize, getPiecePerRowNumber } from "../helpers/product";
import { ProductOption } from "../types/ProductOption";
import { css } from "styled-components";
import { Button } from "./Button";
import { Image } from "./Image";
import { getImageUrl, Width } from "../helpers/getImageUrl";
import { GlassMagnifier, TOUCH_ACTIVATION } from "react-image-magnifiers";
import { Spacing, Row, Column } from "../helpers/layout";
import { getPiecesNumber } from "../helpers/product";
import { CompareButton } from "./CompareButton";
import { AddToWishlistButton } from "./AddToWishlistButton";
import { Colors } from "../branding";
import { H5 } from "../typography";
import Router from "next/router";
import AwardIcon from "../icons/AwardIcon";
import BestsellingIcon from "../icons/BestsellingIcon";
import InMuseumIcon from "../icons/InMuseumIcon";
import { useCommonData } from "../context/CommonDataContext";

function Magnifier(props: { imageUrl: string }) {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .magnifier > img {
          opacity: 0;
          visibility: hidden;
        }
      `}
    >
      <GlassMagnifier
        imageSrc={props.imageUrl}
        touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
        magnifierSize="250px"
        square={true}
        allowOverflow={true}
        className="magnifier"
      />
    </div>
  );
}

function ProductImage(props: {
  product: Pick<Product, "id" | "featured_image">;
  previewWidth?: Width;
  children: React.ReactNode;
}) {
  const { product, previewWidth } = props;
  const image = product.featured_image;
  const imageUrl = image
    ? getImageUrl(image, {
        width: previewWidth || 450,
      })
    : "";
  const paddingBottom = image
    ? ((image.height / image.width) * 100).toFixed(2)
    : "56.25";

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: ${paddingBottom}%;
        background-image: url(${imageUrl});
        background-size: cover;
        background-position: center;
        overflow: hidden;
      `}
    >
      {props.children}
    </div>
  );
}

function PrintTile(props: {
  product: Pick<Product, "id" | "featured_image" | "pricing">;
  piece?: number;
  pieces?: number;
  piecesPerRow?: number;
  printSize: ProductSize;
  previewWidth?: Width;
  options?: ProductOption[];
  enableZoom?: boolean;
  darkBackground?: boolean;
  children?: React.ReactNode;
}) {
  const { printSize, product, enableZoom, darkBackground } = props;

  if (!printSize) {
    return null;
  }

  const options = props.options || [];
  const piecesPerRow = props.piecesPerRow || 1;
  const pieces = props.pieces || 1;
  const piece = props.piece || 0;
  const piecesPerColumn = pieces / piecesPerRow;

  const frame = options.find((option) => option.type === "FRAME");
  const border = options.find((option) => option.type === "BORDER");

  const shadow = css`
    box-shadow: 2px 2px 4px 1px ${darkBackground ? "#2e2e2e" : "#b5b5b5"},
      0 -1px 1px rgba(0, 0, 0, ${darkBackground ? 0.2 : 0.1});
  `;

  const frameSize = frame
    ? getOptionSize(product.pricing, printSize, frame)
    : null;
  const borderSize = border
    ? getOptionSize(product.pricing, printSize, border)
    : null;
  const borderColor =
    frame &&
    frame.name === "Black frame" &&
    borderSize &&
    borderSize.width === 0
      ? "#4d4d4d"
      : "#e3e3e4";

  const borderWidth = borderSize ? borderSize.width : 0;
  const frameWidth = frameSize ? frameSize.width : 0;
  const fullWidth = printSize.width + 2 * borderWidth + 2 * frameWidth;
  const paddingBottom = (printSize.height / printSize.width) * 100;
  const leftIndex = piece % piecesPerRow;
  const topIndex = Math.floor(piece / piecesPerRow);
  const imageUrl = product.featured_image
    ? getImageUrl(product.featured_image, {
        width: props.previewWidth || 450,
      })
    : "";
  const zoomUrl = product.featured_image
    ? getImageUrl(product.featured_image, {
        width: "large",
      })
    : "";

  return (
    <div css={frame && frame.name !== "Unframed" ? shadow : ""}>
      <div
        css={css`
          background: ${frame ? frame.config.color : "#fff"};
          padding: ${(frameWidth / fullWidth) * 100}%;
          box-sizing: border-box;
        `}
      >
        <div
          css={css`
            position: relative;
            padding: ${(borderWidth / fullWidth) * 100}%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f7f6fb;
            box-shadow: inset 2px 1px 1px #c5c7c7, inset -1px -2px 1px #fff;
            box-sizing: border-box;
          `}
        >
          <div
            css={css`
              position: relative;
              width: 100%;
              height: 0;
              padding-bottom: ${paddingBottom}%;
              background-image: url(${imageUrl});

              ${piecesPerRow > 1 || piecesPerColumn > 1
                ? css`
                    background-size: ${piecesPerRow * 100}%
                      ${piecesPerColumn * 100}%;
                    background-position: -${leftIndex * 100}% -${topIndex *
                      100}%;
                  `
                : css`
                    background-size: cover;
                    background-position: center;
                  `}
            `}
          >
            <div
              css={css`
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: ${frame && frame.name !== "Plexi" && border
                  ? `2px solid ${borderColor}`
                  : "none"};
                box-sizing: border-box;
                pointer-events: none;

                ${border &&
                border.name.match(/lift/i) &&
                css`
                  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
                `}
              `}
            />
            {props.children}
          </div>

          {enableZoom && <Magnifier imageUrl={zoomUrl} />}
        </div>
      </div>
    </div>
  );
}

function IconLabel(props: { icon: JSX.Element; label: string }) {
  return (
    <div
      css={css`
        display: inline-block;
        vertical-align: middle;
        margin-right: ${Spacing.l};

        &:last-child {
          margin-right: 0;
        }
      `}
    >
      <div
        css={css`
          display: inline-block;
          vertical-align: middle;
          margin-right: ${Spacing.s};

          svg {
            width: auto;
            height: 12px;
          }
        `}
      >
        {props.icon}
      </div>
      <div
        css={css`
          display: inline-block;
          vertical-align: middle;
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.1em;
        `}
      >
        {props.label}
      </div>
    </div>
  );
}

type ProductPreviewProps = {
  product: Pick<
    Product,
    | "id"
    | "featured_image"
    | "pricing"
    | "type"
    | "slug"
    | "pairing_type"
    | "pairing_products"
    | "sizes"
    | "alt"
    | "in_museum"
    | "bestselling"
    | "awards_count"
    | "book_options"
  >;
  bookOption?: BookOption;
  printSize?: ProductSize | null;
  previewWidth?: Width;
  options?: ProductOption[];
  onZoom?: () => void;
  enableZoom?: boolean;
  darkBackground?: boolean;
  enableOverlay?: boolean;
  onClick?: () => void;
  onQuickShop?: () => void;
};

export function ProductPreview({
  product,
  options,
  enableZoom,
  darkBackground,
  onClick,
  onQuickShop,
  printSize: printSizeProp,
  previewWidth,
  onZoom,
  enableOverlay,
}: ProductPreviewProps) {
  const { settings } = useCommonData();
  const ref = useRef<HTMLDivElement>(null);
  const [isSmall, setIsSmall] = useState(false);
  const { pairing_type, pairing_products } = product;
  const printSize = printSizeProp || product.sizes[0];
  const piecesPerRow = getPiecePerRowNumber(product);
  const pieces = getPiecesNumber(product);
  const zoomUrl = product.featured_image
    ? getImageUrl(product.featured_image, {
        width: "large",
      })
    : "";

  const isPhoto = product.type === "PHOTO";
  const isBundle = product.type === "PAIRING" && pairing_type === "BUNDLE";
  const isTych = product.type === "PAIRING" && pairing_type !== "BUNDLE";
  const isBook = product.type === "BOOK";

  useEffect(() => {
    if (!ref.current) return;

    const { height } = ref.current.getBoundingClientRect();

    if (height < 230) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="product-preview"
      css={css`
        position: relative;

        .overlay {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s;
        }

        &:hover {
          .overlay {
            opacity: 1;
            visibility: visible;
          }
        }
      `}
      onClick={onClick}
    >
      {isPhoto ? (
        <PrintTile
          product={product}
          options={options}
          printSize={printSize}
          enableZoom={enableZoom}
          darkBackground={darkBackground}
          previewWidth={previewWidth}
        />
      ) : isBundle ? (
        <Row gutter={Spacing.xs}>
          {pairing_products.map((product) => {
            return (
              <div
                key={`product-preview-bundle--${product.id}`}
                css={css`
                  min-width: 0;
                  flex: 1 0 20%;
                `}
              >
                <PrintTile
                  product={product}
                  key={`product-preview--${product.id}`}
                  options={options}
                  printSize={printSize}
                  enableZoom={enableZoom}
                  darkBackground={darkBackground}
                  previewWidth={previewWidth}
                >
                  <Image
                    file={product.featured_image}
                    width={previewWidth || 500}
                    alt={product.alt}
                  />
                </PrintTile>
              </div>
            );
          })}
        </Row>
      ) : isTych ? (
        <Row gutter={Spacing.xs} wrap="wrap">
          {new Array(pieces).fill(null).map((_v, i) => {
            return (
              <div
                key={`product-preview-pieces--${i}`}
                css={css`
                  min-width: 0;
                  flex: 1 0 ${100 / piecesPerRow - 5}%;
                  margin-bottom: ${Spacing.xs};
                `}
              >
                <PrintTile
                  key={`product-preview--${i}`}
                  product={product}
                  options={options}
                  printSize={printSize}
                  piece={i}
                  pieces={pieces}
                  piecesPerRow={piecesPerRow}
                  darkBackground={darkBackground}
                  previewWidth={previewWidth}
                />
              </div>
            );
          })}
        </Row>
      ) : (
        // Books
        <ProductImage product={product} previewWidth={previewWidth || 500}>
          <Image
            file={product.featured_image}
            width={previewWidth || 500}
            alt={product.alt}
            css={css`
              width: 100%;
              visibility: hidden;
            `}
          />
        </ProductImage>
      )}
      {enableZoom && isTych && <Magnifier imageUrl={zoomUrl} />}
      {onZoom && (
        <div
          className="overlay"
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-content: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
          `}
        >
          <div
            css={css`
              width: auto;
              display: inline-block;
            `}
          >
            <Button
              label="Enlarge"
              type="button"
              onClick={() => {
                onZoom!();
              }}
            />
          </div>
        </div>
      )}
      {enableOverlay && (
        <div
          className="overlay"
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-content: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            background: rgba(0, 0, 0, 0.7);
            color: ${Colors.white};

            svg path {
              fill: ${Colors.white} !important;
            }
          `}
        >
          <Column align="center" gutter={isSmall ? Spacing.m : Spacing.l}>
            <div>
              <H5>Signed Limited Edition</H5>
              <H5>Fine Art Prints</H5>
            </div>

            <Row justify="center" wrap="wrap">
              <Button
                label="View Pricing"
                style="white-border"
                type="button"
                onClick={() => {
                  Router.push("/shop/[slug]", `/shop/${product.slug}`);
                }}
              />

              {!!onQuickShop && !isBook && (
                <Button
                  label="Quick View"
                  style="white-border"
                  type="button"
                  onClick={() => {
                    onQuickShop?.();
                  }}
                />
              )}
            </Row>

            {(product.awards_count > 0 ||
              product.bestselling ||
              product.in_museum) && (
              <div>
                {product.awards_count > 0 && (
                  <IconLabel icon={<AwardIcon />} label="Award Winner" />
                )}
                {!settings.gallery_focus && product.bestselling && (
                  <IconLabel icon={<BestsellingIcon />} label="Best Seller" />
                )}
                {product.in_museum && (
                  <IconLabel
                    icon={<InMuseumIcon />}
                    label="Museum Acquisition"
                  />
                )}
              </div>
            )}
            {!settings.gallery_focus && (
              <Row
                align="center"
                justify="center"
                gutter={Spacing.l}
                wrap="wrap"
                css={css`
                  font-size: 11px;
                  padding-inline: 5px;
                  box-sizing: border-box;
                  row-gap: 5px;
                `}
              >
                <CompareButton
                  productId={product.id}
                  bookOptions={product.book_options}
                />
                <AddToWishlistButton
                  productId={product.id}
                  bookOptions={product.book_options}
                />
              </Row>
            )}
          </Column>
        </div>
      )}
    </div>
  );
}
