import React, { useState, useEffect, useRef, useCallback } from "react";
import { SearchFilters } from "./SearchFilters";
import { Spacing } from "../helpers/layout";
import { css } from "styled-components";
import { Product } from "../types/Product";
import { ProductTag } from "../types/ProductTag";
import { notAuthorised } from "../request";
import { Layout } from "../helpers/filters";
import { useReachedEnd } from "../helpers/scroll";
import { useFilters } from "../helpers/useFilters";
import { NoSearchResults } from "./NoSearchResults";

type SearchQuery = {
  count: number;
  next: string;
  results: Product[];
};

export function InfiniteProducts(props: {
  tags: ProductTag[];
  background?: "white" | "gray";
  serie?: string;
  query?: string;
  arrowsInside?: boolean;
  hideTitle?: boolean;
  isSticky?: boolean;
  urlFilters?: boolean;
  filtersInWrapper?: boolean;
  currentLayout?: Layout;
  onLayoutChange?: (layout: Layout) => void;
  children: (results: Product[]) => React.ReactNode;
  stickyOffset?: number;
  scrollContainer?: HTMLDivElement | null;
  skipPairings?: boolean;
}) {
  const { serie, query, skipPairings } = props;
  const loading = useRef(false);
  const hasNextPage = useRef(true);
  const page = useRef(1);
  const [results, setResults] = useState<Product[]>([]);
  const { filters, setFilters } = useFilters("SEARCH", props.urlFilters);

  const getResults = useCallback(async () => {
    if (!hasNextPage.current || loading.current) return;

    loading.current = true;

    try {
      const args = [`page=${page.current}`];

      if (filters.length > 0) {
        args.push(`filters=${filters.join(",")}`);
      }

      if (serie) {
        args.push(`serie=${serie}`);
      }

      if (query) {
        args.push(`search=${query}`);
      }

      if (skipPairings) {
        args.push("skip_pairings=1");
      }

      const request = await notAuthorised.request<SearchQuery>({
        method: "GET",
        url: `/api/frontend/products/?${args.join("&")}`,
      });

      setResults((results) => {
        return [...results, ...request.data.results];
      });

      hasNextPage.current = !!request.data.next;
      page.current = page.current + 1;
    } catch (e) {
      hasNextPage.current = false;
    } finally {
      loading.current = false;
    }
  }, [filters, query, serie, skipPairings]);

  useEffect(() => {
    hasNextPage.current = true;
    page.current = 1;

    setResults([]);
    getResults();
  }, [getResults]);

  useReachedEnd(
    () => {
      getResults();
    },
    [filters, serie, query],
    props.scrollContainer
  );

  return (
    <div>
      <SearchFilters
        showTitle={!props.hideTitle}
        showLayout={!!props.onLayoutChange}
        onLayoutChange={props.onLayoutChange}
        currentLayout={props.currentLayout}
        background={props.background || "gray"}
        arrowsInside={props.arrowsInside}
        tags={props.tags}
        currentFilters={filters}
        onFiltersChange={(filters) => {
          setFilters(filters);
        }}
        isSticky={props.isSticky}
        inWrapper={props.filtersInWrapper}
        stickyOffset={props.stickyOffset}
      />
      <div
        css={css`
          padding: ${Spacing.xl} 0;
        `}
      >
        {results.length > 0 ? (
          props.children(results)
        ) : (
          <NoSearchResults
            onClear={() => {
              setFilters([]);
            }}
          />
        )}
      </div>
    </div>
  );
}
