import React, { useState } from "react";
import { Button, LinkButton } from "./Button";
import { TotalPrice, TotalLabel, fontFamilies } from "../typography";
import { AffirmPromo } from "./AffirmPromo";
import { Price } from "./Price";
import { css } from "styled-components";
import { Column, Row, RowProps, Spacing } from "../helpers/layout";
import { BookOption, Product } from "../types/Product";
import { useResponsive } from "../context/ResponsiveContext";
import { useUserState } from "../context/UserContext";
import { Link } from "./Link";
import { Colors } from "../branding";
import { PlainButton } from "./PlainButton";
import { useModal } from "../context/ModalContext";
import { AddToWishlistButton } from "./AddToWishlistButton";
import { CompareButton } from "./CompareButton";
import { useCommonData } from "../context/CommonDataContext";
import { getInquiryLink } from "../helpers/product";
import { PlainSelect } from "../forms/SelectField";

const Layout = ({
  children,
  ...rest
}: { children: React.ReactNode } & RowProps) => {
  const { isMobile } = useResponsive();
  const Wrapper = isMobile ? Column : Row;
  return <Wrapper {...rest}>{children}</Wrapper>;
};

const PRODUCTS_WITH_NOTE =
  process.env.NODE_ENV === "development"
    ? [
        708, // WILD: THE LEGENDARY HORSES OF SABLE ISLAND - STANDARD EDITION | UNSIGNED
      ]
    : [
        941, // Untamed Spirits: Horses From Around The World
        940, // Untamed Spirits: Horses From Around the World - Special Edition
        944, // Untamed Spirits: Horses From Around the World - The Artist's Edition
        943, // Untamed Spirits: Horses From Around the World
        942, // Untamed Spirits: Horses from Around the World - Special Edition
      ];

const InfoNote = ({ product }: { product: Product }) =>
  PRODUCTS_WITH_NOTE.includes(product.id) ? (
    <p
      css={`
        font-size: 16px;
        font-family: ${fontFamilies.freight};
      `}
    >
      <em>
        The special edition clamshell, folio, and prints were designed in-house
        by Drew and his team and produced by our longstanding partner, Brilliant
        Graphics. As such, neither teNeues Verlag GmbH nor teNeues Publishing
        were responsible for the production, product quality, or any customer
        complaints, and do not accept any liability.
      </em>
    </p>
  ) : null;

export function ProductDetailFooter(props: {
  product: Product;
  total: string;
  onAddToCart: (opt: BookOption | null) => Promise<void>;
  onAddToWishlist?: (opt: BookOption | null) => Promise<void>;
  onAddToComparison?: (opt: BookOption | null) => Promise<void>;
}) {
  const { product } = props;
  const { isMobile } = useResponsive();
  const { showModal } = useModal();
  const [selectState, setSelectState] = useState(
    product.book_options?.[0]?.name || ""
  );
  const [bookOption, setBookOption] = useState<BookOption | null>(
    product.book_options?.[0] || null
  );
  const isPhotoProduct = product.type === "PHOTO" || product.type === "PAIRING";
  const isBookProduct = product.type === "BOOK";
  const { settings } = useCommonData();

  const { isAuthenticated, userprofile } = useUserState();

  const openLoginModal = () => {
    const type = product.type === "BOOK" ? "product-book" : "product";
    showModal("login", { type });
  };

  let addToCartButton = null;

  if (settings.gallery_focus && !isBookProduct) {
    if (isAuthenticated) {
      addToCartButton = (
        <LinkButton
          external
          pure
          href={getInquiryLink(product.name, userprofile?.user.first_name)}
          label="Inquire"
        />
      );
    } else {
      addToCartButton = (
        <Button
          type="button"
          label="View Pricing"
          onClick={() => {
            openLoginModal();
          }}
        />
      );
    }
  } else {
    addToCartButton = (
      <Button
        type="button"
        successText={isAuthenticated ? "Added" : undefined}
        label={product.sold_out ? "Sold out" : "Add to Cart"}
        disabled={product.sold_out || !Number(bookOption?.price || props.total)}
        onClick={() => {
          if (isAuthenticated) {
            props.onAddToCart(bookOption);
          } else {
            openLoginModal();
          }
        }}
      />
    );
  }

  // Don't show pricing in Prints for non-authenticated users
  if (!isAuthenticated && isPhotoProduct) {
    return (
      <div
        css={css`
          width: 100%;
          box-sizing: border-box;
          padding: ${Spacing.l};
          background: ${Colors.very_light_grey};

          ${!isMobile &&
          css`
            position: sticky;
            bottom: ${Spacing.m};
          `}
        `}
      >
        {isMobile ? (
          <Row align="center" justify="center">
            <Button
              type="button"
              label="View Pricing"
              onClick={() => {
                openLoginModal();
              }}
            />
            {!settings.gallery_focus && props.onAddToComparison && (
              <Button
                type="button"
                style="light-grey"
                label="Compare"
                onClick={() => props.onAddToComparison!(bookOption)}
              />
            )}
          </Row>
        ) : (
          <Row align="center">
            <div
              css={css`
                flex: 1 0 10%;
                font-size: 16px;
                letter-spacing: 0.06em;

                button {
                  margin-left: 2px;
                  letter-spacing: inherit;
                }
              `}
            >
              To view pricing, please{" "}
              <PlainButton
                hoverEffect
                underlined
                onClick={() => {
                  openLoginModal();
                }}
              >
                Login / Register
              </PlainButton>
            </div>
            {!settings.gallery_focus && props.onAddToComparison && (
              <div
                css={css`
                  flex: 0 0 auto;
                `}
              >
                <Row>
                  <Button
                    type="button"
                    label="Add to Cart"
                    style="light-grey"
                    onClick={() => {
                      openLoginModal();
                    }}
                  />
                  <Button
                    type="button"
                    label="Compare"
                    onClick={() => props.onAddToComparison!(bookOption)}
                  />
                </Row>
              </div>
            )}
          </Row>
        )}
      </div>
    );
  }

  const bookOptionSelect =
    product.book_options && product.book_options.length > 1 ? (
      <Row>
        <div
          css={css`
            flex: 1;
          `}
        >
          <TotalLabel>Select Option:</TotalLabel>
          <PlainSelect
            placeholder="Select Option"
            value={selectState}
            onChange={(state) => {
              setSelectState(state);
              setBookOption(
                product.book_options?.find((option) => option.name === state) ||
                  null
              );
            }}
            options={
              product.book_options?.map((option) => ({
                label: option.name,
                value: option.name,
              })) || []
            }
          />
        </div>
      </Row>
    ) : null;

  const priceContent = (
    <>
      <Row align="center">
        <TotalLabel>Total: </TotalLabel>
        <TotalPrice>
          <Price price={bookOption?.price || props.total} />
        </TotalPrice>
      </Row>
      {!settings.gallery_focus ? (
        <AffirmPromo price={bookOption?.price || props.total} />
      ) : null}
    </>
  );

  const leftCol = (
    <div
      css={css`
        flex: 1 0 10%;
      `}
    >
      <Column>
        {isMobile ? bookOptionSelect : null}
        {!bookOptionSelect ? priceContent : null}
      </Column>
    </div>
  );

  return (
    <Layout align="center" wrap="wrap" gutter={Spacing.m}>
      {!bookOptionSelect ? leftCol : null}
      <div
        css={css`
          flex: 0 0 auto;
        `}
      >
        <Column>
          {bookOptionSelect}
          {bookOptionSelect ? priceContent : null}
          <div>
            <Row>
              <div>{addToCartButton}</div>
              {!settings.gallery_focus &&
                !isMobile &&
                !product.sold_out &&
                props.onAddToWishlist && (
                  <Button
                    successText={isAuthenticated ? "Added" : undefined}
                    type="button"
                    label="Add to Wishlist"
                    onClick={() => {
                      if (isAuthenticated) {
                        props.onAddToWishlist!(bookOption);
                      } else {
                        openLoginModal();
                      }
                    }}
                  />
                )}
              {!settings.gallery_focus &&
                !isMobile &&
                !product.sold_out &&
                props.onAddToComparison && (
                  <Button
                    successText={isAuthenticated ? "Added" : undefined}
                    type="button"
                    label="Compare"
                    onClick={() => {
                      if (isAuthenticated) {
                        props.onAddToComparison!(bookOption);
                      } else {
                        openLoginModal();
                      }
                    }}
                  />
                )}
            </Row>
          </div>
          {!settings.gallery_focus && isMobile && !product.sold_out && (
            <div>
              <Row align="center">
                {props.onAddToWishlist && (
                  <AddToWishlistButton
                    productId={product.id}
                    bookOptions={product.book_options}
                  />
                )}
                {props.onAddToComparison && (
                  <CompareButton
                    productId={product.id}
                    bookOptions={product.book_options}
                  />
                )}
              </Row>
            </div>
          )}

          {product.sold_out && (
            <div
              css={css`
                text-align: center;
              `}
            >
              <Link href="mailto:gallery@drewdoggett.com" pure underlined>
                Contact us
              </Link>
            </div>
          )}
        </Column>
      </div>
      <InfoNote product={product} />
    </Layout>
  );
}
