import React from "react";
import { Image } from "./Image";
import * as types from "../types/Product";
import { Column, Spacing, Row } from "../helpers/layout";
import { css } from "styled-components";
import { Colors } from "../branding";
import { useResponsive } from "../context/ResponsiveContext";

export function ProductAward(props: {
  award: types.ProductAward;
  showTitle?: boolean;
}) {
  const { award, showTitle } = props;

  const { isMobile } = useResponsive();

  return (
    <div>
      {award.rewarder && (
        <div
          css={css`
            width: 50px;
            display: inline-block;
            vertical-align: middle;
          `}
        >
          <Image file={award.rewarder.logo} width={50} />
        </div>
      )}
      {award.reward && (!isMobile || showTitle) && (
        <div
          css={css`
            max-width: 100px;
            letter-spacing: 0.05em;
            margin-left: ${Spacing.m};
            display: inline-block;
            vertical-align: middle;
          `}
        >
          {award.reward}
        </div>
      )}
    </div>
  );
}

export function ProductAwards(props: { product: types.Product }) {
  const { product } = props;
  const { isMobile } = useResponsive();

  const Layout = isMobile ? Row : Column;
  const awards = product.awards
    .sort((a, b) => {
      if (a.reward.length > b.reward.length) {
        return -1;
      }

      if (a.reward.length < b.reward.length) {
        return 1;
      }

      return 0;
    })
    .slice(0, 1);
  const deltaAwards = product.awards.length - awards.length;

  return (
    <Layout gutter={Spacing.s}>
      {awards.map((award) => {
        return (
          <ProductAward award={award} key={`product-awards--${award.id}`} />
        );
      })}
      {deltaAwards > 0 && (
        <div>
          <Row align="center">
            <div
              css={css`
                width: 50px;
                height: 50px;
                background: ${Colors.very_light_grey};
                font-size: 14px;
                line-height: 50px;
                text-align: center;
              `}
            >
              + {deltaAwards}
            </div>
            <div>more</div>
          </Row>
        </div>
      )}
    </Layout>
  );
}
