import React, { useEffect } from "react";
import { Product, ProductSize } from "../types/Product";
import { ProductOption, OptionComponent } from "../types/ProductOption";
import { Row, Column, Spacing } from "../helpers/layout";
import {
  calculateOptionPrice,
  getOptionSize,
  isOptionAvailable,
  OptionTypeOrder,
  calculateOptionDiscount,
  getPiecesNumber,
} from "../helpers/product";
import { Section } from "../typography";
import sortBy from "lodash/sortBy";
import { HelpLink } from "./HelpLink";
import { PrintOptionPreview } from "./PrintOptionPreview";
import { css } from "styled-components";
import { useCommonData } from "../context/CommonDataContext";
import { useResponsive } from "../context/ResponsiveContext";

type Option = {
  option: ProductOption;
  price: number;
  discount: number;
  isSelected: boolean;
};

type OptionGroup = {
  name: string;
  type: OptionComponent;
  options: Array<Option>;
};

function getProductOptionGroups(options: Option[]) {
  const groups = options.reduce((groups: OptionGroup[], item) => {
    const groupExists = groups.find((group) => group.type === item.option.type);

    if (groupExists) {
      return groups.map((group) => {
        if (group.type === item.option.type) {
          return {
            ...group,
            options: [...group.options, item],
          };
        }

        return group;
      });
    }

    return [
      ...groups,
      {
        name: item.option.type,
        type: item.option.type,
        options: [item],
      },
    ];
  }, []);

  return groups.map((group) => {
    const options = group.options.sort((a, b) => {
      if (a.price < b.price) {
        return -1;
      }

      if (a.price > b.price) {
        return 1;
      }

      return 0;
    });

    return {
      ...group,
      options,
    };
  });
}

export function PrintOptions(props: {
  product: Product;
  size: ProductSize;
  selected: ProductOption[];
  onSelect: (option: ProductOption) => void;
  disablePrices?: boolean;
  initialIndex: number;
}) {
  const { product } = props;
  const { settings } = useCommonData();
  const { isMobile } = useResponsive();
  const numberOfItems = getPiecesNumber(product);

  const productOptions = product.options
    .filter((option) => {
      const isAvailable = isOptionAvailable(option, props.selected);

      return isAvailable && getOptionSize(product.pricing, props.size, option);
    })
    .map((option) => {
      const price = calculateOptionPrice(
        product.pricing,
        props.size,
        option,
        props.selected
      );
      const discount = calculateOptionDiscount(
        product.pricing,
        props.size,
        option,
        props.selected,
        settings
      );

      return {
        option,
        price: numberOfItems * price,
        discount: numberOfItems * discount,
        isSelected: !!props.selected.find((o) => o.id === option.id),
      };
    });

  const groups = getProductOptionGroups(productOptions);

  /** Auto-select first option in a group */
  useEffect(() => {
    const noSelection = groups.find((group) => {
      const selectedOption = props.selected.find(
        (option) => option.type === group.type
      );

      return !selectedOption;
    });

    if (noSelection) {
      const firstOption = noSelection.options[0];

      if (firstOption) {
        props.onSelect(firstOption.option);
      }
    }
  }, [groups]);

  if (!productOptions.length) {
    return null;
  }

  const orderedGroups = sortBy(groups, (group) => {
    return OptionTypeOrder[group.type] || OptionTypeOrder.Default;
  });

  return (
    <React.Fragment>
      {orderedGroups.map((group, index) => {
        const hasAvailableOptions = group.options.length > 0;

        return (
          <div
            css={css`
              max-height: ${hasAvailableOptions ? "200px" : 0};
              opacity: ${hasAvailableOptions ? 1 : 0};
              transition: all 400ms;
              overflow: hidden;
            `}
            key={`option-group--${group.name}`}
          >
            <Column>
              <Row wrap="wrap" justify="space-between">
                <div
                  css={css`
                    flex: 1 0 40%;
                  `}
                >
                  <Section number={props.initialIndex + index + 1}>
                    Choose a {group.name.toLowerCase()}
                  </Section>
                </div>
                <div
                  css={css`
                    flex: 0 0 auto;
                  `}
                >
                  <HelpLink
                    identifier={`product_option_${group.type.toLowerCase()}`}
                    hideLabel={isMobile}
                  />
                </div>
              </Row>
              <div>
                <Row gutter={Spacing.l}>
                  {group.options.map((item) => {
                    return (
                      <PrintOptionPreview
                        key={`print-option--${item.option.id}`}
                        option={item.option}
                        isSelected={item.isSelected}
                        price={item.price}
                        disablePrices={props.disablePrices}
                        discount={item.discount}
                        onClick={() => {
                          props.onSelect(item.option);
                        }}
                      />
                    );
                  })}
                </Row>
              </div>
            </Column>
          </div>
        );
      })}
    </React.Fragment>
  );
}
