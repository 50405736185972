import React from "react";
import { css } from "styled-components";
import NextLink from "next/link";
import { MenuItem } from "../types/Menu";
import { Colors } from "../branding";

function getHref(href: string) {
  const testHref = href.split(/[?#]/)[0];
  const staticPages = [
    "cart",
    "compare",
    "in-homes",
    "login",
    "orders",
    "register",
    "wishlist",
    "my-account",
    "reset-password",
    "search",
    "shop",
    "blog",
    "",
  ];

  for (let i = 0; i < staticPages.length; i++) {
    if (testHref.match(new RegExp(`^/${staticPages[i]}$`))) {
      return `/${staticPages[i]}`;
    }
  }

  if (testHref.match(/^\/series\/([a-zA-Z0-9_-]*)\/shop/)) {
    return "/series/[slug]/shop";
  }

  if (testHref.match(/^\/series\/([a-zA-Z0-9_-]*)/)) {
    return "/series/[slug]";
  }

  if (testHref.match(/^\/blog\/category/)) {
    return "/blog/category/[slug]";
  }

  if (testHref.match(/^\/blog/)) {
    return "/blog/[slug]";
  }

  if (testHref.match(/^\/checkout\/[0-9]/)) {
    return "/checkout/[id]";
  }

  if (testHref.match(/^\/shop\//)) {
    return "/shop/[slug]";
  }

  if (testHref.match(/^\/([a-zA-Z0-9_-]*)$/)) {
    return "/[slug]";
  }

  return href;
}

export const underlineStyle = css`
  &:before {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${Colors.light_grey};
    content: "";
  }
`;

export const activeStyle = css`
  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    transition: width 300ms;
    content: "";
  }
`;

export const hoverStyle = css`
  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0px;
    height: 1px;
    background: currentColor;
    transition: width 300ms;
    content: "";
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

const active = css`
  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    transition: width 300ms;
    content: "";
  }
`;

export function getLinkParams(url: string, external?: boolean) {
  const href = getHref(url);
  const as = external ? undefined : url;

  return {
    href,
    as,
  };
}

export function getUrl(item: MenuItem) {
  if (item.type === "serie" && item.serie) {
    return `/series/${item.serie.slug}`;
  }

  if (item.type === "link") {
    return item.url;
  }

  return null;
}

export function Link(props: {
  href: string;
  children: React.ReactNode;
  external?: boolean;
  className?: string;
  noEffect?: boolean;
  underlined?: boolean;
  isActive?: boolean;
  disable?: boolean;
  pure?: boolean;
  onClick?: () => void;
  target?: "_blank" | "_self" | "_parent";
}) {
  if (!props.href) {
    return (
      <span onClick={props.onClick} className={props.className}>
        {props.children}
      </span>
    );
  }

  const { href, as } = getLinkParams(props.href, props.external);
  const target = props.target || (props.external ? "_blank" : "_self");

  const styles = css`
    position: relative;
    text-decoration: none;
    color: inherit;
    display: inline-block;

    ${props.underlined && underlineStyle}
    ${!props.noEffect && hoverStyle}
    ${props.isActive && active}
  `;

  if (props.disable) {
    return (
      <span
        className={props.className}
        css={css`
          ${styles};
          cursor: default !important;
        `}
      >
        {props.children}
      </span>
    );
  }

  if (props.pure) {
    return (
      <a
        href={as || href}
        className={props.className}
        css={styles}
        onClick={props.onClick}
        target={target}
      >
        {props.children}
      </a>
    );
  }

  return (
    <NextLink href={href} as={as}>
      <a
        href={as}
        className={props.className}
        css={styles}
        onClick={props.onClick}
        target={target}
      >
        {props.children}
      </a>
    </NextLink>
  );
}
