import React from "react";
import { css } from "styled-components";
import * as Formik from "formik";
import { Colors } from "../branding";
import { fontFamilies } from "../typography";
import { Message } from "../components/Message";

export const FieldError: React.FunctionComponent<{ name: string }> = (
  props
) => {
  return (
    <Formik.ErrorMessage
      name={props.name}
      render={(errorMessage: any) => {
        if (typeof errorMessage !== "string") return null;

        return (
          <div
            css={css`
              margin-top: 15px;
            `}
          >
            <Message text={errorMessage} />
          </div>
        );
      }}
    />
  );
};

export const FieldWrapper: React.StatelessComponent<{
  name: string;
  label?: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}> = (props) => {
  return (
    <div className={props.className}>
      {props.label && (
        <label
          css={css`
            font-family: ${fontFamilies.gotham};
            font-size: 12px;
          `}
        >
          {props.label}
        </label>
      )}
      {props.children}

      <FieldError name={props.name} />
    </div>
  );
};
type TextFieldTypes =
  | "text"
  | "email"
  | "search"
  | "password"
  | "number"
  | "tel"
  | "url"
  | "select";

export const TextFieldBase = ({
  error,
  ...props
}: React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { error?: boolean }) => {
  return (
    <input
      css={css`
        width: 100%;
        border: none;
        background: none;
        font-size: 14px;
        outline: none;
        letter-spacing: 0.05em;
        padding: 10px 0;
        border-bottom: ${error
          ? `1px solid ${Colors.error}`
          : `1px solid ${Colors.light_grey}`};
        :focus {
          border-bottom: 1px solid ${Colors.black};
        }

        ::placeholder {
          font-size: 14px;
          color: ${Colors.dark_gray};
        }
      `}
      {...props}
    />
  );
};

export const TextField: React.FC<{
  name: string;
  type?: TextFieldTypes;
  label?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  validate?: (value: string) => Promise<void | string>;
}> = (props) => (
  <FieldWrapper
    name={props.name}
    label={props.label}
    css={css`
      width: 100%;
    `}
  >
    <Formik.Field name={props.name} validate={props.validate}>
      {({ field, meta }: { field: any; meta: any }) => (
        <TextFieldBase
          error={meta.error && meta.touched}
          className={props.className}
          type={props.type || "text"}
          placeholder={props.placeholder}
          disabled={props.disabled}
          {...field}
          onChange={(e) => {
            field.onChange(e);

            if (props.onChange) {
              props.onChange(e);
            }
          }}
        />
      )}
    </Formik.Field>
  </FieldWrapper>
);
