import React, { useState, FormEvent } from "react";
import styled, { css } from "styled-components";
import { Button } from "../components/Button";
import { Row, Column, Spacing } from "../helpers/layout";
import { Message } from "../components/Message";
import { Colors } from "../branding";
import { signupNewsletter } from "../actions/signupNewsletter";
import EventsTracker from "../helpers/fb";
import { gtag } from "../helpers/gtag";
import { useRouter } from "next/router";
import { FormSuccessProps } from "types/Block";

const Form = styled.form`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
`;
export const Input = styled.input`
  border: none;
  background: none;
  height: 45px;
  width: 100%;
  font-size: 12px;
  line-height: 45px;
  box-sizing: border-box;
  letter-spacing: 0.15em;
  outline: none;
  color: ${Colors.light_grey};

  ::placeholder {
    text-transform: uppercase;
    font-size: 11px;
    color: ${Colors.dark_gray};
  }
`;

export function SendgridForm(
  props: {
    customListId?: string | undefined;
    //
    children: (data: {
      errorMessage: string;
      email: string;
      setEmail: (email: string) => void;
      name: string;
      setName: (name: string) => void;
      successMessage: string;
      loading: boolean;
    }) => React.ReactNode;
  } & FormSuccessProps,
) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const checkSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (!emailValid) {
      setErrorMessage("Enter a valid email");
      setLoading(false);
      return;
    }

    setErrorMessage("");
    try {
      const res = await signupNewsletter({
        email,
        name,
        listId: props.customListId || undefined,
        disableConfirmationEmail: !!props.customListId,
      });
      if (res.status === 200) {
        EventsTracker.trackCustom("newsletter_subscribe", {
          email: EventsTracker.hash(email),
          name: name ? EventsTracker.hash(name) : "",
          custom_list_id: props?.customListId || undefined,
        });
        gtag("newsletter_subscribe", {
          email: EventsTracker.hash(email),
          first_name: name ? EventsTracker.hash(name) : "",
          custom_list_id: props?.customListId || undefined,
        });
        setSuccessMessage(props.success_message || "You have been subscribed!");
        setEmail("");
        setName("");

        console.log(props.success_action, props.redirect_url);

        if (props?.success_action === "redirect" && props?.redirect_url) {
          console.log("redirecting now");
          router.push(props.redirect_url);
        }
      }
    } catch (error) {
      setErrorMessage("An unexpected error occured");
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={checkSubmit} noValidate>
      {props.children({
        errorMessage,
        email,
        setEmail,
        name,
        setName,
        successMessage,
        loading,
      })}
    </Form>
  );
}

export function SubscribeForm() {
  return (
    <div>
      <SendgridForm>
        {({ email, errorMessage, setEmail, successMessage }) => {
          return (
            <Column gutter={Spacing.s} justify="center" align="center">
              <div>
                <Row gutter={Spacing.s} justify="center" align="center">
                  <Input
                    type="email"
                    value={email}
                    name="EMAIL"
                    placeholder="Enter your Email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    css={css`
                      flex: 1 0 10%;
                      background-color: ${Colors.nero_dark};
                      padding: 0 20px;
                      border-bottom: ${errorMessage &&
                      `1px solid ${Colors.error}`};
                      text-transform: none;

                      :focus {
                        border-bottom: 1px solid ${Colors.dark_gray};
                      }
                    `}
                  />
                  <div
                    css={css`
                      flex: 0 0 auto;
                    `}
                  >
                    <Button type="submit" label="submit" style="dark-grey" />
                  </div>
                </Row>
                {errorMessage && (
                  <div
                    css={css`
                      margin-top: ${Spacing.m};
                    `}
                  >
                    <Message text={errorMessage} />
                  </div>
                )}
                {successMessage && (
                  <div
                    css={css`
                      margin-top: ${Spacing.m};
                    `}
                  >
                    <Message text={successMessage} success />
                  </div>
                )}
              </div>
            </Column>
          );
        }}
      </SendgridForm>
    </div>
  );
}

export function NewsletterSubscribeForm({
  customListId,
  buttonLabel,
  success_action = "show_message",
  success_message,
  redirect_url,
}: {
  customListId?: string | undefined;
  buttonLabel?: string;
} & FormSuccessProps) {
  return (
    <div>
      <SendgridForm
        customListId={customListId}
        success_message={success_message}
        success_action={success_action}
        redirect_url={redirect_url}
      >
        {({
          email,
          errorMessage,
          setEmail,
          name,
          setName,
          successMessage,
          loading,
        }) => {
          return (
            <Column gutter={Spacing.s} justify="center" align="center">
              <Column>
                <Column>
                  <Input
                    type="text"
                    value={name}
                    name="FNAME"
                    placeholder="First Name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    css={css`
                      border-bottom: 1px solid ${Colors.light_grey};
                      font-size: 14px;
                      :focus {
                        border-bottom: 1px solid ${Colors.black};
                      }
                    `}
                  />
                </Column>
                <Column>
                  <Input
                    type="email"
                    value={email}
                    name="EMAIL"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    css={css`
                      border-bottom: ${errorMessage
                        ? `1px solid ${Colors.error}`
                        : `1px solid ${Colors.light_grey}`};
                      font-size: 14px;
                      :focus {
                        border-bottom: 1px solid ${Colors.black};
                      }
                    `}
                  />
                </Column>
              </Column>

              {errorMessage && (
                <div
                  css={css`
                    margin-top: ${Spacing.m};
                  `}
                >
                  <Message text={errorMessage} />
                </div>
              )}
              {successMessage && (
                <div
                  css={css`
                    margin-top: ${Spacing.m};
                  `}
                >
                  <Message text={successMessage} success />
                </div>
              )}
              <div
                css={css`
                  margin-top: ${Spacing.m};
                `}
              >
                <Button
                  type="submit"
                  label={buttonLabel || "Sign up"}
                  fullwidth={true}
                  isSubmitting={loading}
                />
              </div>
            </Column>
          );
        }}
      </SendgridForm>
    </div>
  );
}
